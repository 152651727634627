import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { config } from '../config/config';
import { EUserType } from '../types';
import signUp, { TFields } from '../utils/auth/signUp';
import ButtonV2 from './Button_v2';
import FormContainer from './FormContainer';
import FormHeader from './FormHeader';
import { InfoBox } from './InfoBox';
import Input from './Input';
import InputLabel from './Label';
import { PasswordValidation } from './PasswordValidation';

const { commonTexts } = config.textData;

const Form = styled.form`
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  gap: 10px;
`;

const TypeButton = styled(ButtonV2)`
  border: 1px solid var(--amplify-primary-color);
  background-color: var(--amplify-white);
  color: var(--amplify-primary-color);

  display: inline-block;
  text-align: center;

  width: 100%;
  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      background-color: var(--amplify-primary-color);
      color: var(--amplify-white);
    `}
`;

const SignUpDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AlreadySignedUp = styled.span`
  flex: 1;
  width: 100%;
  margin: 1em 0 2em 0;
  color: var(--amplify-grey);
  font-size: var(--amplify-text-sm);
`;

const SignUpButton = styled(ButtonV2)`
  width: 100%;
  background-color: var(--amplify-primary-color);
  padding: 1em;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  display: inline-block;
  text-align: center;
`;

const SignInLink = styled.button`
  color: var(--amplify-primary-color);
  background-color: inherit;
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
`;

const validateFields = (values: Array<string>): boolean =>
  values.every(value => value && value.length !== 0);

export function SignUpForm() {
  const [fields, setValues] = useState<TFields>({
    email: '',
    password: '',
    givenName: '',
    familyName: '',
    type: EUserType.Person,
    initialPassword: '',
    passwordRepeated: '',
  });

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const signUpButtonText =
    fields.type === EUserType.Person
      ? 'Rekisteröidy'
      : 'Rekisteröidy yrityksenä';

  return (
    <FormContainer slot="sign-up" className="sign-up-form">
      <FormHeader className="sign-up-form-header">
        {commonTexts.signUp}
      </FormHeader>
      <Form
        onSubmit={async (event: any) => {
          event.preventDefault();
          await signUp(fields);

          /* Clear away the password so that it's not stored in the state anymore. */
          setValues(prevState => ({
            ...prevState,
            password: '',
          }));
        }}
      >
        <Item>
          <ButtonContainer>
            <TypeButton
              type="button"
              className="type-button"
              selected={fields.type === EUserType.Person}
              onClick={(event: any) => {
                event.preventDefault();
                setValueToState('type', EUserType.Person);
              }}
            >
              Yksityiskäyttäjä
            </TypeButton>
            <TypeButton
              type="button"
              className="type-button"
              selected={fields.type === EUserType.Organization}
              onClick={(event: any) => {
                event.preventDefault();
                setValueToState('type', EUserType.Organization);
              }}
            >
              Yrityskäyttäjä
            </TypeButton>
          </ButtonContainer>
        </Item>

        <Item>
          <InputLabel className="sign-up-input-label" htmlFor="email">
            Sähköpostiosoite *
          </InputLabel>
          <Input
            className="sign-up-input"
            name="email"
            id="email"
            type="email"
            placeholder="Sähköposti"
            onChange={(event: any) => {
              setValueToState('email', event.target.value);
            }}
            value={fields.email}
          />
        </Item>

        <Item half>
          <InputLabel className="sign-up-input-label" htmlFor="givenName">
            Etunimi *
          </InputLabel>
          <Input
            className="sign-up-input-label"
            name="givenName"
            id="givenName"
            type="text"
            placeholder="Etunimi"
            onChange={(event: any) => {
              setValueToState('givenName', event.target.value);
            }}
            value={fields.givenName}
          />
        </Item>

        <Item half>
          <InputLabel className="sign-up-input-label" htmlFor="familyName">
            Sukunimi *
          </InputLabel>
          <Input
            className="sign-up-input"
            name="familyName"
            id="familyName"
            type="text"
            placeholder="Sukunimi"
            onChange={(event: any) => {
              setValueToState('familyName', event.target.value);
            }}
            value={fields.familyName}
          />
        </Item>

        <PasswordValidation
          onValidPassword={(value: string) =>
            setValueToState('password', value)
          }
        />

        <SignUpDiv>
          <AlreadySignedUp>
            Oletko jo rekisteröitynyt?{' '}
            <SignInLink
              type="button"
              onClick={event => {
                event.preventDefault();
                Hub.dispatch(UI_AUTH_CHANNEL, {
                  event: AUTH_STATE_CHANGE_EVENT,
                  message: AuthState.SignIn,
                });
              }}
            >
              Kirjaudu
            </SignInLink>
          </AlreadySignedUp>
          <SignUpButton
            className="sign-up-button"
            disabled={
              !validateFields([
                fields.email,
                fields.givenName,
                fields.familyName,
                fields.password,
              ])
            }
          >
            {signUpButtonText}
          </SignUpButton>
        </SignUpDiv>
      </Form>
      <InfoBox />
    </FormContainer>
  );
}
