import { IAuthConfig } from './AuthConfig';

/**
 * Jes dev authentication config
 */

// no protocol, the base URL
const extranetDomain = 'asiakasdev.sssoy.fi';

const jesDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_dA49JCCUt',
  userPoolWebClientId: '4po5shff1vfe2vu22no6kf37hc',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.jes-extranet.com', // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://sssoy.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-skinny-pig-773',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasdev.jes-extranet.com/admin/maintenancenotice`,
  magicLinkAuthUrl:
    'https://wmypnopbrj.execute-api.eu-west-1.amazonaws.com/staging/jseoy/magic-link',
};

export default jesDevAuthConfig;
