import { config } from './config/config';

/**
 * This file allows us to specify different colors to components based on the product
 * if there's a need for that. The fallback color allows us to
 * specify the custom colors only to those products that require them.
 *
 * The use case is this.
 * Products A and B want to use the mainColor1 for buttons
 * (green for product A and blue for product B), but the product C's mainColor1
 * doesn't look good on buttons (i.e red makes the buttons look like delete buttons)
 * and instead it wants to use it's secondaryColor1 (blue) for that.
 *
 * This case would be configured in the following way:
 *
 * buttonBackground: {
 *  productC: config.themeConfig.secondaryColor1, <-- Product C uses this color
 *  defaultColor: config.themeConfig.mainColor1, <-- All other products use this color
 * }
 *
 * If there's no need for customising the color for a component (this is usually the case),
 * we can use the config.themeConfig.<color> directly in the code
 * and skip using this file.
 *
 */

type TColorTheme = {
  [key: string]: {
    elenia?: string;
    nice?: string;
    jes?: string;
    auris?: string;
    loimua?: string;
    defaultColor: string;
  };
};

const theme: TColorTheme = {
  /* The decorative border on the top of the page */
  decorativeTopBorder: {
    elenia: config.themeConfig.colors.secondaryColor3,
    defaultColor: config.themeConfig.colors.mainThemeColor1,
  },

  /* Button component */
  buttonBackground: {
    elenia: config.themeConfig.colors.secondaryColor3,
    defaultColor: config.themeConfig.colors.mainThemeColor1,
  },
  deleteButtonBackground: {
    nice: config.themeConfig.colors.mainThemeColor2,
    defaultColor: config.themeConfig.colors.secondaryColor1,
  },

  /* Error color for inputs */
  inputError: {
    nice: config.themeConfig.colors.mainThemeColor2,
    defaultColor: config.themeConfig.colors.secondaryColor1,
  },

  headerText: {
    nice: config.themeConfig.colors.mainThemeColor2,
    defaultColor: config.themeConfig.colors.mainThemeColor1,
  },
};

const colorByProduct = (key: any) => {
  const color = theme[key][config.product] || theme[key].defaultColor;

  return color;
};

export default colorByProduct;
