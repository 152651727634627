import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonBase } from './components';
import { config } from '../../config/config';

export const RemoveButtonContainer = styled(ButtonBase)`
  ${(props: { url?: string }) => {
    return css`
      width: 32px;
      height: 32px;
      background: url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 2px;
      top: 2px;
      background-size: contain;
    `;
  }}
`;
type Props = {
  onAction: Function;
};

const RemoveButton = (props: Props) => {
  return (
    <RemoveButtonContainer
      onClick={() => props.onAction()}
      onTouchEnd={() => props.onAction()}
      url={config.themeConfig.closeButtonIcon?.url}
    ></RemoveButtonContainer>
  );
};

export default RemoveButton;
