import { IAuthConfig } from './AuthConfig';

/**
 * Loimua qa authentication config
 */

// no protocol, the base domain
const extranetDomain = 'asiakasqa.loimua.fi';

const loimuaQaAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_xGb5BFs1H',
  userPoolWebClientId: '3mgtr3gkctoo48ghevgn3bmc1t',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakasqa.loimua-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://loimua.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-lazy-screw-415',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasqa.loimua-extranet.com/admin/maintenancenotice`,
};

export default loimuaQaAuthConfig;
