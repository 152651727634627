import React from 'react';
import styled, { css } from 'styled-components';
import { config } from '../../config/config';
import RemoveButton from './RemoveButton';
import AddButton from './AddButton';
import chevronRight from '../../images/chevron_right.svg';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  filter: ${config.themeConfig.launchpad3?.appLinkFilter};
  background: ${config.themeConfig.launchpad3?.appLinkBackground};

  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05),
    0px 0px 1px 0px rgba(255, 255, 255, 0.25) inset,
    0px 0px 84px 0px rgba(255, 255, 255, 0.1) inset;

  &:focus,
  &:hover {
    text-decoration: underline;
    filter: brightness(1.1);
  }

  ${(props: { isEditMode: boolean }) => {
    if (props.isEditMode) {
      return css`
        cursor: move;
      `;
    }

    return css`
      cursor: pointer;
    `;
  }}
`;

const Title = styled.div`
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 500) {
    font-size: 14px;
  }

  @media screen and (min-width: 500) {
    font-size: 18px;
  }

  font-weight: bold;
  color: white;
`;

const ButtonEndIcon = styled.div`
  ${(props: { url: string }) => {
    return css`
      width: 8px;
      height: 25px;
      background: url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      justify-self: flex-end;
      margin: 0 8px;
      @media screen and (max-width: 330px) {
        width: 6px;
        height: 11px;
      }
    `;
  }}
`;
type Props = {
  onRemove?: Function;
  onAdd?: Function;
  id: string;
  title: string;
  image?: any;
};

const ServiceItem = (props: Props) => {
  const { onRemove, id, title, onAdd } = props;

  return (
    <Container isEditMode={onRemove !== undefined}>
      {onRemove && <RemoveButton onAction={() => onRemove(id)} />}
      {onAdd && <AddButton onAction={() => onAdd(id)} />}
      <Title>{title}</Title>
      <ButtonEndIcon url={chevronRight} />
    </Container>
  );
};

export default ServiceItem;
