import { IAuthConfig } from './AuthConfig';

/**
 * Auris qa authentication config
 */

// no protocol, the base domain
const extranetDomain = 'asiakasqa.aurisenergia.fi';

const aurisQaAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_m1EPbP26j',
  userPoolWebClientId: '3sl658lf4ek04bkc1cngjst9e6',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakasqa.auris-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://auris.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-spicy-fork-778',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasqa.auris-extranet.com/admin/maintenancenotice`,
};

export default aurisQaAuthConfig;
