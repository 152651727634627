/**
 * Render a link to admin-service-frontend if the logged in user is an admin.
 */
import React from 'react';
import { Auth } from 'aws-amplify';
import { config } from '../config/config';
import styled from 'styled-components';
import { isContentManager } from '../helpers';
import { ExternalLink } from './Button';
import { redirectWithToken } from '../utils/navigation';

const StyledLink = styled(ExternalLink)`
  margin: auto 1em;
  font-size: ${config.themeConfig.fontSizes.medium};
`;

type TState = {
  isContentManager: boolean;
};

export class AdminServiceLink extends React.Component<{}, TState> {
  url = `${config.authConfig.adminServiceFrontendUrl}/kirjaudu`;
  state = {
    isContentManager: false,
  };

  componentDidMount() {
    this.fetchToken();
  }

  async fetchToken() {
    const user: any = await Auth.currentAuthenticatedUser();

    this.setState({
      isContentManager: isContentManager(user),
    });
  }

  onLinkClick = (e: React.MouseEvent)=> {
    e.preventDefault();
    redirectWithToken(this.url);
    return false;
  }

  render() {
    return (
      this.state.isContentManager && (
        <StyledLink onClick={this.onLinkClick} href={this.url}>Sisällönhallinta</StyledLink>
      )
    );
  }
}
