import { IAuthConfig } from './AuthConfig';

/**
 * Elenia prod authentication config
 */

// no protocol, the base URL
const extranetDomain = 'asiakas.elenia.fi';

const eleniaProdAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_wFbb2iXfk',
  userPoolWebClientId: 'k4s2pnm04536t1bm72bdatqct',
  identityPoolId: 'eu-west-1:adc6a1c4-5de9-4ac3-99a7-10bf035439ea',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  widgetsApiUrl: 'https://api.asiakas.aina-extranet.com',
  lambdaApiUrl: `https://api.asiakas.aina-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://elenia.app.signicat.com/auth/open/connect',
  SignicatApiClientId: 'prod-narrow-drawer-204',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakas.aina-extranet.com/admin/maintenancenotice`,
  cookieStorage: {
    domain: '.elenia.fi',
    secure: true,
    path: '/',
    expires: 30,
  },
};

export default eleniaProdAuthConfig;
