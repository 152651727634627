import React from 'react';
import styled from 'styled-components';
import { config } from '../../config/config';
import { ButtonBase } from './components';

export const RemoveButtonContainer = styled(ButtonBase)`
  position: absolute;
  right: 2px;
  top: 2px;
  color: ${config.themeConfig.launchpad2?.removeButtonColor};
  background: ${config.themeConfig.launchpad2?.removeButtonBackground};
`;

type Props = {
  onAction: Function;
};

const RemoveButton = (props: Props) => (
  <RemoveButtonContainer
    onClick={() => props.onAction()}
    onTouchEnd={() => props.onAction()}
  >
    &#x2715;
  </RemoveButtonContainer>
);

export default RemoveButton;
