// @flow
import * as React from 'react';
import styled from 'styled-components';
import { config } from '../../config/config';
import closeDark from '../../images/close_dark.svg';
import closeLight from '../../images/close_light.svg';
import { request } from '../../helpers';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 1em;
  color: ${config.themeConfig.launchpad?.noticesTextColor};
  background: ${config.themeConfig.launchpad?.noticesBackgroundColor ??
  config.themeConfig.colors.noticesBackgroundColor};
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05),
    0px 0px 1px 0px rgba(255, 255, 255, 0.25) inset;
`;

const Text = styled.h1`
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 0.5em;
  color: ${config.themeConfig.launchpad?.noticesTextColor ??
  config.themeConfig.colors.noticesTextColor};
`;

const Message = styled.span`
  margin-left: 0.5em;
  color: black;
  font-size: 0.9em;
  font-weight: normal;
  text-transform: none;
  color: ${config.themeConfig.launchpad?.noticesTextColor ??
  config.themeConfig.colors.noticesTextColor};
`;

const Close = styled.img.attrs({
  src: config.launchpadConfig.version === 3 ? closeLight : closeDark,
})`
  width: 1em;
  height: 100%;
  cursor: pointer;
`;

type NoticeProps = {
  onClose: () => {},
  message: ?string,
};

type State = {
  notice: Array,
  noticeClosed: Boolean,
};

type Props = {
  onLaunchpad: Boolean,
};

const Notice = ({ message, onClose }: NoticeProps) => (
  <Container message={message}>
    <>
      <Text>
        TIEDOTE <Message>{message}</Message>
      </Text>
    </>
    <Close onClick={onClose} />
  </Container>
);

class Notices extends React.Component<Props, State> {
  state = {
    notice: [],
    noticeClosed: false,
    onLaunchpad: false,
  };

  componentDidMount = async () => {
    this.setState({
      notice: [],
      onLaunchpad: this.props.onLaunchpad,
    });

    try {
      const maintenanceNotices = await request({
        url: config.authConfig.maintenanceNoticeServiceUrl,
      });

      this.setState({
        notice: [maintenanceNotices?.data],
      });
    } catch (err) {
      console.error(err);

      this.setState({
        notice: [],
      });
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.onLaunchpad !== this.props.onLaunchpad) {
      this.setState({
        onLaunchpad: this.props.onLaunchpad,
      });
    }
  };

  hideNotice = () =>
    this.setState({
      noticeClosed: prevState => !prevState,
    });

  render() {
    if (
      this.state.notice?.length === 0 ||
      this.state.noticeClosed ||
      this.state.notice[0].active === false
    ) {
      return null;
    }
    switch (this.state.notice[0].visibility) {
      case 'launchPad':
        if (!this.state.onLaunchpad) return null;
        break;
      case 'frontpage':
        if (this.state.onLaunchpad) return null;
        break;
      case 'both':
        break;
      case 'extranet':
      default:
        return null;
    }
    return (
      <Notice
        message={this.state.notice[0].message}
        onClose={this.hideNotice}
      />
    );
  }
}
export const NoticesComponent = Notices;
