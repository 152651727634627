import { IAuthConfig } from './AuthConfig';

/**
 * Nice dev authentication config
 */

// no protocol, the base domain
const extranetDomain = 'asiakasdev.ese.fi';

const niceDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_Bb5uxIU7J',
  userPoolWebClientId: '2dop5afq1tk2cphkc4efs1rnju',
  identityPoolId: 'eu-west-1:b3873f1a-c071-4be0-9beb-bf7d5bb6e697',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.nice-extranet.com', // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://ese.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-fierce-flag-669',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasdev.nice-extranet.com/admin/maintenancenotice`,
};

export default niceDevAuthConfig;
