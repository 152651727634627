import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { InfoBox } from '../components/InfoBox';
import { config } from '../config/config';
import { EUserType } from '../types';
import signUp, { TFields } from '../utils/auth/signUp';
import { PasswordInput } from './PasswordInput';

const { commonTexts } = config.textData;

const Header = styled.h2`
  text-align: left;
  margin: 1rem 0 1.5rem 0;
  font-size: var(--amplify-text-md);
  color: var(--amplify-secondary-color);
  font-family: var(--amplify-font-family);
  font-weight: 700;
  text-transform: unset;
`;

const Container = styled.div``;

const FormContainer = styled.div`
  background-color: white;
  min-width: 10rem;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 35px 40px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;

const Label = styled.label`
  margin-bottom: 0.75em;
  font-size: var(--amplify-text-sm);
  color: #152939;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: var(--amplify-text-md);
  font-size: var(--amplify-text-sm);
  color: #152939;
  background-color: var(--amplify-white);
  background-image: none;
  border: 1px solid var(--amplify-light-grey);
  border-radius: 3px;
  box-sizing: border-box;
  margin: 0 0 0.425rem 0;
  height: 3.125rem;
  line-height: 1.1;
`;

const ButtonContainer = styled.div`
  display: block;
  justify-content: space-between;
  margin-bottom: 0.5em;

  @media screen and (min-width: 500px) {
    display: flex;
  }
`;

const TypeButton = styled.button`
  width: 100%;
  height: 60px;
  margin: 0px;
  color: var(--amplify-primary-color);
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid var(--amplify-primary-color);
  background-color: var(--amplify-white);
  font-size: var(--amplify-text-md);

  &:first-child {
    margin: 0 0 5px 0;

    @media screen and (min-width: 500px) {
      margin: 0 5px 0 0;
    }
  }

  &:last-child {
    margin: 0 0 5px 0;

    @media screen and (min-width: 500px) {
      margin: 0 0 0 5px;
    }
  }

  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      background-color: var(--amplify-primary-color);
      color: var(--amplify-white);
    `}
`;

const SignUpDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AlreadySignedUp = styled.span`
  text-align: center;
  flex: 1;
  width: 100%;
  margin: 1em 0 0.5em 0;
  color: var(--amplify-grey);
  font-size: var(--amplify-text-sm);
`;

const SignUpButton = styled.button`
  width: 100%;
  background-color: var(--amplify-primary-color);
  padding: 1em;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: 0;
  display: inline-block;
  font-size: var(--amplify-text-sm);
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.75px;

  &:hover {
    opacity: 0.8;
  }
  &[disabled] {
    color: white;
    background-color: var(--amplify-primary-color);
    opacity: 0.5;
    cursor: not-allowed;
    border: 0;

    &:hover,
    &:focus {
      background-color: var(--amplify-primary-color);
      opacity: 0.5;
    }
  }
`;

const SignInLink = styled.button`
  color: var(--amplify-primary-color);
  background-color: inherit;
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
`;

/**
 * Form submit should only be allowed if
 *    - All fields are filled (TODO: email format validation?)
 *
 * Returns false if validations fail and true if they pass.
 */
const validateFields = (values: Array<string>): boolean =>
  values.every(value => value && value.length !== 0);

export function SignUpForm() {
  const [fields, setValues] = useState<TFields>({
    email: '',
    password: '',
    givenName: '',
    familyName: '',
    type: EUserType.Person,
  });

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const signUpButtonText =
    fields.type === EUserType.Person
      ? 'Rekisteröidy'
      : 'Rekisteröidy yrityksenä';

  return (
    <Container slot="sign-up">
      <FormContainer>
        <Header>{commonTexts.signUp}</Header>
        <Form
          onSubmit={async (event: any) => {
            event.preventDefault();
            await signUp(fields);

            /* Clear away the password so that it's not stored in the state anymore. */
            setValues(prevState => ({
              ...prevState,
              password: '',
            }));
          }}
        >
          <Item>
            <Label htmlFor="type" hidden>
              Valitse käyttäjätyyppi
            </Label>
            <ButtonContainer>
              <TypeButton
                type="button"
                selected={fields.type === EUserType.Person}
                onClick={(event: any) => {
                  event.preventDefault();
                  setValueToState('type', EUserType.Person);
                }}
              >
                Yksityiskäyttäjä
              </TypeButton>
              <TypeButton
                type="button"
                selected={fields.type === EUserType.Organization}
                onClick={(event: any) => {
                  event.preventDefault();
                  setValueToState('type', EUserType.Organization);
                }}
              >
                Yrityskäyttäjä
              </TypeButton>
            </ButtonContainer>
          </Item>

          <Item>
            <Label htmlFor="email">Sähköpostiosoite *</Label>
            <Input
              name="email"
              id="email"
              type="email"
              placeholder="Sähköposti"
              onChange={(event: any) => {
                setValueToState('email', event.target.value);
              }}
              value={fields.email}
            />
          </Item>

          <Item half>
            <Label htmlFor="givenName">Etunimi *</Label>
            <Input
              name="givenName"
              id="givenName"
              type="text"
              placeholder="Etunimi"
              onChange={(event: any) => {
                setValueToState('givenName', event.target.value);
              }}
              value={fields.givenName}
            />
          </Item>

          <Item half>
            <Label htmlFor="familyName">Sukunimi *</Label>
            <Input
              name="familyName"
              id="familyName"
              type="text"
              placeholder="Sukunimi"
              onChange={(event: any) => {
                setValueToState('familyName', event.target.value);
              }}
              value={fields.familyName}
            />
          </Item>

          <PasswordInput
            onValidPassword={(value: string) =>
              setValueToState('password', value)
            }
          />

          <SignUpDiv>
            <AlreadySignedUp>
              Oletko jo rekisteröitynyt?{' '}
              <SignInLink
                type="button"
                onClick={event => {
                  event.preventDefault();
                  Hub.dispatch(UI_AUTH_CHANNEL, {
                    event: AUTH_STATE_CHANGE_EVENT,
                    message: AuthState.SignIn,
                  });
                }}
              >
                Kirjaudu
              </SignInLink>
            </AlreadySignedUp>
            <SignUpButton
              disabled={
                !validateFields([
                  fields.email,
                  fields.givenName,
                  fields.familyName,
                  fields.password,
                ])
              }
            >
              {signUpButtonText}
            </SignUpButton>
          </SignUpDiv>
        </Form>
      </FormContainer>
      <InfoBox />
    </Container>
  );
}
