import React from 'react';
import { config } from '../config/config';
import styled from 'styled-components';

const Button = styled.button`
  background-color: ${config.themeConfig.colors.mainThemeColor1};
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  max-width: fit-content;
  color: white;
  border: 0;
  align-items: center;
  font-family: ${config.themeConfig.fontFamilies.buttonFont};
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 48px;
  display: flex;

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    color: white;
    background-color: var(--amplify-primary-color);
    opacity: 0.5;
    cursor: not-allowed;
    border: 0;

    &:hover,
    &:focus {
      background-color: var(--amplify-primary-color);
    }
  }
`;

interface ButtonProps {
  className: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void | undefined;
  children?: React.ReactNode;
  disabled?: boolean | undefined;
  tabIndex?: number | undefined;
}

export default function ButtonV2(props: ButtonProps) {
  return (
    <Button
      disabled={props.disabled}
      className={props.className}
      type={props.type}
      onClick={props.onClick}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </Button>
  );
}
