import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import submitPasswordResetRequest from '../utils/auth/forgotPassword';
import ButtonV2 from './Button_v2';
import FormContainer from './FormContainer';
import FormHeader from './FormHeader';
import { InfoBox } from './InfoBox';
import Input from './Input';
import InputLabel from './Label';
import { PasswordValidation } from './PasswordValidation';

const Form = styled.form`
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const CancelButtonContainer = styled.span`
  text-align: left;
  flex: 0 0 auto;
  min-width: 160px;
  margin: 1em 0 0.5em 0;
  color: var(--amplify-grey);
  font-size: var(--amplify-text-sm);
  display: grid;
`;

const ConfirmButton = styled(ButtonV2)`
  background-color: var(--amplify-primary-color);
`;

const SignInLink = styled.button`
  color: var(--amplify-primary-color);
  background-color: inherit;
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  text-align: left;
`;

const validateFields = (values: Array<string>): boolean =>
  values.every(value => value && value.length !== 0);

export function ForgotPasswordForm() {
  const [fields, setValues] = useState({
    code: '',
    email: '',
    password: '',
  });

  const [isCodeSent, setIsCodeSent] = useState(false);

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  return (
    <React.Fragment>
      <FormContainer slot="forgot-password" className="forgot-password-form">
        <FormHeader className="forgot-password-form-header">
          Palauta salasana
        </FormHeader>
        <Form
          onSubmit={async event => {
            event.preventDefault();
            submitPasswordResetRequest(isCodeSent, setIsCodeSent, fields);
          }}
        >
          {!isCodeSent && (
            <Item>
              <InputLabel className="email-label" htmlFor="fpEmail">
                Sähköpostiosoite *
              </InputLabel>
              <Input
                className="fpEmail"
                name="fpEmail"
                id="fpEmail"
                type="email"
                placeholder="Syötä sähköpostiosoite"
                onChange={(event: any) => {
                  setValueToState('email', event.target.value);
                }}
                value={fields.email}
              />
            </Item>
          )}
          {isCodeSent && (
            <React.Fragment>
              <Item>
                <InputLabel className="code-label" htmlFor="code">
                  Vahvistuskoodi *
                </InputLabel>
                <Input
                  className="code"
                  name="code"
                  id="code"
                  type="text"
                  placeholder="Syötä vahvistuskoodi"
                  onChange={event =>
                    setValueToState('code', event.target.value)
                  }
                  value={fields.code}
                />
              </Item>

              <PasswordValidation
                onValidPassword={(value: string) =>
                  setValueToState('password', value)
                }
              />
            </React.Fragment>
          )}

          <Footer>
            <CancelButtonContainer>
              <SignInLink
                type="button"
                onClick={event => {
                  event.preventDefault();
                  setIsCodeSent(false);
                  setValueToState('email', '');
                  setValueToState('code', '');
                  setValueToState('password', '');

                  Hub.dispatch(UI_AUTH_CHANNEL, {
                    event: AUTH_STATE_CHANGE_EVENT,
                    message: AuthState.SignIn,
                  });
                }}
              >
                Takaisin kirjautumiseen
              </SignInLink>
            </CancelButtonContainer>
            {!isCodeSent && (
              <ConfirmButton
                className="confirm-button"
                disabled={fields.email.length === 0}
              >
                Lähetä koodi
              </ConfirmButton>
            )}
            {isCodeSent && (
              <ConfirmButton
                className="confirm-button"
                disabled={!validateFields([fields.code, fields.password])}
              >
                Lähetä
              </ConfirmButton>
            )}
          </Footer>
        </Form>
        <InfoBox />
      </FormContainer>
    </React.Fragment>
  );
}
