import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import submitPasswordResetRequest from '../utils/auth/forgotPassword';
import { InfoBox } from './InfoBox';
import { PasswordInput } from './PasswordInput';

const Header = styled.h2`
  text-align: left;
  margin: 1rem 0 1.5rem 0;
  font-size: var(--amplify-text-md);
  color: var(--amplify-secondary-color);
  font-family: var(--amplify-font-family);
  font-weight: 700;
  text-transform: unset;
`;

const Container = styled.div`
  max-width: 28.5rem;
`;

const FormContainer = styled.div`
  background-color: white;
  min-width: 10rem;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 35px 40px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;
const Label = styled.label`
  margin-bottom: 0.75em;
  font-size: var(--amplify-text-sm);
  color: #152939;
`;
const Input = styled.input`
  display: block;
  width: 100%;
  padding: var(--amplify-text-md);
  font-size: var(--amplify-text-sm);
  color: #152939;
  background-color: var(--amplify-white);
  background-image: none;
  border: 1px solid var(--amplify-light-grey);
  border-radius: 3px;
  box-sizing: border-box;
  margin: 0 0 0.425rem 0;
  height: 3.125rem;
  line-height: 1.1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const CancelButtonContainer = styled.span`
  text-align: left;
  flex: 0 0 auto;
  min-width: 160px;
  margin: 1em 0 0.5em 0;
  color: var(--amplify-grey);
  font-size: var(--amplify-text-sm);
  display: grid;
`;

const ConfirmButton = styled.button`
  width: 100%;
  background-color: var(--amplify-primary-color);
  padding: 1em;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: 0;
  display: inline-block;
  font-size: var(--amplify-text-sm);
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.75px;

  &:hover {
    opacity: 0.8;
  }
  &[disabled] {
    color: white;
    background-color: var(--amplify-primary-color);
    opacity: 0.5;
    cursor: not-allowed;
    border: 0;

    &:hover,
    &:focus {
      background-color: var(--amplify-primary-color);
      opacity: 0.5;
    }
  }
`;

const SignInLink = styled.button`
  color: var(--amplify-primary-color);
  background-color: inherit;
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  text-align: left;
`;

const validateFields = (values: Array<string>): boolean =>
  values.every(value => value && value.length !== 0);

export function ForgotPasswordForm() {
  const [fields, setValues] = useState({
    code: '',
    email: '',
    password: '',
  });

  const [isCodeSent, setIsCodeSent] = useState(false);

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  return (
    <React.Fragment>
      <Container slot="forgot-password">
        <FormContainer>
          <Header>Palauta salasana</Header>
          <Form
            onSubmit={async event => {
              event.preventDefault();

              submitPasswordResetRequest(isCodeSent, setIsCodeSent, fields);
            }}
          >
            {!isCodeSent && (
              <Item>
                <Label htmlFor="fpEmail">Sähköpostiosoite *</Label>
                <Input
                  name="fpEmail"
                  id="fpEmail"
                  type="email"
                  placeholder="Syötä sähköpostiosoite"
                  onChange={(event: any) => {
                    setValueToState('email', event.target.value);
                  }}
                  value={fields.email}
                />
              </Item>
            )}
            {isCodeSent && (
              <React.Fragment>
                <Item>
                  <Label htmlFor="code">Vahvistuskoodi *</Label>
                  <Input
                    name="code"
                    id="code"
                    type="text"
                    placeholder="Syötä vahvistuskoodi"
                    onChange={event =>
                      setValueToState('code', event.target.value)
                    }
                    value={fields.code}
                  />
                </Item>

                <PasswordInput
                  onValidPassword={(value: string) =>
                    setValueToState('password', value)
                  }
                />
              </React.Fragment>
            )}

            <Footer>
              <CancelButtonContainer>
                <SignInLink
                  type="button"
                  onClick={event => {
                    event.preventDefault();
                    setIsCodeSent(false);
                    setValueToState('email', '');
                    setValueToState('code', '');
                    setValueToState('password', '');

                    Hub.dispatch(UI_AUTH_CHANNEL, {
                      event: AUTH_STATE_CHANGE_EVENT,
                      message: AuthState.SignIn,
                    });
                  }}
                >
                  Takaisin kirjautumiseen
                </SignInLink>
              </CancelButtonContainer>
              {!isCodeSent && (
                <ConfirmButton disabled={fields.email.length === 0}>
                  Lähetä koodi
                </ConfirmButton>
              )}
              {isCodeSent && (
                <ConfirmButton
                  disabled={!validateFields([fields.code, fields.password])}
                >
                  Lähetä
                </ConfirmButton>
              )}
            </Footer>
          </Form>
        </FormContainer>
        <InfoBox />
      </Container>
    </React.Fragment>
  );
}
