import { IAuthConfig } from './AuthConfig';

/**
 * Loimua prod authentication config
 */

// no protocol, the base URL
const extranetDomain = 'asiakas.loimua.fi';

const loimuaProdAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_gJ3s7MInf',
  userPoolWebClientId: '516mvufkcrtt2keg4oa2auomgo',
  identityPoolId: 'eu-west-1:adc6a1c4-5de9-4ac3-99a7-10bf035439ea',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakas.loimua-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://loimua.app.signicat.com/auth/open/connect',
  SignicatApiClientId: 'prod-petty-fork-689',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakas.loimua-extranet.com/admin/maintenancenotice`,
};

export default loimuaProdAuthConfig;
