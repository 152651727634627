import React from 'react';
import styled from 'styled-components';
import { config } from '../../config/config';
import { ButtonBase } from './components';

export const AddButtonContainer = styled(ButtonBase)`
  position: absolute;
  right: 2px;
  bottom: 2px;
  color: ${config.themeConfig.launchpad3?.plusSignColor};
  background: ${config.themeConfig.launchpad3?.plusSignBackground};
`;

type Props = {
  onAction: Function;
};

const AddButton = (props: Props) => (
  <AddButtonContainer
    onClick={() => props.onAction()}
    onTouchEnd={() => props.onAction()}
  >
    &#x002b;
  </AddButtonContainer>
);

export default AddButton;
