import { Auth } from 'aws-amplify';
import React from 'react';
import { removeIdTokenCookie } from '../utils/session';

class LogOut extends React.Component<{}> {
  async componentDidMount() {
    await Auth.signOut();
    removeIdTokenCookie();
    window.location.assign('/');
  }

  render() {
    return null;
  }
}
export default LogOut;
