import styled from 'styled-components';
import React from 'react';
import { config } from '../config/config';

const Container = styled.div`
  height: 100%;
  box-sizing: unset;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 50px 0;
  min-width: 10rem;
  border-radius: 6px;

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    padding: 20px 40px 35px 40px;
  }
`;

interface FormContainerProps {
  className: string;
  slot: string;
  children: React.ReactNode;
}

export default function FormContainer(props: FormContainerProps) {
  return (
    <Container slot={props.slot} className={props.className}>
      {props.children}
    </Container>
  );
}
