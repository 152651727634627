import { Auth } from 'aws-amplify';
import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import styled, { css } from 'styled-components';
import { config } from '../../config/config';
import { request } from '../../helpers';
import { LaunchpadItem } from '../../types';
import CookieDeclarationModal from '../CookieDeclarationModal';
import { Spinner } from '../Spinner';
import ServiceItem from './ServiceItem';
import widgets from './widgets/widgets';
import ButtonV2 from '../Button_v2';
import chevronRight from '../../images/chevron_right.svg';
import logoutIcon from '../../images/exit-to-app.svg';

// Style configuration for React Grid Layout
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import TopAppBar from './TopAppBar';
const { launchpad: texts } = config.textData;

// Responsive grid component
const ResponsiveGridLayout = WidthProvider(Responsive);

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  margin: 20px auto;
  font-family: Apex;
  max-width: ${config.themeConfig.mobileBreakPoint}px;
  width: 75%;

  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    width: 100%;
  }

  border-radius: 3px;
  min-height: 50vh;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
`;

const PrimaryButton = styled(ButtonV2)`
  filter: ${config.themeConfig.launchpad3?.primaryButtonFilter};
  background-color: ${config.themeConfig.launchpad3?.primaryButtonBackground};
  border: 1px solid rgba(255, 255, 255, 0.32);
`;

const TextButton = styled(ButtonV2)`
  background: transparent;
  border: none;
`;

const LogoutButton = styled(ButtonV2)`
  background: transparent;
  border: none;
  font-family: Apex-semi-bold;
  font-weight: 600;
  flex-grow: 1;

  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    display: none;
  }
`;

const SecondaryButton = styled(ButtonV2)`
  filter: ${config.themeConfig.launchpad3?.secondaryButtonFilter};
  background: ${config.themeConfig.launchpad3?.secondaryButtonBackground};
  color: ${config.themeConfig.launchpad3?.secondaryButtonTextColor};
`;

const ButtonEndIcon = styled.div`
  ${(props: { url: string }) => {
    return css`
      width: 8px;
      height: 12px;
      background: url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      justify-self: flex-end;
      @media screen and (max-width: 330px) {
        width: 6px;
        height: 11px;
      }
    `;
  }}
`;
const ButtonStartIcon = styled.div`
  ${(props: { url: string }) => {
    return css`
      height: 24px;
      width: 24px;
      background: url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      justify-self: flex-start;
    `;
  }}
`;

const ServiceItemGridButton = styled.button`
  background: transparent;
  flex: 0 0 auto;
  border: 0;
  height: 100%;
  width: 100%;
`;

const Buttons = styled.div`
  margin: 20px 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
`;

const HiddenServicesContainer = styled.div`
  width: 100%;
`;

const Title = styled.h2`
  text-align: center;
  text-transform: none;
  color: ${config.themeConfig.launchpad3?.titleColor};
  font-family: Apex-semi-bold;
  font-size: 30px;
  font-weight: 600;
  text-align: left;

  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    font-size: 24px;
  }
  padding-top: 20px;
  padding-bottom: 20px;
`;

const HiddenServicesGrid = styled.div`
  padding: 10px;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(${4}, 1fr);
  width: 100%;
`;

const EditTitle = styled.div`
  font-family: Apex-semi-bold;
  color: ${config.themeConfig.launchpad3?.editTitleColor};
  font-size: 22px;
  font-weight: 600;
  text-align: left;
`;

const EditSubtitle = styled.div`
  font-family: Apex-semi-bold;
  color: ${config.themeConfig.launchpad3?.editSubtitleColor};
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`;

const ROW_HEIGHT = 160;
const COLUMNS_CONFIGURATION = {
  lg: 4,
  md: 4,
  sm: 3,
  xs: 2,
};
const BREAKPOINTS_CONFIGURATION = {
  xs: 300,
  sm: 600,
  md: 675,
  lg: 800,
};

type ItemDefaultPosition = {
  x: number;
  i: string;
  h: number;
  w: number; // Width of 1 or 2 columns
  y: number;
};
const calculateDefaultLayoutPositions = (
  services: LaunchpadItem[],
  numberOfColumns: number,
): ItemDefaultPosition[] => {
  // Build a list of items with their widths
  const items: ItemDefaultPosition[] = services.map(c => {
    const widget = widgets.find(item => item.id === c.id);

    return {
      i: c.id,
      h: 1,
      w: widget?.minWidth || 1,
      x: 0,
      y: 0,
    };
  });
  const packedItems: ItemDefaultPosition[] = [];
  let spaceInCurrentRow = numberOfColumns; // Space left in the current row
  let currentRowNumber = 0; // Current row number i.e. Y position
  let currentX = 0; // X position within the current row

  items.forEach(item => {
    if (item.w <= spaceInCurrentRow) {
      // If the item fits in the current row, assign position and add it
      item.x = currentX;
      item.y = currentRowNumber;
      packedItems.push(item);

      // Update the space and X position for the next item
      spaceInCurrentRow -= item.w;
      currentX += item.w;
    } else {
      // If the item does not fit, move to a new row
      currentRowNumber++;
      spaceInCurrentRow = numberOfColumns - item.w; // Reset space for the new row
      currentX = 0; // Reset X position for the new row

      // Assign position for the new row and add the item
      item.x = currentX;
      item.y = currentRowNumber;
      packedItems.push(item);

      // Update the X position for the next item
      currentX += item.w;
    }

    // If there's no space left in the row or the item exactly fits, prepare for a new row
    if (spaceInCurrentRow === 0) {
      currentRowNumber++;
      spaceInCurrentRow = numberOfColumns; // Reset space for the new row
      currentX = 0; // Reset X position for the new row
    }
  });

  return packedItems;
};

type Props = {
  services: LaunchpadItem[];
  onSelectApplication: Function;
  fetchingMagicLink: boolean;
};

const LaunchpadV3 = (props: Props) => {
  const [isEditMode, setEditMode] = React.useState(false);
  const [layouts, setLayouts] = React.useState({});
  const [activeBreakpoint, setActiveBreakpoint] = React.useState<
    'lg' | 'md' | 'sm' | 'xs'
  >('lg');
  const [hiddenServices, setHiddenServices] = React.useState([] as string[]);
  const [draggingItem, setDraggingItem] = React.useState(null as string | null);
  const [isLoadingConfiguration, setLoadingConfiguration] = React.useState(
    true,
  );
  const [cookieDeclarationOpen, setCookieDeclaration] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    document.title = texts.pageTitle;
  }, []);

  // Hook for fetching user data
  React.useEffect(() => {
    if (isLoadingConfiguration) {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();

        (async () => {
          const userMetadata = await request({
            url: `${config.authConfig.lambdaApiUrl}/idm/customerMetadata`,
            params: {
              sub: user.attributes.sub,
            },
          });

          // Validate launchpad configuration
          const isValidLaunchpadConfig =
            userMetadata.data.launchpad_config &&
            typeof userMetadata.data.launchpad_config === 'object' &&
            userMetadata.data.launchpad_config.layouts &&
            userMetadata.data.launchpad_config.hiddenServices;

          if (isValidLaunchpadConfig) {
            setLoadingConfiguration(false);
            const layoutsFromUserData =
              userMetadata.data.launchpad_config.layouts;

            if (
              'lg' in layoutsFromUserData ||
              'md' in layoutsFromUserData ||
              'sm' in layoutsFromUserData ||
              'xs' in layoutsFromUserData
            ) {
              setLayouts(layoutsFromUserData);
            } else {
              setLayouts({
                ...layouts,
                [activeBreakpoint]: layoutsFromUserData,
              });
            }
            setHiddenServices(
              userMetadata.data.launchpad_config.hiddenServices,
            );
          } else {
            setLoadingConfiguration(false);
          }
        })();
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingConfiguration]);

  const calculateDefaultLayout = () => {
    const calculatedLayouts = {
      lg: calculateDefaultLayoutPositions(
        props.services,
        COLUMNS_CONFIGURATION.lg,
      ),
      md: calculateDefaultLayoutPositions(
        props.services,
        COLUMNS_CONFIGURATION.md,
      ),
      sm: calculateDefaultLayoutPositions(
        props.services,
        COLUMNS_CONFIGURATION.sm,
      ),
      xs: calculateDefaultLayoutPositions(
        props.services,
        COLUMNS_CONFIGURATION.xs,
      ),
    };
    setLayouts(calculatedLayouts);
  };

  React.useEffect(() => {
    calculateDefaultLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeApp = (id: string) => {
    setHiddenServices([...hiddenServices, id]);
  };

  // Save launchpad configuration into DynamoDB
  const saveLaunchpadConfiguration = async () => {
    const user: any = await Auth.currentAuthenticatedUser();

    await request({
      url: `${config.authConfig.lambdaApiUrl}/idm/customerLaunchpadConfig`,
      method: 'POST',
      params: {
        sub: user.attributes.sub,
      },
      data: {
        config: {
          layouts,
          hiddenServices,
        },
      },
    }).catch(error => console.error(error));

    setIsSaving(false);
  };

  if (isLoadingConfiguration || props.fetchingMagicLink) {
    return (
      <Container>
        <ContentContainer>
          <TitleContainer>
            <Title>
              <Spinner />
            </Title>
          </TitleContainer>
        </ContentContainer>
      </Container>
    );
  }

  function onDrop(layout: any, layoutItem: any, _event: any) {
    const layoutItems = layout.map((item: any) => {
      if (item.i === '__dropping-elem__') {
        const widget = widgets.find(item => item.id === draggingItem);

        const updatedItem = !!widget
          ? // Apply widget configuration to the item
            {
              ...item,
              w: widget.minWidth,
              h: widget.minHeight,
              minW: widget.minWidth,
              minH: widget.minHeight,
              i: draggingItem,
            }
          : {
              ...item,
              i: draggingItem,
            };

        // Eliminate isDraggable from item configuration because it
        // overrides the global grid configuration and we don't want that.
        delete updatedItem.isDraggable;

        return updatedItem;
      }

      return item;
    });
    setLayouts({
      lg: layoutItems,
      md: layoutItems,
      sm: layoutItems,
      xs: layoutItems,
    });
    setHiddenServices(hiddenServices.filter(name => name !== draggingItem));
    setDraggingItem(null);
    setIsDirty(true);
  }

  const editButtonText = isEditMode ? 'Lopeta muokkaus' : 'Muokkaa näkymää';

  return (
    <Container>
      <TopAppBar />
      <ContentContainer>
        {!isEditMode && (
          <TitleContainer>
            <Title>Siirry haluamaasi palveluun</Title>
            <LogoutButton
              className="secondary-button"
              aria-label="Kirjaudu ulos"
              onClick={() =>
                window.location.assign(
                  `${config.authConfig.extranetUrl}/kirjaudu-ulos`,
                )
              }
            >
              <ButtonStartIcon url={logoutIcon} />
              Kirjaudu ulos
            </LogoutButton>
          </TitleContainer>
        )}
        {isEditMode && (
          <TitleContainer>
            <Title>
              <EditTitle>Valitsemasi palvelut:</EditTitle>
              <EditSubtitle>
                Voit muokata kuvakkeiden kokoa ja paikkaa. Halutessasi voit myös
                piilottaa itsellesi tarpeettomia palveluita.
              </EditSubtitle>
            </Title>
          </TitleContainer>
        )}
        <ResponsiveGridLayout
          className="layout"
          isDraggable={isEditMode}
          isResizable={isEditMode}
          isDroppable={isEditMode}
          onDrop={onDrop}
          isBounded={true}
          layouts={layouts}
          margin={[10, 10]}
          breakpoints={BREAKPOINTS_CONFIGURATION}
          onBreakpointChange={(newBreakpoint: 'lg' | 'md' | 'sm' | 'xs') => {
            setActiveBreakpoint(newBreakpoint);
          }}
          preventCollision={false}
          rowHeight={ROW_HEIGHT}
          cols={COLUMNS_CONFIGURATION}
          resizeHandles={['se']}
          onLayoutChange={newLayouts => {
            if (!draggingItem && isEditMode) {
              setLayouts({ ...layouts, [activeBreakpoint]: newLayouts });
              setIsDirty(true);
            }
          }}
        >
          {props.services
            .filter(c => !hiddenServices.includes(c.id))
            .map(c => {
              if (c.type === 'link') {
                return (
                  <ServiceItemGridButton
                    className="service-item-grid-button"
                    key={c.id}
                    onClick={() =>
                      !isEditMode && props.onSelectApplication(c.id)
                    }
                    aria-label={c.title}
                  >
                    <ServiceItem
                      onRemove={isEditMode ? removeApp : undefined}
                      id={c.id}
                      title={c.title}
                      image={c.image}
                    />
                  </ServiceItemGridButton>
                );
              } else if (c.type === 'widget') {
                // Default position and size of an item is supplied
                // as data attribute.
                const widget = widgets.find(item => item.id === c.id);

                if (!widget) {
                  return null;
                }

                const Comp = widget.component;

                return (
                  <ServiceItemGridButton
                    className="service-item-grid-button"
                    key={c.id}
                  >
                    <Comp
                      id={c.id}
                      onRemove={isEditMode ? removeApp : undefined}
                    />
                  </ServiceItemGridButton>
                );
              } else {
                return null;
              }
            })}
        </ResponsiveGridLayout>

        {isEditMode && hiddenServices.length > 0 && (
          <HiddenServicesContainer>
            <TitleContainer>
              <Title>
                <EditTitle>Piilottamasi palvelut:</EditTitle>
                <EditSubtitle>
                  Voit siirtää kuvakkeen takaisin palvelunäkymään painamalla
                  plus-painiketta tai raahamalla kuvakkeen haluamallesi
                  paikalle.{' '}
                </EditSubtitle>
              </Title>
            </TitleContainer>
            <HiddenServicesGrid>
              {hiddenServices.map((serviceName, i) => {
                const c = props.services.find(
                  service => service.id === serviceName,
                );

                if (!c) {
                  return null;
                }

                if (c.type === 'link') {
                  return (
                    <div
                      key={c.id}
                      className="droppable-element"
                      draggable={true}
                      unselectable="on"
                      style={{ height: ROW_HEIGHT, width: '100%', minWidth: 0 }}
                      onDragStart={(e: any) => {
                        e.dataTransfer.setData('text/plain', '');
                        setDraggingItem(c.id);
                      }}
                    >
                      <ServiceItemGridButton
                        key={c.id}
                        tabIndex={0}
                        className="service-item-grid-button"
                        aria-label={c.title}
                      >
                        <ServiceItem
                          id={c.id}
                          title={c.title}
                          image={c.image}
                          onAdd={(id: string) => {
                            setHiddenServices(
                              hiddenServices.filter(name => name !== id),
                            );
                          }}
                        />
                      </ServiceItemGridButton>
                    </div>
                  );
                } else if (c.type === 'widget') {
                  const widget = widgets.find(item => item.id === c.id);

                  if (!widget) {
                    // This should never ever happen
                    throw new Error('Invalid widget configuration');
                  }

                  const Comp = widget.component;

                  return (
                    <div
                      key={c.id}
                      className="droppable-element"
                      draggable={true}
                      unselectable="on"
                      style={{ height: ROW_HEIGHT, minWidth: 0 }}
                      onDragStart={(e: any) => {
                        e.dataTransfer.setData('text/plain', '');
                        setDraggingItem(c.id);
                      }}
                    >
                      <ServiceItemGridButton
                        className="service-item-grid-button"
                        key={c.id}
                      >
                        <Comp
                          id={c.id}
                          onAdd={(id: string) => {
                            setHiddenServices(
                              hiddenServices.filter(name => name !== id),
                            );
                          }}
                          hidden
                        />
                      </ServiceItemGridButton>
                    </div>
                  );
                }

                return null;
              })}
            </HiddenServicesGrid>
          </HiddenServicesContainer>
        )}
        <Buttons>
          <PrimaryButton
            className="primary-button"
            aria-label={isEditMode ? 'Lopeta muokkaus' : 'Muokkaa näkymää'}
            disabled={isSaving}
            onClick={event => {
              event.preventDefault();

              if (isEditMode) {
                if (isDirty) {
                  setIsSaving(true);
                  saveLaunchpadConfiguration();
                  setIsDirty(false);
                }

                setEditMode(false);
              } else {
                setEditMode(true);
              }
            }}
          >
            {isSaving ? (
              <Spinner dark />
            ) : (
              <React.Fragment>
                {editButtonText} <ButtonEndIcon url={chevronRight} />
              </React.Fragment>
            )}
          </PrimaryButton>

          {isEditMode && (
            <SecondaryButton
              className="secondary-button"
              aria-label="Palauta alkuperäiset asetukset"
              onClick={event => {
                event.preventDefault();

                calculateDefaultLayout();
                setHiddenServices([]);
              }}
            >
              Nollaa asetukset
              <ButtonEndIcon url={chevronRight} />
            </SecondaryButton>
          )}

          <TextButton
            className="primary-button"
            aria-label="Evästeseloste"
            onClick={event => {
              event.preventDefault();
              setCookieDeclaration(true);
            }}
          >
            Evästeseloste
            <ButtonEndIcon url={chevronRight} />
          </TextButton>
        </Buttons>
      </ContentContainer>
      <CookieDeclarationModal
        cookieDeclarationOpen={cookieDeclarationOpen}
        setCookieDeclaration={() => setCookieDeclaration(false)}
      />
    </Container>
  );
};

export default LaunchpadV3;
