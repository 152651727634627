import { IAuthConfig } from './AuthConfig';

/**
 * Loimua dev authentication config
 */

// no protocol, the base URL
const extranetDomain = 'asiakasdev.loimua.fi';

const loimuaDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_xMZGdNdRt',
  userPoolWebClientId: '49vhbr17dm7vprmufbks4v00vb',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.loimua-extranet.com', // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://loimua.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-lazy-screw-415',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasdev.loimua-extranet.com/admin/maintenancenotice`,
};

export default loimuaDevAuthConfig;
