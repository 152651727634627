import React from 'react';
import styled, { css } from 'styled-components';
import { config } from '../../config/config';
import RemoveButton from './RemoveButton';
import AddButton from './AddButton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 20px;
  filter: ${config.themeConfig.launchpad2?.appLinkFilter};
  background: ${config.themeConfig.launchpad2?.appLinkBackground};

  &:focus,
  &:hover {
    text-decoration: underline;
    filter: brightness(1.1);
  }

  ${(props: { isEditMode: boolean }) => {
    if (props.isEditMode) {
      return css`
        cursor: move;
      `;
    }

    return css`
      cursor: pointer;
    `;
  }}
`;

const ImageContainer = styled.div`
  flex: 0 0 65%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  flex: 0 0 35%;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 700px) {
    font-size: 11px;
  }

  @media screen and (min-width: 700px) and (max-width: 1000px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1001px) {
    font-size: 20px;
  }

  font-weight: bold;
  color: ${config.themeConfig.launchpad2?.appLinkTitleColor};
`;

const Image = styled.div`
  ${(props: { url: string }) => {
    return css`
      width: 70%;
      height: 60%;
      background: 100% 100% url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
    `;
  }}
`;

type Props = {
  onRemove?: Function;
  onAdd?: Function;
  id: string;
  title: string;
  image?: any;
};

const ServiceItem = (props: Props) => {
  const { onRemove, id, title, image, onAdd } = props;

  return (
    <Container isEditMode={onRemove !== undefined}>
      {onRemove && <RemoveButton onAction={() => onRemove(id)} />}
      {onAdd && <AddButton onAction={() => onAdd(id)} />}
      <ImageContainer>
        <Image url={image} />
      </ImageContainer>
      <Title>{title}</Title>
    </Container>
  );
};

export default ServiceItem;
