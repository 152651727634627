import styled from 'styled-components';
import { config } from '../../config/config';

export const WidgetText = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${config.themeConfig.launchpad3?.widgetTextColor};
`;

export const WidgetValue = styled(WidgetText)`
  font-size: 30px;
`;

export const ButtonBase = styled.div`
  font-size: 24px;
  font-weight: bold;
  border-radius: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
`;
