import { ITextData } from './TextData';
import { EEnvironment } from '../config';

const environmentType = process.env.REACT_APP_ENVIRONMENT_TYPE as EEnvironment;

const PRODUCT_NAME =
  !environmentType || environmentType === 'prod'
    ? 'Helmi'
    : `(${environmentType}) Helmi`;

const electricityContact = `Sähköasiakkaat: asiakaspalvelu(at)sssoy.fi tai puh. 0800 90444 ma-pe 8-18`;
const heatContact = `Lämpöasiakkaat: asiakaspalvelu(at)lempealampo.fi tai puh. 0800 90990 ma-pe 8-16`;

const jesTextData: ITextData = {
  commonTexts: {
    productName: `${PRODUCT_NAME}`,
    signIn: `Kirjaudu sisään ${PRODUCT_NAME}-palveluun`,
    signUp: `Rekisteröidy ${PRODUCT_NAME}-palveluun `,
    extranetButton: `Helmi-palveluun`,
    passwordRequirements: `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
    sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
    ja erikoismerkki.`,
  },
  authStateTitles: {
    signin: `${PRODUCT_NAME} - Kirjautuminen`,
    signup: `${PRODUCT_NAME} - Rekisteröityminen`,
    signout: `${PRODUCT_NAME} - Uloskirjautuminen`,
    loading: `${PRODUCT_NAME} - Ladataan`,
    forgotpassword: `${PRODUCT_NAME} - Palauta salasana`,
    resettingpassword: `${PRODUCT_NAME} - Vaihda salasana`,
    confirmSignUp: `${PRODUCT_NAME} - Vahvista käyttäjätunnus`,
  },
  signUpInfoBox: {
    text: '',
  },
  profileSettings: {
    pageTitle: `${PRODUCT_NAME} - Päivitä käyttäjätiedot`,
    headerTitle: 'Käyttäjätietojen muuttaminen',
    instructions: `Käyttäjätietojen muutos vaikuttaa vain palvelun käyttämiseen ja kirjautumiseen.
      Huom! Tietojen päivittyminen ${PRODUCT_NAME}-palveluun saattaa vaatia uuden kirjautumisen.`,
  },
  changePassword: {
    pageTitle: `${PRODUCT_NAME} - Vaihda salasana`,
    headerTitle: 'Vaihda salasana',
  },
  signicatUndone: {
    pageTitle: `${PRODUCT_NAME} - Tunnistaudu vahvasti`,
    headerTitle: `${PRODUCT_NAME} käyttöönotto 1/3 - Tunnistaudu vahvasti`,
    instructions: [
      `Tervetuloa ${PRODUCT_NAME}-palveluun.`,
      `Tunnistaudu palveluun ensimmäisellä kerralla pankkitunnuksilla tai mobiilivarmenteella.`,
      `Jatkossa voit kirjautua sähköpostiosoitteella ja salasanalla.`,
    ],
    beginSignicat: 'Aloita tunnistautuminen',
  },
  deleteUser: {
    pageTitle: `${PRODUCT_NAME} - Poista käyttäjätunnus`,
    headerTitle: 'Poista käyttäjätunnus',
    confirmText: `Ymmärrän, että poistettuani käyttäjätunnukseni, en pääse kirjautumaan enää ${PRODUCT_NAME}-palveluun.`,
    instructions: [
      `Käyttäjätunnuksen poistamisen jälkeen et pääse kirjautumaan enää ${PRODUCT_NAME}-palveluun. Voit ottaa ${PRODUCT_NAME}-palvelun käyttöösi rekisteröitymällä palveluun uudelleen.`,
      `Huom. Käyttäjätunnuksen poistaminen ${PRODUCT_NAME}-palvelusta ei katkaise verkkopalvelusopimuksiasi
      tai poista asiakkuuksiasi Suur-Savon Sähkön asiakastietojärjestelmästä.
      Tehdäksesi muutoksia verkkopalvelusopimukseesi tai asiakastietoihisi,
      ole yhteydessä asiakaspalveluumme:`,
      electricityContact,
      heatContact,
    ],
  },
  addOrganization: {
    pageTitle: `${PRODUCT_NAME} - Yrityksen lisääminen`,
    headerTitle: 'Yrityksen lisääminen',
    generalInstructions: `Liittämällä yrityksesi y-tunnuksen käyttäjätiliisi saat
      kaikki y-tunnukseesi liitetyt asiakkuudet ja käyttöpaikat
      kerralla näkyviin. Tämän jälkeen myös kaikki uudet asiakkuudet
      päivittyvät näkyviin automaattisesti eikä sinun tarvitse lisätä
      niitä manuaalisesti. Mikäli sinulla on useampi y-tunnus, voit liittää jokaiseen y-tunnukseesi liittyvät asiakkuudet samalla tavalla.`,
    specificInstructions: [
      'Huomaa, että syötetyn tunnuksen tai käyttäpaikkanumeron täytyy liittyä syötettyyn asiakasnumeroon.',
    ],
    checkField1Name: 'Asiakasnumero',
    checkField2Name: 'Liittymätunnus, GSRN-tunnus tai kaukolämmön käyttöpaikkanumero',
  },
  homePage: {
    pageTitle: `${PRODUCT_NAME} - Käyttäjätilin asetukset`,
    headerTitle: 'Käyttäjätilin asetukset',
    instructions: {
      personWithCustomerships: [
        `Käyttäjätiliisi on liitetty seuraavat asiakkuudet`,
        `Voit halutessasi muokata ${PRODUCT_NAME}-palvelussa näytettäviä tietoja piilottamalla asiakkuuden ”Piilota asiakkuus” -painikkeella.
        Piilotetut asiakkuudet voi liittää takaisin milloin tahansa.
        Huomaa, että piilottamisella ei ole vaikutusta verkkopalvelusopimuksesi voimassaoloon tai muihin sopimustietoihisi,
        vaan sen avulla voit ainoastaan muokata ${PRODUCT_NAME}-palvelussa näytettäviä tietoja.`,
        `Jos kaikkia sinulle kuuluvia asiakkuuksiasi ei näy,
        pyydämme ystävällisesti olemaan yhteydessä asiakaspalveluumme:`,
        electricityContact,
        heatContact,
      ],
      personWithoutCustomerships: [
        `Käyttäjätiliisi ei ole tällä hetkellä liitetty yhtäkään asiakkuutta.`,
        `Jos tarvitset apua,
        pyydämme ystävällisesti olemaan yhteydessä asiakaspalveluumme:`,
        electricityContact,
        heatContact,
      ],
      organizationWithCustomerships: [
        `Käyttäjätiliisi on liitetty seuraavat asiakkuudet`,
        `Voit halutessasi muokata ${PRODUCT_NAME}-palvelussa näytettäviä tietoja piilottamalla asiakkuuden ”Piilota asiakkuus” -painikkeella.
        Piilotetut asiakkuudet voi liittää takaisin milloin tahansa.
        Huomaa, että piilottamisella ei ole vaikutusta verkkopalvelusopimuksesi voimassaoloon tai muihin sopimustietoihisi,
        vaan sen avulla voit ainoastaan muokata ${PRODUCT_NAME}-palvelussa näytettäviä tietoja.`,
        `Jos kaikkia y-tunnukseenne liittyviä asiakkuuksia ei löytynyt,
        pyydämme ystävällisesti ottamaan yhteyttä asiakaspalveluumme:`,
        electricityContact,
        heatContact,
      ],
      organizationWithoutCustomerships: [
        `Käyttäjätiliisi ei ole liitetty yhtäkään asiakkuutta.`,
        `Voit käydä liittämässä käyttäjätiliisi yritysasiakkuuden syöttämällä tarvittavat tiedot 'Lisää yritys'-sivulla.`,
      ],
    },
  },
  verifyCustomerships: {
    found: {
      pageTitle: `${PRODUCT_NAME} - Löydetyt asiakkuudet`,
      headerTitle: `${PRODUCT_NAME}-palvelun käyttöönotto 2/3 - Löydetyt asiakkuudet`,
      confirmButton: `Vahvista ja siirry ${PRODUCT_NAME}-palveluun`,
      instructions: [
        `Tässä ovat asiakkuudet, jotka vahva tunnistautuminen yhdisti sinuun.`,
        `Jos asiakkuuksiasi ei löytynyt,
        pyydämme ystävällisesti ottamaan yhteyttä asiakaspalveluumme.`,
        `Jos haluat tarkastella ${PRODUCT_NAME}-palvelussa jonkun toisen henkilön tietoja,
        pyydämme toimittamaan häneltä vapaamuotoisen valtuutuksen sähköpostitse.`,
        electricityContact,
        heatContact,
      ],
    },
    notFound: {
      pageTitle: `${PRODUCT_NAME} - Ei asiakkuuksia`,
      headerTitle: `${PRODUCT_NAME}-palvelun käyttöönotto - Asiakkuuksia ei löytynyt`,
      confirmButton: '',
      instructions: [
        `Vahva tunnistautuminen ei yhdistänyt asiakkuuksia sinuun.`,
        `Pyydämme ystävällisesti ottamaan yhteyttä asiakaspalveluumme asian selvittämiseksi.`,
        `Jos haluat tarkastella ${PRODUCT_NAME}-palvelussa jonkun toisen henkilön tietoja,
        pyydämme toimittamaan häneltä vapaamuotoisen valtuutuksen sähköpostitse.`,
        electricityContact,
        heatContact,
      ],
    },
  },
  manageCustomerAccounts: {
    pageTitle: `${PRODUCT_NAME} - Asiakkaan käyttäjätilin hallinta`,
    headerTitle: 'Asiakkaan käyttäjätilin hallinta',
  },
  launchpad: {
    pageTitle: `${PRODUCT_NAME} - Launchpad`,
    headerTitle: 'Launchpad',
  },
};

export default jesTextData;
