import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { EEnvironment, config } from '../config/config';

const logo = config.themeConfig.logoLight ?? config.themeConfig.logo;

const FormContainer = styled.div`
  background: ${config.themeConfig.colors.formBackground};

  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05),
    0px 0px 1px 0px rgba(255, 255, 255, 0.25) inset,
    0px 0px 84px 0px rgba(255, 255, 255, 0.1) inset;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto%;
  flex: 1;
  align-items: stretch;
  justify-content: flex-start;
  padding: 1em;

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    max-width: 472px;
    padding: 40px 1em;
  }
`;

const Logo = styled.a`
  padding: 16px 0px 18px 0px;
  background-repeat: no-repeat;
  background-image: url(${logo.url});
  width: ${logo.width}px;
  height: ${logo.height}px;
  background-size: ${logo.width}px ${logo.height}px;

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    margin: ${logo.margin}px ${logo.margin}px ${logo.margin}px 40px;
  }
`;

const StyledEnvDisclaimer = styled.div`
  margin-bottom: 15px;
  background: #e32424;
  width: 150px;
  top: 0;
  text-align: center;
  text-transform: uppercase;

  span {
    color: white;
    font-size: ${config.themeConfig.fontSizes.small};
    font-weight: bold;
  }

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    width: 150px;
    position: relative;
    padding: 0 2em;
    margin-left: 40px;
    margin: 15px;
  }
`;

export const EnvDisclaimer = () =>
  config.environmentType !== EEnvironment.PROD ? (
    <StyledEnvDisclaimer>
      <span>
        {config.product} {config.environmentType}
      </span>
    </StyledEnvDisclaimer>
  ) : null;

export const SignInContainer = ({ children }: { children: ReactNode }) => {
  return (
    <FormContainer>
      <EnvDisclaimer />
      <Logo href={'/'} />
      {children}
    </FormContainer>
  );
};
