import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { config } from '../config/config';
import chevronRight from '../images/chevron_right.svg';
import Divider from './Divider';
import ButtonV2 from './Button_v2';
import FormContainer from './FormContainer';
import FormHeader from './FormHeader';
import { InfoBox } from './InfoBox';
import Input from './Input';
import InputLabel from './Label';
import PasswordInput from './PasswordInput_v2';
import signIn from '../utils/auth/signIn';

const { commonTexts } = config.textData;

const Form = styled.form`
  display: flex;
  max-width: 1000px;
  flex-direction: column;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;

  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    justify-content: flex-start;
    & > * {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;

const SignInButton = styled(ButtonV2)`
  background-color: ${config.themeConfig.colors.mainThemeColor2};
  margin: 40px 0px;
`;

const DarkButton = styled(ButtonV2)`
  background-color: ${config.themeConfig.colors.mainThemeColor1};
  border: 1px solid rgba(255, 255, 255, 0.32);
  width: 100%;

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    width: auto;
  }

  @media screen and (max-width: 380px) {
    font-size: 14px;
  }
`;

const ButtonEndIcon = styled.div`
  ${(props: { url: string }) => {
    return css`
      width: 8px;
      height: 12px;
      background: url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      justify-self: flex-end;
      @media screen and (max-width: 330px) {
        width: 6px;
        height: 11px;
      }
    `;
  }}
`;
const FormDivider = styled(Divider)`
  flex-grow: 1;
  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    display: none;
  }
`;

const BottomButtonsContainer = styled.div`
  align-self: flex-end;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BottomButtonArea = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 360px) {
    gap: 10px;
  }
  @media screen and (max-width: 340px) {
    gap: 0px;
  }
`;

const ButtonLabelContainer = styled.div`
  display: flex;
  align-items: ${(props: { position: 'end' | 'start' }) =>
    props.position === 'end' ? 'flex-end' : 'flex-start'};
  flex-direction: column;
  flex: 1;
  font-size: var(--amplify-text-md);
  color: var(--amplify-secondary-color);
  font-family: var(--amplify-font-family);
  gap: 15px;

  @media screen and (max-width: 380px) {
    font-size: 16px;
  }
  @media screen and (max-width: 330px) {
    font-size: 15px;
  }
`;

const TopFormContainer = styled.div`
  align-self: flex-start;
  width: 100%;

  & > * {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export function SignInForm() {
  const [fields, setValues] = useState({
    email: '',
    password: '',
  });

  const signInButtonText = 'Kirjaudu sisään';

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <React.Fragment>
      <FormContainer className="sign-in-form" slot="sign-in">
        <FormHeader className="sign-in-form-header">
          {commonTexts.signIn}
        </FormHeader>
        <Form
          onSubmit={async (event: any) => {
            event.preventDefault();
            await signIn(fields);

            /* Clear away the password so that it's not stored in the state anymore. */
            setValues(prevState => ({
              ...prevState,
              password: '',
              passwordRepeated: '',
            }));
          }}
        >
          <TopFormContainer>
            <Item>
              <InputLabel className="email-label" htmlFor="signin-email">
                Sähköpostiosoite *
              </InputLabel>
              <Input
                className="email-input"
                name="signin-email"
                id="signin-email"
                type="email"
                placeholder="Sähköposti"
                onChange={(event: any) => {
                  setValueToState('email', event.target.value);
                }}
                value={fields.email}
              />
            </Item>

            <Item>
              <InputLabel className="password-label" htmlFor="password">
                Salasana *
              </InputLabel>
              <PasswordInput
                value={fields.password}
                fieldKey="password"
                setValue={setValueToState}
              />
            </Item>

            <SignInButton className="signin-button">
              {signInButtonText}
              <ButtonEndIcon url={chevronRight} />
            </SignInButton>
          </TopFormContainer>
          <BottomButtonsContainer>
            <FormDivider className="form-divider" />
            <BottomButtonArea>
              <ButtonLabelContainer position="start">
                Uusi käyttäjä?{' '}
                <DarkButton
                  className="dark-button"
                  type="button"
                  onClick={event => {
                    event.preventDefault();
                    Hub.dispatch(UI_AUTH_CHANNEL, {
                      event: AUTH_STATE_CHANGE_EVENT,
                      message: AuthState.SignUp,
                    });
                  }}
                >
                  Rekisteröidy
                  <ButtonEndIcon url={chevronRight} />
                </DarkButton>
              </ButtonLabelContainer>
              <ButtonLabelContainer position="end">
                Salasana hukassa?{' '}
                <DarkButton
                  className="dark-button"
                  type="button"
                  onClick={event => {
                    event.preventDefault();
                    Hub.dispatch(UI_AUTH_CHANNEL, {
                      event: AUTH_STATE_CHANGE_EVENT,
                      message: AuthState.ForgotPassword,
                    });
                  }}
                >
                  Palauta salasana
                  <ButtonEndIcon url={chevronRight} />
                </DarkButton>
              </ButtonLabelContainer>
            </BottomButtonArea>
          </BottomButtonsContainer>
        </Form>
        <InfoBox />
      </FormContainer>
    </React.Fragment>
  );
}
