import styled from 'styled-components';
import React from 'react';

const BaseInput = styled.input`
  display: block;
  width: 100%;
  padding: var(--amplify-text-md);
  font-size: var(--amplify-text-sm);
  color: var(--amplify-secondary-color);
  background: transparent;
  background-image: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 0 0.425rem 0;
  height: 3.125rem;
  line-height: 1.1;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;

interface InputProps {
  className: string;
  name: string;
  id: string;
  type: string;
  placeholder: string;
  onChange: (event: any) => void;
  value: string;
}

export default function Input(props: InputProps) {
  return (
    <BaseInput
      className={props.className}
      name={props.name}
      id={props.id}
      type={props.type}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
    ></BaseInput>
  );
}
