import React from 'react';
import styled from 'styled-components';
import { config } from '../config/config';
import { request } from '../helpers';
import { CommonSubSection, CommonSubheader } from './CommonStyles';
import { Spinner } from './Spinner';

const Section = styled(CommonSubSection)`
  padding: 1em;
`;

const Subheader = styled(CommonSubheader)`
  padding: 1em;
`;

const SubSection = styled.div`
  padding: 1em;
  height: auto;
  flex: 1;
  max-width: 500px;
`;

const SubSectionContent = styled.div`
  background-color: ${config.themeConfig.colors.grayLight};
  padding: 2em;
  height: 100%;
`;

const Content = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
`;

const SubSectionHeader = styled(CommonSubheader)`
  font-size: 16px;
`;

const Result = styled.span`
  text-align: left;
  display: block;
  font-size: 18px;
`;

const BigNumber = styled.span`
  font-size: 24px;
`;

const Error = styled.p`
  text-align: left;
  padding: 1em;
`;

type Statistics = {
  count: number;
} | null;

type StatisticsType =
  | 'reportConfigStatistics'
  | 'consumptionAlertStatistics'
  | 'changeThresholdStatistics'
  | 'registeredUsers';

type State = {
  loading: boolean;
  error: boolean;
  statistics: {
    [key in StatisticsType]: Statistics;
  };
};

export class Dashboard extends React.Component<{}> {
  state: State = {
    loading: false,
    error: false,
    statistics: {
      reportConfigStatistics: null,
      consumptionAlertStatistics: null,
      changeThresholdStatistics: null,
      registeredUsers: null,
    },
  };

  componentDidMount = async () => {
    this.setState({
      loading: true,
      error: false,
    });

    try {
      const result = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/statistics`,
        params: {
          adminRequired: true,
        },
      });

      this.setState({
        loading: false,
        error: false,
        statistics: {
          ...result.data,
        },
      });
    } catch (err) {
      console.error(err);

      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  getValue = (property: StatisticsType) => {
    if (this.state.loading) {
      return <Spinner dark />;
    }

    if (!this.state.statistics[property]) {
      return '-';
    }

    return this.state.statistics[property]?.count;
  };

  renderBox = (header: string, propertyName: StatisticsType) => {
    return (
      <SubSection>
        <SubSectionContent>
          <SubSectionHeader>{header}</SubSectionHeader>

          <Result>
            <BigNumber>{this.getValue(propertyName)}</BigNumber> kpl
          </Result>
        </SubSectionContent>
      </SubSection>
    );
  };

  render() {
    return (
      <Section>
        <Subheader>Käyttäjäluvut ja muut tilastot</Subheader>

        {this.state.error ? (
          <Error>Tietoja ei saatu haettua</Error>
        ) : (
          <Content>
            {this.renderBox('Rekisteröityneitä käyttäjiä', 'registeredUsers')}

            {(config.product === 'elenia' || config.product === 'loimua') && (
              <React.Fragment>
                {this.renderBox(
                  'Kuukausiraportin tilaajia',
                  'reportConfigStatistics',
                )}
                {config.product === 'elenia' && this.renderBox(
                  'Kulutusvahdin tilaajia',
                  'consumptionAlertStatistics',
                )}
                {config.product === 'elenia' && this.renderBox(
                  'Muutosrajavahdin tilaajia',
                  'changeThresholdStatistics',
                )}
              </React.Fragment>
            )}
          </Content>
        )}
      </Section>
    );
  }
}
