import React from 'react';
import styled from 'styled-components';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { config } from '../config/config';
import {
  CommonSection,
  CommonSubSection,
  CommonMessage,
  CommonDescription,
  CommonButtonDiv,
} from '../components/CommonStyles';
import { SubmitButton, Link } from '../components/Button';
import { Auth } from 'aws-amplify';
import { request } from '../helpers';

const { deleteUser: texts } = config.textData;

type State = {
  selectCheckbox1: boolean;
  error: boolean;
  errorMessage: string;
};

const Instructions = styled.div`
  max-width: 850px;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  margin-bottom: 1em;
`;

const CheckBox = styled.input`
  margin-right: 1em;
`;

class DeleteUser extends React.Component<{}, State> {
  state: State = {
    selectCheckbox1: false,
    error: false,
    errorMessage: '',
  };

  componentDidMount = async () => {
    document.title = texts.pageTitle;
  };

  removeUser = async (event: any) => {
    event.preventDefault();

    const result = window.confirm(
      'Oletko varma että haluat poistaa käyttäjätunnuksesi?',
    );
    if (result) {
      this.setState({
        error: false,
        errorMessage: '',
      });

      Auth.currentAuthenticatedUser()
        .then(async user => {
          const { sub, email } = user.attributes;

          try {
            await request({
              url: `${config.authConfig.lambdaApiUrl}/idm/customer/deleteCognitoAccount`,
              method: 'POST',
              params: {
                customerEmail: email,
                sub,
              },
            });

            Auth.signOut();
            document.location.assign('/');
          } catch (err) {
            this.setState({
              error: true,
              errorMessage:
                'Käyttäjätunnuksen poistaminen epäonnistui. Yritä myöhemmin uudelleen.',
            });
          }
        })
        .catch(err => {
          console.log('err', err); //FIXME:
        });
    }
  };

  checkBox = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };

  render() {
    const instructions = texts.instructions.map((part: string, i: number) => {
      return <CommonDescription key={i}>{part}</CommonDescription>;
    });

    return (
      <Page>
        <PageHeader text={texts.headerTitle} />
        <CommonSection>
          <CommonSubSection>
            <form onSubmit={this.removeUser}>
              <Instructions>{instructions}</Instructions>

              <CheckBoxLabel>
                <CheckBox
                  type="checkbox"
                  name="selectCheckbox1"
                  checked={this.state.selectCheckbox1}
                  onChange={this.checkBox}
                />
                {texts.confirmText}
              </CheckBoxLabel>

              <CommonButtonDiv>
                <SubmitButton pink disabled={!this.state.selectCheckbox1}>
                  Poista käyttäjätunnus
                </SubmitButton>

                <Link to={'/'}>Sulje</Link>

                <CommonMessage error={this.state.error}>
                  {this.state.errorMessage}
                </CommonMessage>
              </CommonButtonDiv>
            </form>
          </CommonSubSection>
        </CommonSection>
      </Page>
    );
  }
}

export default DeleteUser;
