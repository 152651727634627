import React, { useState } from 'react';
import styled from 'styled-components';
import {
  UI_AUTH_CHANNEL,
  TOAST_AUTH_ERROR_EVENT,
} from '@aws-amplify/ui-components';
import { Auth, Hub } from 'aws-amplify';
import { translations } from '../config/translations';
import { InfoBox } from '../components/InfoBox';
import { PasswordInput } from './PasswordInput';

const Header = styled.h2`
  text-align: left;
  margin: 1rem 0 1.5rem 0;
  font-size: var(--amplify-text-md);
  color: var(--amplify-secondary-color);
  font-family: var(--amplify-font-family);
  font-weight: 700;
  text-transform: unset;
`;

const Container = styled.div``;

const FormContainer = styled.div`
  background-color: white;
  min-width: 10rem;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 35px 40px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubmitButton = styled.button`
  width: 100%;
  background-color: var(--amplify-primary-color);
  padding: 1em;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: 0;
  display: inline-block;
  font-size: var(--amplify-text-sm);
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.75px;

  &:hover {
    opacity: 0.8;
  }
  &[disabled] {
    color: white;
    background-color: var(--amplify-primary-color);
    opacity: 0.5;
    cursor: not-allowed;
    border: 0;

    &:hover,
    &:focus {
      background-color: var(--amplify-primary-color);
      opacity: 0.5;
    }
  }
`;

const validateFields = (values: Array<string>): boolean =>
  values.every(value => value && value.length !== 0);

type TFields = {
  password: string;
};

type Props = {
  user: any;
};

export function PasswordChangeRequiredForm(props: Props) {
  const [fields, setValues] = useState<TFields>({
    password: '',
  });

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Container slot="require-new-password">
      <FormContainer>
        <Header>Uuden salasanan asettaminen</Header>
        <Form
          onSubmit={async (event: any) => {
            event.preventDefault();

            Auth.completeNewPassword(props.user, fields.password, {}).catch(
              error => {
                const translatedErrorMessage = translations.fi[error.message];

                Hub.dispatch(UI_AUTH_CHANNEL, {
                  event: TOAST_AUTH_ERROR_EVENT,
                  message:
                    translatedErrorMessage ||
                    'Tapahtui virhe. Tarkistathan syöttämäsi tiedot.',
                });
              },
            );

            /* Clear away the password so that it's not stored in the state anymore. */
            setValueToState('password', '');
          }}
        >
          <PasswordInput
            onValidPassword={(value: string) =>
              setValueToState('password', value)
            }
          />
          <FooterDiv>
            <SubmitButton disabled={!validateFields([fields.password])}>
              Aseta salasana
            </SubmitButton>
          </FooterDiv>
        </Form>
      </FormContainer>
      <InfoBox />
    </Container>
  );
}
