import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from '../../../Spinner';
import Widget from '../Widget';
import { request } from '../../../../helpers';
import { WidgetText, WidgetValue } from '../../components';
import { config } from '../../../../config/config';
import SpotPriceChart from './SpotPriceChart';
import { isCurrentTimeInRange, isToday, isTomorrow } from './utils';
import { SpotPrice } from './types';
import nextPageIcon from './next-page.svg';

const SmallWidgetText = styled(WidgetText)`
  @media screen and (max-width: 500px) {
    font-size: 10px;
    line-height: 10px;
  }

  @media screen and (min-width: 501px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const TimeString = styled(SmallWidgetText)`
  margin-top: 5px;
`;

const TextContainer = styled.div`
  flex: 0 0 auto;
`;

const NextPageIconContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
`;

const NextPageIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const PrevPageIconContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
`;

const PrevPageIcon = styled.img`
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
`;

const StyledWidgetValue = styled(WidgetValue)`
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }

  @media screen and (min-width: 501px) and (max-width: 700px) {
    font-size: 16px;
  }

  @media screen and (min-width: 700px) and (max-width: 1000px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1001px) {
    font-size: 24px;
  }
`;

enum Day {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
}

type Props = {
  onRemove?: Function;
  onAdd?: Function;
  id: string;
  hidden?: boolean;
};

const getFormattedDate = (d: Date) => {
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const getFormattedDisplayTime = (item: SpotPrice): string => {
  const startTime = new Date(item.startTime);
  const endTime = new Date(item.endTime);

  return `${startTime
    .getHours()
    .toString()
    .padStart(2, '0')}:${startTime
    .getMinutes()
    .toString()
    .padStart(2, '0')} - ${endTime
    .getHours()
    .toString()
    .padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`;
};

const convertEurPerMWhToCntPerKWh = (value: number): number =>
  (value / 1000) * 100;

const withVAT = (value: number): number => value * 1.24;

const SpotPriceWidget = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dayToShow, setDayToShow] = useState(Day.TODAY);
  const [spotPrices, setSpotPrices] = useState(null as null | SpotPrice[]);

  const toggleDay = () => {
    if (dayToShow === Day.TODAY) {
      setDayToShow(Day.TOMORROW);
    } else {
      setDayToShow(Day.TODAY);
    }
  };

  // Fetch spot prices from consumption service
  useEffect(() => {
    if (isLoading) {
      const fromDate = new Date();

      const toDate = new Date();
      toDate.setDate(fromDate.getDate() + 2);

      const searchParams = new URLSearchParams({
        start: getFormattedDate(fromDate),
        end: getFormattedDate(toDate),
      });

      (async () => {
        const spotPrices = await request({
          url: `${
            config.authConfig.widgetsApiUrl
          }/consumption/pricing/spot?${searchParams.toString()}`,
        }).catch(err => {
          setIsLoading(false);
        });

        if (spotPrices && Array.isArray(spotPrices.data)) {
          setSpotPrices(spotPrices.data);
          setIsLoading(false);
        }
      })();
    }
  }, [isLoading]);

  const { id, onRemove, onAdd, hidden } = props;

  // Default values
  let priceCntPerKwh = '--';
  let timeStr = '--';

  // Find the entry for current hour from spot prices array
  const currentHourData =
    spotPrices &&
    spotPrices.find((item: any) =>
      isCurrentTimeInRange(item.startTime, item.endTime),
    );

  if (currentHourData) {
    if (currentHourData.value < 0) {
      // Do not apply VAT for negative price
      priceCntPerKwh = convertEurPerMWhToCntPerKWh(
        currentHourData.value,
      ).toFixed(2);
    } else {
      priceCntPerKwh = withVAT(
        convertEurPerMWhToCntPerKWh(currentHourData.value),
      ).toFixed(2);
    }

    timeStr = getFormattedDisplayTime(currentHourData);
  }

  const predicate = dayToShow === Day.TOMORROW ? isTomorrow : isToday;
  const dataset = spotPrices?.filter(predicate) || [];
  const isEnoughDataEntries = dataset.length === 24; // Entry for each hour

  const isEditMode = onRemove !== undefined;

  const day = dayToShow === Day.TODAY ? 'tänään' : 'huomenna';

  const header = hidden ? 'Sähkön pörssihinta' : `Sähkön pörssihinta ${day}`;

  const nextPageComponent =
    !isEditMode && !hidden && dayToShow === Day.TODAY ? (
      <NextPageIconContainer>
        <NextPageIcon src={nextPageIcon} alt="Seuraava sivu" />
      </NextPageIconContainer>
    ) : null;

  const prevPageComponent =
    !isEditMode && !hidden && dayToShow === Day.TOMORROW ? (
      <PrevPageIconContainer>
        <PrevPageIcon src={nextPageIcon} alt="Seuraava sivu" />
      </PrevPageIconContainer>
    ) : null;

  return (
    <Widget
      id={id}
      onAdd={onAdd}
      onRemove={onRemove}
      header={header}
      onAction={isEditMode ? undefined : toggleDay}
      nextPageComponent={nextPageComponent}
      prevPageComponent={prevPageComponent}
      hidden={props.hidden}
    >
      {!props.hidden && !isLoading && isEnoughDataEntries && (
        <SpotPriceChart dataset={dataset} />
      )}

      {!props.hidden && !isLoading && !isEnoughDataEntries && (
        <TextContainer>
          <SmallWidgetText>Ei saatavilla olevia hintatietoja.</SmallWidgetText>
        </TextContainer>
      )}

      {/* Content to render when the widget is hidden i.e. listed in hidden services */}
      {props.hidden && isEnoughDataEntries && (
        <TextContainer>
          <TimeString></TimeString>
          <StyledWidgetValue>
            {isLoading ? <Spinner /> : `${priceCntPerKwh} snt/kWh`}
          </StyledWidgetValue>
          <SmallWidgetText>{timeStr} (sis. alv 24&nbsp;%)</SmallWidgetText>
        </TextContainer>
      )}

      {/* Content to render when the widget is not hidden */}
      {!props.hidden && isEnoughDataEntries && dayToShow === Day.TODAY && (
        <TextContainer>
          <StyledWidgetValue>
            {isLoading ? <Spinner /> : priceCntPerKwh} snt/kWh
          </StyledWidgetValue>
          {!isLoading && (
            <SmallWidgetText>{timeStr} (sis. alv 24&nbsp;%)</SmallWidgetText>
          )}
        </TextContainer>
      )}
    </Widget>
  );
};

export default SpotPriceWidget;
