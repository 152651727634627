import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { config } from '../config/config';
import { InfoBox } from './InfoBox';
import signIn from '../utils/auth/signIn';

const { commonTexts } = config.textData;

const Header = styled.h2`
  text-align: left;
  margin: 1rem 0 1.5rem 0;
  font-size: var(--amplify-text-md);
  color: var(--amplify-secondary-color);
  font-family: var(--amplify-font-family);
  font-weight: 700;
  text-transform: unset;
`;

const Container = styled.div`
  max-width: 28.5rem;
`;

const FormContainer = styled.div`
  background-color: white;
  min-width: 10rem;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 35px 40px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;
const Label = styled.label`
  margin-bottom: 0.75em;
  font-size: var(--amplify-text-sm);
  color: #152939;
`;
const Input = styled.input`
  display: block;
  width: 100%;
  padding: var(--amplify-text-md);
  font-size: var(--amplify-text-sm);
  color: #152939;
  background-color: var(--amplify-white);
  background-image: none;
  border: 1px solid var(--amplify-light-grey);
  border-radius: 3px;
  box-sizing: border-box;
  margin: 0 0 0.425rem 0;
  height: 3.125rem;
  line-height: 1.1;
`;

const SignInDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const ForgotPasswordDiv = styled.div`
  display: block;
  margin-bottom: 3rem;
  font-size: 0.9rem;
`;

const NewUser = styled.span`
  text-align: left;
  flex: 1;
  width: 100%;
  margin: 1em 0 0.5em 0;
  color: var(--amplify-grey);
  font-size: var(--amplify-text-sm);
  display: grid;
`;

const SignInButton = styled.button`
  background-color: var(--amplify-primary-color);
  padding: 1em;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: 0;
  display: inline-block;
  font-size: var(--amplify-text-sm);
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.75px;

  &:hover {
    opacity: 0.8;
  }
`;

const SignInLink = styled.button`
  color: var(--amplify-primary-color);
  background-color: inherit;
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  text-align: left;
`;

const ShowHidePassword = styled.div`
  text-align: right;
  cursor: pointer;
`;

const ForgotPasswordLink = SignInLink;

export function SignInForm() {
  const [fields, setValues] = useState({
    email: '',
    password: '',
  });

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const signInButtonText = 'Kirjaudu sisään';

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <React.Fragment>
      <Container slot="sign-in">
        <FormContainer>
          <Header>{commonTexts.signIn}</Header>
          <Form
            onSubmit={async (event: any) => {
              event.preventDefault();
              await signIn(fields);

              /* Clear away the password so that it's not stored in the state anymore. */
              setValues(prevState => ({
                ...prevState,
                password: '',
                passwordRepeated: '',
              }));
            }}
          >
            <Item>
              <Label htmlFor="signin-email">Sähköpostiosoite *</Label>
              <Input
                name="signin-email"
                id="signin-email"
                type="email"
                placeholder="Sähköposti"
                onChange={(event: any) => {
                  setValueToState('email', event.target.value);
                }}
                value={fields.email}
              />
            </Item>

            <Item>
              <Label htmlFor="password">Salasana *</Label>
              <ShowHidePassword
                onClick={() => setIsRevealPwd(prevState => !prevState)}
              >
                {isRevealPwd ? 'Piilota salasana ' : 'Näytä salasana'}
              </ShowHidePassword>
              <Input
                name="password"
                id="password"
                type={isRevealPwd ? 'text' : 'password'}
                placeholder="Salasana"
                onChange={(event: any) => {
                  setValueToState('password', event.target.value);
                }}
                value={fields.password}
              />
            </Item>

            <ForgotPasswordDiv>
              Unohditko salasanasi?{' '}
              <ForgotPasswordLink
                type="button"
                onClick={event => {
                  event.preventDefault();
                  Hub.dispatch(UI_AUTH_CHANNEL, {
                    event: AUTH_STATE_CHANGE_EVENT,
                    message: AuthState.ForgotPassword,
                  });
                }}
              >
                Palauta salasana
              </ForgotPasswordLink>
            </ForgotPasswordDiv>

            <SignInDiv>
              <NewUser>
                Uusi käyttäjä?{' '}
                <SignInLink
                  type="button"
                  onClick={event => {
                    event.preventDefault();
                    Hub.dispatch(UI_AUTH_CHANNEL, {
                      event: AUTH_STATE_CHANGE_EVENT,
                      message: AuthState.SignUp,
                    });
                  }}
                >
                  Rekisteröidy
                </SignInLink>
              </NewUser>
              <SignInButton>{signInButtonText}</SignInButton>
            </SignInDiv>
          </Form>
        </FormContainer>
        <InfoBox />
      </Container>
    </React.Fragment>
  );
}
