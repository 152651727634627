import styled, { css } from 'styled-components';
import { config } from '../../config/config';
import {
  CommonSubSection,
  CommonButtonDiv,
  CommonFormItem,
} from '../../components/CommonStyles';

export const DeleteButton = styled.button`
  border: 0;
  background-color: unset;
  text-decoration: underline;
  color: ${config.themeConfig.colors.secondaryColor1};
`;

export const FoundCustomerIDs = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0 2em 0;
  flex-wrap: wrap;
`;

export const PasswordInputWrapper = styled.div`
  margin-top: 20px;
`;

export const HidableSection = styled(CommonSubSection)`
  display: none;

  ${(props: { show: boolean }) =>
    props.show &&
    css`
      display: block;
    `}
`;

export const ResultRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
`;

export const SearchResultContainer = styled.div`
  width: auto;
  text-align: left;
  width: auto;
  background-color: rgb(245, 245, 245);
  padding: 2em;
  line-height: 1.5;

  h3 {
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  button {
    margin-right: 1em;
    width: 100%;

    @media screen and (min-width: 800px) {
      width: auto;
    }
  }

  @media screen and (min-width: 800px) {
    width: 700px;
  }
`;

export const StyledButtons = styled(CommonButtonDiv)`
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 850px) {
    flex-direction: row;
  }

  button,
  a {
    margin: 0 0.5em 0.5em 0.5em;
    width: 100%;
    flex: 1;

    @media screen and (min-width: 800px) {
      margin: 0 0 0.5em 0;
      flex: unset;
    }
    @media screen and (min-width: 850px) {
      margin: 0 0 0 0.5em;
      width: auto;
    }
  }
`;

export const StyledForm = styled.form`
  height: 100%;
  width: 100%;
  margin-bottom: 1em;
  margin-right: 2em;
  max-width: 500px;

  @media screen and (min-width: 800px) {
    width: 35%;
  }
`;

export const PasswordForm = styled.form`
  height: 100%;
  width: 100%;
  margin-bottom: 1em;
  margin-right: 2em;
`;

export const StyledFormItem = styled(CommonFormItem)`
  input {
    max-width: 400px;
    margin-left: 0.5em;

    @media screen and (min-width: 800px) {
      margin-left: 0;
      max-width: unset;
    }
  }

  label {
    margin-left: 0.5em;

    @media screen and (min-width: 800px) {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 600px) {
    padding: 0;
    width: 100%;
    min-width: unset;
  }
`;

export const UserListItem = styled.li`
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const UserListDescription = styled.div`
  margin-bottom: 15px;
`;
