/**
 * This file exports the correct auth object dynamically for each product version,
 * and environment, leaving the unused configs out of the build.
 */
import { EProduct, EEnvironment } from '../config';

interface CookieStorage {
  domain: string,
  secure: boolean,
  path: string,
  expires: number,
};

export interface IAuthConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  // Identity Pool ID is not needed unless Pinpoint analytics are used.
  identityPoolId?: string;
  authenticationFlowType: 'USER_PASSWORD_AUTH';
  lambdaApiUrl: string;
  SignicatApiUrl: string;
  SignicatApiClientId: string;
  extranetUrl: string;
  adminServiceFrontendUrl: string;
  maintenanceNoticeServiceUrl?: string;
  cookieStorage?: CookieStorage;
  // Used by launchpad widgets
  widgetsApiUrl?: string,
  magicLinkAuthUrl?: string,
}

export const AuthConfig = (
  product: EProduct,
  environmentType: EEnvironment,
): IAuthConfig => {
  try {
    return require(`./AuthConfig.${product}.${environmentType}`).default;
  } catch (err) {
    throw Error(
      `Invalid PRODUCT (${product}) and/or ENVIRONMENT_TYPE (${environmentType}) specified`,
    );
  }
};
