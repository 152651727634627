import React from 'react';
import styled from 'styled-components';

const Header = styled.h2`
  text-align: left;
  margin: 1rem 0 1.5rem 0;
  font-size: var(--amplify-text-xl);
  color: var(--amplify-secondary-color);
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-transform: unset;
`;

interface HeaderProps {
  className: string;
  children: React.ReactNode;
}

export default function FormHeader(props: HeaderProps) {
  return <Header className={props.className}>{props.children}</Header>;
}
