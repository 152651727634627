import { IThemeConfig } from './ThemeConfig';
import logoUrl from '../../images/nice/logo.jpg';

const niceThemeConfig: IThemeConfig = {
  colors: {
    mainThemeColor1: 'rgb(128, 189, 38)', // green
    mainThemeColor2: 'rgb(96, 23, 123)', // purple
    secondaryColor1: 'rgb(238, 116, 33)', // orange
    secondaryColor2: 'rgb(244, 255, 16)', // yellow
    secondaryColor3: 'rgb(52, 170, 230)', // blue
    noticesTextColor: 'rgb(255, 255, 255)',
    noticesBackgroundColor: 'rgb(96, 23, 123)',

    // Gray colors
    grayDark: 'rgb(53, 53, 53)',
    grayMedium: 'rgb(200, 200, 200)',
    grayLight: 'rgb(238, 238, 238)',
  },
  logo: {
    width: 180,
    height: 60,
    margin: 10,
    url: logoUrl,
  },
  fontSizes: {
    small: '14px',
    medium: '18px',
    large: '20px',
    extraLarge: '32px',
  },
  fontFamilies: {
    headers: 'Helvetica Neue, arial, helvetica, verdana, sans-serif',
    regular: 'Apex, arial, helvetica, verdana, sans-serif',
    buttonFont: 'Lato, arial, helvetica, verdana, sans-serif',
  },
  mobileBreakPoint: 900
};

export default niceThemeConfig;
