import React from 'react';
import styled, { css } from 'styled-components';
import { config } from '../../../config/config';
import RemoveButton from './../RemoveButton';
import AddButton from '../AddButton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 20px;
  filter: ${config.themeConfig.launchpad2?.widgetFilter};
  background: ${config.themeConfig.launchpad2?.widgetBackground};

  ${(props: { isEditMode: boolean }) => {
    if (props.isEditMode) {
      return css`
        cursor: move;
      `;
    }

    return css`
      cursor: pointer;
    `;
  }}
`;

const Header = styled.div`
  flex: 0 0 auto;
  font-weight: bold;

  ${(props: { isHidden: boolean }) => {
    console.log(props.isHidden);
    if (!props.isHidden) {
      return css`
        padding: 5px 25px;
      `;
    }

    return css`
      padding: 5px;
    `;
  }}

  color: ${config.themeConfig.launchpad2?.widgetTitleColor};

  @media screen and (min-width: 501px) and (max-width: 700px) {
    font-size: 16px;
  }

  @media screen and (min-width: 700px) and (max-width: 1000px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1001px) {
    font-size: 20px;
  }
`;

const ChildContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type Props = {
  onRemove?: Function;
  onAdd?: Function;
  id: string;
  children: any;
  header: string;
  onAction?: Function;
  nextPageComponent: JSX.Element | null;
  prevPageComponent: JSX.Element | null;
  hidden?: boolean;
};

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Widget = (props: Props) => {
  const {
    onRemove,
    onAdd,
    id,
    header,
    onAction,
    nextPageComponent,
    prevPageComponent,
    hidden,
  } = props;

  const isHidden = hidden || false;

  return (
    <Container
      id={id}
      isEditMode={onRemove !== undefined}
      onClick={onAction ? () => onAction() : () => {}}
    >
      <HeaderContainer>
        <Header isHidden={isHidden}>{header}</Header>
        {prevPageComponent}
        {nextPageComponent}
      </HeaderContainer>
      {onRemove && <RemoveButton onAction={() => onRemove(id)} />}
      {onAdd && <AddButton onAction={() => onAdd(id)} />}
      <ChildContainer>{props.children}</ChildContainer>
    </Container>
  );
};

export default Widget;
