import { IAuthConfig } from './AuthConfig';

/**
 * Elenia qa authentication config
 */

// no protocol, the base domain
const extranetDomain = 'asiakasqa.elenia.fi';

const eleniaQaAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_JXRsbYwWz',
  userPoolWebClientId: '5b0cmchn7ni4thka3r0gg55vpj',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  widgetsApiUrl: 'https://api.asiakasqa.aina-extranet.com',
  lambdaApiUrl: `https://api.asiakasqa.aina-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://elenia.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-defiant-card-977',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasqa.aina-extranet.com/admin/maintenancenotice`,
  cookieStorage: {
    domain: '.elenia.fi',
    secure: true,
    path: '/',
    expires: 30,
  },
};

export default eleniaQaAuthConfig;
