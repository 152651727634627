import { Translations } from '@aws-amplify/ui-components';
import { config } from './config';

export const translations = {
  fi: {
    'Username cannot be empty':
      'Sähköpostiosoite puuttuu tai se on virheellinen. Käytäthän sähköpostiosoitteessa vain pieniä kirjaimia.',
    'Password did not conform with policy: Password must have numeric characters': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    'Password does not conform with policy: Password must have numeric characters': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
    sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
    ja erikoismerkki.`,
    'Password did not conform with policy: Password must have uppercase characters': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    'Password does not conform to policy: Password must have uppercase characters': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    'Password did not conform with policy: Password must have symbol characters': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    'Password does not conform with policy: Password must have symbol characters': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    'Invalid email address format.':
      'Sähköpostiosoite puuttuu tai se on virheellinen. Käytäthän sähköpostiosoitteessa vain pieniä kirjaimia. Ole hyvä ja tarkista tiedot.',
    'An account with the given email already exists.':
      'Annetulla sähköpostiosoitteella on jo tunnus.',
    'User does not exist.':
      'Annetulla sähköpostiosoitteella ei löytynyt käyttäjätunnusta. Käytäthän sähköpostiosoitteessa vain pieniä kirjaimia.',
    'Incorrect username or password.':
      'Väärä käyttäjätunnus tai salasana. Käytäthän sähköpostiosoitteessa vain pieniä kirjaimia. Ole hyvä ja tarkista tiedot.',
    'Confirmation code cannot be empty': 'Syötä vahvistuskoodi.',
    'Invalid phone number format.':
      'Syötä puhelinnumerosi kansainvälisessä muodossa (+358...)',
    'CUSTOM_AUTH is not enabled for the client.':
      'Tapahtui virhe. Tarkistathan syöttämäsi tiedot.',
    'Invalid verification code provided, please try again.':
      'Väärä vahvistuskoodi. Ole hyvä ja yritä uudelleen.',
    'Invalid code provided, please request a code again.':
      'Väärä vahvistuskoodi. Ole hyvä ja yritä uudelleen.',
    /**
     * Passwords that are too short throw several different kinds of error, so that's why there's some 'overlapping' here.
     * It seems that amplify-js catches cases where the password is less than 6 characters and
     * only password that are more than that and less than the length specified
     * in the Cognito are caught by the Cognito => different error messages with confusing error messages.
     *
     */
    // User tries to submit a form without a password
    'Password cannot be empty': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    // this error is thrown when the user tries to sign up with a password that's less than 6 characters
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    // this error is thrown when the password is updated with a new password that's less than 6 characters
    "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6": `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,
    // this error is thrown when the user tries to sign up/update a password that's more than 6 characters, but less than 8.
    'Password did not conform with policy: Password not long enough': `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
      sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
      ja erikoismerkki.`,

    [Translations.BACK_TO_SIGN_IN]: 'Takaisin kirjautumiseen',
    [Translations.CHANGE_PASSWORD_ACTION]: 'Vaihda',
    [Translations.CHANGE_PASSWORD]: 'Vaihda salasana',
    [Translations.CODE_LABEL]: 'Vahvistuskoodi',
    [Translations.CODE_PLACEHOLDER]: 'Syötä koodi',
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Vahvistuskoodi',
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Syötä vahvistuskoodi',
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Vahvista rekisteröityminen',
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Koodi hukassa?',
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Lähetä uusi koodi',
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Vahvista',
    [Translations.CONFIRM_SMS_CODE]: 'Vahvista SMS-koodi',
    [Translations.CONFIRM_TOTP_CODE]: 'Vahvista TOTP-koodi',
    [Translations.CONFIRM]: 'Vahvista',
    [Translations.CREATE_ACCOUNT_TEXT]: 'Rekisteröidy',
    [Translations.EMAIL_LABEL]: 'Sähköpostiosoite',
    [Translations.EMAIL_PLACEHOLDER]: 'Syötä sähköpostiosoite',
    [Translations.FORGOT_PASSWORD_TEXT]: 'Unohditko salasanasi?',
    [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]:
      'Alle kaksi MFA-tyyppiä saatavilla',
    [Translations.NEW_PASSWORD_LABEL]: 'Uusi salasana',
    [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Syötä uusi salasana',
    [Translations.NO_ACCOUNT_TEXT]: 'Uusi käyttäjä?',
    [Translations.PASSWORD_LABEL]: 'Salasana',
    [Translations.PASSWORD_PLACEHOLDER]: 'Syötä salasana',
    [Translations.PHONE_LABEL]: 'Puhelinnumero *',
    [Translations.PHONE_PLACEHOLDER]: '+358401234567',
    [Translations.QR_CODE_ALT]: 'qr-koodi',
    [Translations.RESET_PASSWORD_TEXT]: 'Palauta salasana',
    [Translations.RESET_YOUR_PASSWORD]: 'Palauta salasana',
    [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'Valitse MFA-tyyppi',
    [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Vahvista',
    [Translations.SEND_CODE]: 'Lähetä koodi',
    [Translations.SETUP_TOTP_REQUIRED]: 'TOTP täytyy määritellä',
    [Translations.SIGN_IN_ACTION]: 'Kirjaudu sisään',
    [Translations.SIGN_IN_HEADER_TEXT]: `${config.textData.commonTexts.signIn}`,
    [Translations.SIGN_IN_TEXT]: 'Kirjaudu',
    [Translations.SIGN_IN_WITH_AMAZON]: 'Amazon-kirjautuminen',
    [Translations.SIGN_IN_WITH_AUTH0]: 'Auth0-kirjautuminen',
    [Translations.SIGN_IN_WITH_AWS]: 'AWS-kirjautuminen',
    [Translations.SIGN_IN_WITH_FACEBOOK]: 'Facebook-kirjautuminen',
    [Translations.SIGN_IN_WITH_GOOGLE]: 'Google-kirjautuminen',
    [Translations.SIGN_OUT]: 'Kirjaudu ulos',
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'Sähköposti',
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Oletko jo rekisteröitynyt?',
    [Translations.SIGN_UP_HEADER_TEXT]: `${config.textData.commonTexts.signUp}`,
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Salasana',
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Rekisteröidy',
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Käyttäjätunnus',
    [Translations.SUCCESS_MFA_TYPE]: 'Onnistui! MFA-tyyppi on nyt:',
    [Translations.TOTP_HEADER_TEXT]: 'Skannaa ja syötä vahvistuskoodi',
    [Translations.TOTP_LABEL]: 'Syötä turvakoodi:',
    [Translations.TOTP_SETUP_FAILURE]: 'TOTP asetus epäonnistui',
    [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Vahvista Security Token',
    [Translations.TOTP_SUCCESS_MESSAGE]: 'TOTP asetettu onnistuneesti!',
    [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]:
      'Virhe! MFA asetus epäonnistui',
    [Translations.USERNAME_LABEL]: 'Käyttäjätunnus *',
    [Translations.USERNAME_PLACEHOLDER]: 'Syötä käyttäjätunnus',
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Sähköposti',
    [Translations.VERIFY_CONTACT_HEADER_TEXT]:
      'Käyttäjätilin palautus vaatii vahvistetun yhteystiedon',
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Puhelinnumero',
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Lähetä',
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Vahvista',
  },
  /**
   * English originals for reference
   */
  /*
    Translations["BACK_TO_SIGN_IN"] = "Back to Sign In";
    Translations["CHANGE_PASSWORD_ACTION"] = "Change";
    Translations["CHANGE_PASSWORD"] = "Change Password";
    Translations["CODE_LABEL"] = "Verification code";
    Translations["CODE_PLACEHOLDER"] = "Enter code";
    Translations["CONFIRM_SIGN_UP_CODE_LABEL"] = "Confirmation Code";
    Translations["CONFIRM_SIGN_UP_CODE_PLACEHOLDER"] = "Enter your code";
    Translations["CONFIRM_SIGN_UP_HEADER_TEXT"] = "Confirm Sign up";
    Translations["CONFIRM_SIGN_UP_LOST_CODE"] = "Lost your code?";
    Translations["CONFIRM_SIGN_UP_RESEND_CODE"] = "Resend Code";
    Translations["CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT"] = "Confirm";
    Translations["CONFIRM_SMS_CODE"] = "Confirm SMS Code";
    Translations["CONFIRM_TOTP_CODE"] = "Confirm TOTP Code";
    Translations["CONFIRM"] = "Confirm";
    Translations["CREATE_ACCOUNT_TEXT"] = "Create account";
    Translations["EMAIL_LABEL"] = "Email Address *";
    Translations["EMAIL_PLACEHOLDER"] = "Enter your email address";
    Translations["FORGOT_PASSWORD_TEXT"] = "Forgot your password?";
    Translations["LESS_THAN_TWO_MFA_VALUES_MESSAGE"] = "Less than two mfa types available";
    Translations["NEW_PASSWORD_LABEL"] = "New password";
    Translations["NEW_PASSWORD_PLACEHOLDER"] = "Enter your new password";
    Translations["NO_ACCOUNT_TEXT"] = "No account?";
    Translations["PASSWORD_LABEL"] = "Password *";
    Translations["PASSWORD_PLACEHOLDER"] = "Enter your password";
    Translations["PHONE_LABEL"] = "Phone Number *";
    Translations["PHONE_PLACEHOLDER"] = "(555) 555-1212";
    Translations["QR_CODE_ALT"] = "qrcode";
    Translations["RESET_PASSWORD_TEXT"] = "Reset password";
    Translations["RESET_YOUR_PASSWORD"] = "Reset your password";
    Translations["SELECT_MFA_TYPE_HEADER_TEXT"] = "Select MFA Type";
    Translations["SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT"] = "Verify";
    Translations["SEND_CODE"] = "Send Code";
    Translations["SETUP_TOTP_REQUIRED"] = "TOTP needs to be configured";
    Translations["SIGN_IN_ACTION"] = "Sign In";
    Translations["SIGN_IN_HEADER_TEXT"] = "Sign in to your account";
    Translations["SIGN_IN_TEXT"] = "Sign in";
    Translations["SIGN_IN_WITH_AMAZON"] = "Sign in with Amazon";
    Translations["SIGN_IN_WITH_AUTH0"] = "Sign in with Auth0";
    Translations["SIGN_IN_WITH_AWS"] = "Sign in with AWS";
    Translations["SIGN_IN_WITH_FACEBOOK"] = "Sign in with Facebook";
    Translations["SIGN_IN_WITH_GOOGLE"] = "Sign in with Google";
    Translations["SIGN_OUT"] = "Sign Out";
    Translations["SIGN_UP_EMAIL_PLACEHOLDER"] = "Email";
    Translations["SIGN_UP_HAVE_ACCOUNT_TEXT"] = "Have an account?";
    Translations["SIGN_UP_HEADER_TEXT"] = "Create a new account";
    Translations["SIGN_UP_PASSWORD_PLACEHOLDER"] = "Password";
    Translations["SIGN_UP_SUBMIT_BUTTON_TEXT"] = "Create Account";
    Translations["SIGN_UP_USERNAME_PLACEHOLDER"] = "Username";
    Translations["SUCCESS_MFA_TYPE"] = "Success! Your MFA Type is now:";
    Translations["TOTP_HEADER_TEXT"] = "Scan then enter verification code";
    Translations["TOTP_LABEL"] = "Enter Security Code:";
    Translations["TOTP_SETUP_FAILURE"] = "TOTP Setup has failed";
    Translations["TOTP_SUBMIT_BUTTON_TEXT"] = "Verify Security Token";
    Translations["TOTP_SUCCESS_MESSAGE"] = "Setup TOTP successfully!";
    Translations["UNABLE_TO_SETUP_MFA_AT_THIS_TIME"] = "Failed! Unable to configure MFA at this time";
    Translations["USERNAME_LABEL"] = "Username *";
    Translations["USERNAME_PLACEHOLDER"] = "Enter your username";
    Translations["VERIFY_CONTACT_EMAIL_LABEL"] = "Email";
    Translations["VERIFY_CONTACT_HEADER_TEXT"] = "Account recovery requires verified contact information";
    Translations["VERIFY_CONTACT_PHONE_LABEL"] = "Phone Number";
    Translations["VERIFY_CONTACT_SUBMIT_LABEL"] = "Submit";
    Translations["VERIFY_CONTACT_VERIFY_LABEL"] = "Verify";
  */
};
