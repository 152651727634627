import React, { ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { SubmitButton, Link } from '../components/Button';
import { config } from '../config/config';
import {
  CommonButtonDiv,
  CommonSubheader,
  CommonSubSection,
  CommonDescription,
  CommonFormItem,
  CommonLabel,
  CommonInput,
  CommonSection,
  CommonMessage,
  CommonFormItemWrapper,
} from '../components/CommonStyles';
import { translations } from '../config/translations';

const { commonTexts, changePassword: texts } = config.textData;

type State = {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeated: string;
  error: boolean;
  msg: string;
};

const StyledButtons = styled(CommonButtonDiv)`
  align-items: center;
`;

class ChangePassword extends React.Component<{}, State> {
  state: State = {
    currentPassword: '',
    newPassword: '',
    newPasswordRepeated: '',
    error: false,
    msg: '',
  };

  componentDidMount = async () => {
    document.title = texts.pageTitle;
  };

  handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
      error: value.length === 0,
      msg: value.length === 0 ? 'Virhe' : '', // TODO:
    });
  };

  saveNewPassword = async (event: any) => {
    event.preventDefault();

    if (this.state.newPassword !== this.state.newPasswordRepeated) {
      this.setState({
        currentPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
        error: true,
        msg: 'Uusi salasana ei vastaa uudelleen kirjoitettua salasanaa.',
      });

      return;
    }

    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(
          user,
          this.state.currentPassword,
          this.state.newPassword,
        );
      })
      .then(data => {
        this.setState({
          currentPassword: '',
          newPassword: '',
          newPasswordRepeated: '',
          error: false,
          msg: 'Salasana vaihdettu onnistuneesti!',
        });
      })
      .catch(err => {
        const message = translations.fi[err.message];

        this.setState({
          currentPassword: '',
          newPassword: '',
          newPasswordRepeated: '',
          error: true,
          msg:
            message ||
            'Virhe salasanaa tallennettaessa. Tarkistathan että syötit kaikki tiedot oikein.',
        });
      });
  };

  render() {
    const {
      currentPassword,
      newPassword,
      newPasswordRepeated,
      error,
      msg,
    } = this.state;

    const saveEnabled =
      currentPassword.length !== 0 &&
      newPassword.length !== 0 &&
      newPasswordRepeated.length !== 0;

    return (
      <Page>
        <PageHeader text={texts.headerTitle} />
        <CommonSection>
          <CommonSubSection>
            <CommonSubheader>Vaihda salasana</CommonSubheader>
            <CommonDescription>
              {commonTexts.passwordRequirements}
            </CommonDescription>

            <form onSubmit={this.saveNewPassword}>
              <CommonFormItemWrapper>
                <CommonFormItem>
                  <CommonLabel htmlFor="currentPassword">
                    Nykyinen salasana *
                  </CommonLabel>
                  <CommonInput
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    onChange={this.handleInputChange}
                    value={currentPassword}
                  />
                </CommonFormItem>

                <CommonFormItem>
                  <CommonLabel htmlFor="newPassword">
                    Uusi salasana *
                  </CommonLabel>
                  <CommonInput
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    onChange={this.handleInputChange}
                    value={newPassword}
                  />
                </CommonFormItem>

                <CommonFormItem>
                  <CommonLabel htmlFor="newPasswordRepeated">
                    Uusi salasana uudestaan *
                  </CommonLabel>
                  <CommonInput
                    type="password"
                    name="newPasswordRepeated"
                    id="newPasswordRepeated"
                    onChange={this.handleInputChange}
                    value={newPasswordRepeated}
                  />
                </CommonFormItem>
              </CommonFormItemWrapper>

              <StyledButtons>
                <SubmitButton disabled={!saveEnabled}>
                  Tallenna uusi salasana
                </SubmitButton>

                <Link pink to={'/'}>
                  Sulje
                </Link>

                <CommonMessage error={error}>{msg}</CommonMessage>
              </StyledButtons>
            </form>
          </CommonSubSection>
        </CommonSection>
      </Page>
    );
  }
}

export default ChangePassword;
