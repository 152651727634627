import React from 'react';
import styled, { css } from 'styled-components';
import smallSpinner from '../images/small-spinner.svg';
import smallSpinnerDark from '../images/small-spinner-dark.svg';

const SmallSpinner = styled.div`
  align-self: center;
  display: inline-block;
  animation: spin 0.7s linear infinite;
  transform-origin: 50% 50%;
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
  vertical-align: middle;
  background: 100% 100% url(${smallSpinner});

  @keyframes spin {
    from {
      transform: rotateZ(0) translateZ(0);
    }
    to {
      transform: rotateZ(360deg) translateZ(0);
    }
  }

  ${(props: Props) =>
    !!props.dark &&
    css`
      background: 100% 100% url(${smallSpinnerDark});
    `}
`;

type Props = {
  dark?: boolean;
};

export const Spinner = (props: Props) => <SmallSpinner dark={props.dark} />;
