import { IAnalyticsConfig } from './AnalyticsConfig';

const jesProdAnalyticsConfig: IAnalyticsConfig = {
  // Amazon Pinpoint App Client ID
  appId: '20183c3e29d8427eb093446a61e19cc1',
  // Amazon service region
  region: 'eu-west-1',
  mandatorySignIn: false,
};

export default jesProdAnalyticsConfig;
