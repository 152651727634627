import styled, { css } from 'styled-components';
import { config } from '../config/config';

export const CommonSubheader = styled.h2`
  text-align: left;
  padding: 0 0 1em 0;
`;

export const CommonDescription = styled.p`
  text-align: left;
  padding: 0 0 1em 0;
  max-width: 900px;
`;

export const CommonSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${config.themeConfig.colors.grayLight};
  padding: 1em;
  height: auto;
`;

export const CommonSubSection = styled.div`
  background-color: white;
  padding: 2em;
  margin-bottom: 1em;
`;

export const CommonButtonDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 1em;
  flex-wrap: wrap;

  a,
  button {
    margin-right: 0.5em;
    margin-top: 0.5em;
  }
`;

export const CommonFormItemWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CommonFormItem = styled.div`
  text-align: left;
  width: 100%;
  padding: 0 0 1em 0;

  @media screen and (min-width: 600px) {
    padding: 0 1em 1em 0;
    width: 50%;
    min-width: 290px;
  }
`;

export const CommonLabelWithState = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CommonLabel = styled.label`
  font-weight: bold;
  padding: 0 0 0.5em 0;
  display: block;
`;

export const CommonInput = styled.input`
  border-radius: 2px;
  border: 1px solid ${config.themeConfig.colors.grayMedium};
  padding: 0.5em;
  width: 100%;

  @media screen and (min-width: 600px) {
    max-width: 300px;
  }
`;

export const CommonColumns = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
`;

export const CommonMessage = styled.span`
  color: ${config.themeConfig.colors.mainThemeColor2};
  margin: 0.5em 0;
  text-align: right;
  display: block;

  ${(props: { error: boolean }) =>
    props.error &&
    css`
      color: red;
    `}
`;
