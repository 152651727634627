import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from './config/config';
import { Address } from './types';

const ADMIN_GROUPS: string[] = [
  'Admins',
  'Testers',
  'Developers',
  'CustomerService',
];

const STATISTICS_GROUPS: string[] = ['Admins', 'Developers'];

const CONTENT_MANAGER_GROUPS: string[] = [
  'Admins',
  'Testers',
  'ContentManagers',
];

class RequestError extends Error {
  private statusCode: number;
  constructor(props: { message: string; statusCode: number }) {
    super(props.message);
    this.name = 'RequestError';
    this.statusCode = props.statusCode;
  }
}

export function getStreetAddress(address: Address | null): string {
  return address
    ? [
        address.streetName,
        address.houseNumber,
        address.stairCase,
        address.apartmentNumber,
        address.postalCode,
        address.postOffice,
      ]
        .filter((value: string | null) => value && typeof value === 'string')
        .join(' ')
    : '';
}

export const request = async ({
  url,
  params,
  data,
  method = 'GET',
}: {
  url: string;
  params?: { [key: string]: any };
  data?: { [key: string]: any };
  method?: string;
}): Promise<AxiosResponse> => {
  const user: any = await Auth.currentAuthenticatedUser().catch(
    // TODO: If this happens for some reason, should forward to login page?
    err => console.log('Signed in user not found, ', err),
  );
  const token = (user && user.signInUserSession.idToken.jwtToken) ?? null;

  const conf = {
    params,
    url,
    method,
    data,
    proxy: false,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  // TODO: Handle error
  try {
    const res = await axios(conf as AxiosRequestConfig);

    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new RequestError({ message: 'Not found', statusCode: 404 });
    }

    if (error.response && error.response.status === 403) {
      throw new RequestError({
        message: 'Action not allowed',
        statusCode: 403,
      });
    }

    throw new RequestError({
      message: 'Error happened when making a request',
      statusCode: 500,
    });
  }
};

export const customerNumberToCustomerId = (customerNumber: string) => {
  // The format was already customer ID
  if (customerNumber.includes('_')) {
    return customerNumber;
  }

  return `${config.product}_${customerNumber}`;
};

export const customerIdToCustomerNumber = (customerId: string) => {
  const [, customerNumber] = customerId.split('_');

  return !customerNumber ? customerId : customerNumber;
};

/* General admin group checking, the user can belong to any of the admin groups */
export const isAdmin = (user: any) => {
  return (
    !!user.signInUserSession.accessToken.payload['cognito:groups'] &&
    !!user.signInUserSession.accessToken.payload[
      'cognito:groups'
    ].some((group: string) => ADMIN_GROUPS.includes(group))
  );
};

export const isAllowedToSeeStatistics = (user: any) => {
  return (
    !!user.signInUserSession.accessToken.payload['cognito:groups'] &&
    !!user.signInUserSession.accessToken.payload[
      'cognito:groups'
    ].some((group: string) => STATISTICS_GROUPS.includes(group))
  );
};

export const isContentManager = (user: any) => {
  return (
    !!user.signInUserSession.accessToken.payload['cognito:groups'] &&
    !!user.signInUserSession.accessToken.payload[
      'cognito:groups'
    ].some((group: string) => CONTENT_MANAGER_GROUPS.includes(group))
  );
};