export function isCurrentTimeInRange(start: string, end: string): boolean {
  const currentDate = new Date();
  const startTime = new Date(start);
  const endTime = new Date(end);

  return currentDate >= startTime && currentDate <= endTime;
}

// Predicate to filter only items for current day
export function isToday(item: any) {
  const currentDate = new Date();
  const startTime = new Date(item.startTime);

  return (
    currentDate.getFullYear() === startTime.getFullYear() &&
    currentDate.getMonth() === startTime.getMonth() &&
    currentDate.getDate() === startTime.getDate()
  );
}

// Predicate to filter only items for tomorrow
export function isTomorrow(item: any) {
  const currentDate = new Date();
  const startTime = new Date(item.startTime);

  return (
    currentDate.getFullYear() === startTime.getFullYear() &&
    currentDate.getMonth() === startTime.getMonth() &&
    currentDate.getDate() + 1 === startTime.getDate()
  );
}
