import { AuthConfig, IAuthConfig } from './auth/AuthConfig';
import { IThemeConfig, ThemeConfig } from './theme/ThemeConfig';
import { ITextData, TextData } from './content/TextData';
import { IAnalyticsConfig, AnalyticsConfig } from './analytics/AnalyticsConfig';
import { ILaunchpadConfig, LaunchpadConfig } from './launchpad/Launchpad';

const product = process.env.REACT_APP_PRODUCT as EProduct;
const environmentType = process.env.REACT_APP_ENVIRONMENT_TYPE as EEnvironment;

/**
 * General application configs
 */
export enum EProduct {
  ELENIA = 'elenia',
  JES = 'jes',
  NICE = 'nice',
  AURIS = 'auris',
  LOIMUA = 'loimua'
}

export enum EEnvironment {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}

export interface IConfig {
  product: EProduct;
  environmentType: EEnvironment;
  authConfig: IAuthConfig;
  themeConfig: IThemeConfig;
  textData: ITextData;
  analyticsConfig: IAnalyticsConfig | null;
  launchpadConfig: ILaunchpadConfig;
}

export const config: IConfig = {
  product,
  environmentType,
  authConfig: AuthConfig(product, environmentType),
  themeConfig: ThemeConfig(product),
  textData: TextData(product),
  analyticsConfig: AnalyticsConfig(product, environmentType),
  launchpadConfig: LaunchpadConfig(product, environmentType),
};
