import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import {
  CommonButtonDiv,
  CommonFormItem,
  CommonInput,
  CommonLabel,
  CommonMessage,
} from '../components/CommonStyles';
import { Button, SubmitButton } from '../components/Button';
import { Spinner } from '../components/Spinner';
import { config } from '../config/config';
import { customerNumberToCustomerId, request } from '../helpers';
import { Customer, CustomerType } from '../types';

type Props = {
  linkedCustomerIds: string[];
  sub: string;
  updateCustomershipList: (customerId: string) => void;
};

type State = {
  customerNumberToAdd: string;
  enableSaving: boolean;
  error: boolean;
  loading: boolean;
  msg: string;
  saveLoading: boolean;
  searchResult: Customer | null;
};

const StyledForm = styled.form`
  height: 100%;
  flex: 1;
  padding: 1.5em;
  margin-bottom: 1em;
  background-color: ${config.themeConfig.colors.grayLight};

  @media screen and (min-width: 900px) {
    max-width: 400px;

    margin-right: 2em;
    margin-bottom: 0;
  }
`;

const ResultDiv = styled.div`
  padding: 1em;
  width: 100%;

  span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FormHeader = styled.h3`
  text-align: left;
  padding: 0 0 1em 0;
  text-transform: uppercase;
`;

const StyledFormItem = styled(CommonFormItem)`
  padding: 0;
`;

const StyledButtons = styled(CommonButtonDiv)`
  align-items: center;
  flex-direction: column;

  button {
    width: 100%;
    margin: 0 0 0.5em 0;
  }
`;

export class AdminAddCustomerShipForm extends React.Component<Props, State> {
  state: State = {
    customerNumberToAdd: '',
    enableSaving: false,
    error: false,
    loading: false,
    msg: '',
    saveLoading: false,
    searchResult: null,
  };

  handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      error: value.length === 0,
      msg: '',
      enableSaving: false,
      searchResult: null,
    }));
  };

  saveCustomershipToAdminAccount = async (event: any) => {
    event.preventDefault();

    this.setState({
      saveLoading: true,
    });

    try {
      if (!this.state.searchResult) {
        throw new Error('Ei hakutuloksia');
      }

      const { id } = this.state.searchResult;

      await request({
        method: 'POST',
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/customer`,
        params: {
          sub: this.props.sub,
          customerIdToAdd: id,
          adminRequired: true,
        },
      });

      this.setState({
        error: false,
        msg: 'Lisäys onnistui.',
        saveLoading: false,
        searchResult: null,
        enableSaving: false,
        customerNumberToAdd: '',
      });

      await this.props.updateCustomershipList(id);
    } catch (err) {
      console.log('Error saving', err);

      this.setState({
        error: true,
        msg: 'Tallennus epäonnistui.',
        saveLoading: false,
      });
    }
  };

  fetchCustomership = async (event: any) => {
    event.preventDefault();

    this.setState({
      loading: true,
    });

    try {
      if (this.state.customerNumberToAdd.length === 0) {
        throw new Error('Asiakasnumeroa ei syötetty.');
      }

      const customerId = customerNumberToCustomerId(
        this.state.customerNumberToAdd,
      );

      if (this.props.linkedCustomerIds.includes(customerId)) {
        this.setState({
          error: true,
          loading: false,
          msg: 'Asiakasnumero on jo liitettynä tähän käyttäjätiliin.',
        });

        return;
      }

      const result = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/customer`,
        params: {
          sub: this.props.sub,
          customerIdToFetch: customerId,
          adminRequired: true,
        },
      });

      this.setState({
        customerNumberToAdd: '',
        enableSaving: true,
        error: false,
        loading: false,
        msg: 'Haku onnistui.',
        searchResult: result.data.enerim_data,
      });
    } catch (err) {
      if (err.statusCode === 404) {
        this.setState({
          error: true,
          loading: false,
          msg: 'Asiakasnumeroa ei löytynyt.',
        });
        return;
      }

      this.setState({
        error: true,
        loading: false,
        msg: 'Haussa tapahtui virhe.',
      });
    }
  };

  renderSearchResult() {
    const { searchResult } = this.state;

    if (!searchResult) {
      return null;
    }

    const name =
      searchResult.customerType === CustomerType.Organisation
        ? searchResult.companyName
        : `${searchResult.firstName} ${searchResult.lastName}`;

    return (
      <ResultDiv>
        <span>
          <b>Asiakasnumero: </b> {searchResult.customerId}
        </span>
        <span>
          <b>Nimi: </b> {name}
        </span>
        <span>
          <b>Sopimuksia: </b> {searchResult.contracts.length} kpl
        </span>
      </ResultDiv>
    );
  }

  render() {
    const { customerNumberToAdd, error, msg } = this.state;

    const searchResultDiv = this.renderSearchResult();

    return (
      <StyledForm onSubmit={this.fetchCustomership}>
        <FormHeader>Liitä asiakasnumero</FormHeader>
        <StyledFormItem>
          <CommonLabel htmlFor="customerNumberToAdd">Asiakasnumero</CommonLabel>
          <CommonInput
            type="text"
            name="customerNumberToAdd"
            id="customerNumberToAdd"
            onChange={this.handleInputChange}
            value={customerNumberToAdd}
          />
        </StyledFormItem>

        <StyledButtons>
          <SubmitButton disabled={customerNumberToAdd.length === 0}>
            {this.state.loading ? <Spinner /> : 'Hae'}
          </SubmitButton>

          {searchResultDiv}

          <Button
            disabled={!this.state.enableSaving}
            onClick={this.saveCustomershipToAdminAccount}
          >
            {this.state.saveLoading ? <Spinner /> : 'Tallenna'}
          </Button>

          <CommonMessage error={error}>{msg}</CommonMessage>
        </StyledButtons>
      </StyledForm>
    );
  }
}
