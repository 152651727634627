import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import InputLabel from './Label';
import PasswordInput from './PasswordInput_v2';

const Description = styled.span`
  margin-bottom: 1.5rem;
  width: 100%;

  b {
    font-weight: normal;
  }
`;

const PasswordRules = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding-top: 6px;
`;

const PasswordRule = styled(InputLabel)`
  display: flex;
`;

const ValidationIconSuccess = styled.div`
  &:after {
    content: '✔';
    color: green;
  }
`;

const ValidationIconFail = styled.div`
  &:after {
    content: '✘';
    color: var(--amplify-red);
  }
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;

const PasswordMatchValidation = styled.div`
  padding: 0px 10px 10px 0px;
  display: flex;
`;

// Special characters listed at
// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
const passwordSpecialCharacters = [
  '^',
  '$',
  '*',
  '.',
  '[ ',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  '>',
  '<',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
];

const checkValidations = (
  passwordValidationsToCheck: TPasswordValidations,
): boolean => {
  // Password is ok if all the password checks are ok, i.e. true
  const passwordOk = Object.values(passwordValidationsToCheck).every(
    value => value === true,
  );

  return passwordOk;
};

type TPasswordValidations = {
  lowerCaseLetterOk: boolean;
  upperCaseLetterOk: boolean;
  numberOk: boolean;
  specialCharacterOk: boolean;
  minimumLengthOk: boolean;
  repeatedPasswordMatches: boolean;
};

type Props = {
  onValidPassword: Function;
};

export function PasswordValidation(props: Props) {
  const { onValidPassword } = props;

  const [passWordValidations, setPasswordValidations] = useState<
    TPasswordValidations
  >({
    lowerCaseLetterOk: false,
    upperCaseLetterOk: false,
    numberOk: false,
    specialCharacterOk: false,
    minimumLengthOk: false,
    repeatedPasswordMatches: false,
  });

  const [fields, setValues] = useState({
    password: '',
    passwordRepeated: '',
  });

  const { current: id } = useRef(uuidv4());

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });

    if (key === 'password') {
      const validations = {
        lowerCaseLetterOk: /[a-zåäö]+/.test(value),
        upperCaseLetterOk: /[A-ZÅÄÖ]+/.test(value),
        numberOk: /\d+/.test(value),
        specialCharacterOk: passwordSpecialCharacters.some(char =>
          value.includes(char),
        ),
        minimumLengthOk: value.length >= 8,
        repeatedPasswordMatches: value === fields.passwordRepeated,
      };

      setPasswordValidations(prevState => ({
        ...prevState,
        ...validations,
      }));

      if (checkValidations(validations)) {
        onValidPassword(value);
      }
    }

    if (key === 'passwordRepeated') {
      setPasswordValidations(prevState => ({
        ...prevState,
        repeatedPasswordMatches: value === fields.password,
      }));

      const validations = {
        lowerCaseLetterOk: /[a-zåäö]+/.test(fields.password),
        upperCaseLetterOk: /[A-ZÅÄÖ]+/.test(fields.password),
        numberOk: /\d+/.test(fields.password),
        specialCharacterOk: passwordSpecialCharacters.some(char =>
          fields.password.includes(char),
        ),
        minimumLengthOk: fields.password.length >= 8,
        repeatedPasswordMatches: value === fields.password,
      };

      if (checkValidations(validations)) {
        onValidPassword(fields.password);
      }
    }
  };

  const renderPasswordRules = () => (
    <Description>
      <InputLabel className="description-label">
        Salasanan täytyy sisältää vähintään:
      </InputLabel>
      <PasswordRules>
        <PasswordRule className="password-validation-label">
          {passWordValidations.lowerCaseLetterOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}
          &nbsp;1 pieni kirjain
        </PasswordRule>
        <PasswordRule className="password-validation-label">
          {passWordValidations.upperCaseLetterOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}
          &nbsp;1 iso kirjain
        </PasswordRule>
        <PasswordRule className="password-validation-label">
          {passWordValidations.numberOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}
          &nbsp;1 numero
        </PasswordRule>
        <PasswordRule className="password-validation-label">
          {passWordValidations.specialCharacterOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}
          &nbsp;1 erikoismerkki
        </PasswordRule>
        <PasswordRule className="password-validation-label">
          {passWordValidations.minimumLengthOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}
          &nbsp;8 merkkiä
        </PasswordRule>
      </PasswordRules>
    </Description>
  );

  return (
    <React.Fragment>
      <Item>
        <InputLabel className="password-input-label" htmlFor={`${id}-password`}>
          Salasana *
        </InputLabel>
        <PasswordInput
          value={fields.password}
          fieldKey={'password'}
          setValue={setValueToState}
        />
      </Item>

      {renderPasswordRules()}

      <Item>
        <InputLabel
          className="password-input-label"
          htmlFor={`${id}-password-repeated`}
        >
          Salasana uudelleen *
        </InputLabel>
        <PasswordInput
          value={fields.passwordRepeated}
          fieldKey={'passwordRepeated'}
          setValue={setValueToState}
        />
      </Item>

      <PasswordMatchValidation>
        {passWordValidations.repeatedPasswordMatches ? (
          <React.Fragment>
            <PasswordRule className="password-validation-label">
              <ValidationIconSuccess /> &nbsp;Salasanat täsmäävät
            </PasswordRule>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PasswordRule className="password-validation-label">
              <ValidationIconFail />
              &nbsp; Salasanat eivät täsmää
            </PasswordRule>
          </React.Fragment>
        )}
      </PasswordMatchValidation>
    </React.Fragment>
  );
}
