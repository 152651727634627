import { IAuthConfig } from './AuthConfig';

/**
 * Auris dev authentication config
 */
// no protocol, the base URL
const extranetDomain = 'asiakasdev.aurisenergia.fi';

const aurisDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_6gQ2t3gxs',
  userPoolWebClientId: '2m46qhc3gnbheugs5suf6avq19',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.auris-extranet.com', // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://auris.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-spicy-fork-778',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasdev.auris-extranet.com/admin/maintenancenotice`,
};

export default aurisDevAuthConfig;
