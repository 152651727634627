import { Auth } from 'aws-amplify';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Link, SubmitButton } from '../components/Button';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { config } from '../config/config';
import { translations } from '../config/translations';
import {
  CommonButtonDiv, CommonDescription, CommonFormItem,
  CommonFormItemWrapper, CommonInput, CommonLabel, CommonLabelWithState, CommonMessage, CommonSection, CommonSubheader,
  CommonSubSection
} from '../components/CommonStyles';
import { refreshSession } from '../utils/session';
import { EUserType } from '../types';

const { profileSettings: texts } = config.textData;

type State = {
  email: string;
  originalEmail: string;
  familyName: string;
  givenName: string;
  phone: string;
  type: EUserType;
  error: boolean;
  msg: string;
  showConfirmationCodeQuery: boolean;
  confirmationCode: string;
  isWaitingForSignOut: boolean;
};

const MissingValue = styled.span`
  color: red;
  padding-left: 0.5em;
`;

class ProfileSettings extends React.Component<{}, State> {
  state: State = {
    email: '',
    originalEmail: '',
    familyName: '',
    givenName: '',
    phone: '',
    type: EUserType.Person,
    error: false,
    msg: '',
    showConfirmationCodeQuery: false,
    confirmationCode: '',
    isWaitingForSignOut: false,
  };

  componentDidMount = async () => {
    document.title = texts.pageTitle;

    const user: any = await Auth.currentAuthenticatedUser();

    this.setState({
      email: user.attributes.email,
      originalEmail: user.attributes.email,
      familyName: user.attributes.family_name,
      givenName: user.attributes.given_name,
      phone: user.attributes.phone_number || '',
      type: user.attributes['custom:user_type'],
    });
  };

  handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
      error: value.length === 0,
      msg: '', // TODO:
    });
  };

  triggerTokenRefresh = async () => {
    // Get the user again because it has been updated
    return refreshSession();
  };

  saveSettings = async (event: any) => {
    event.preventDefault();

    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.updateUserAttributes(user, {
        family_name: this.state.familyName,
        given_name: this.state.givenName,
        phone_number: this.state.phone,
        email: this.state.email,
      });

      // Force token refresh so that the updates
      this.triggerTokenRefresh();

      if (this.state.email !== this.state.originalEmail) {
        // Email was also updated, show form where to input confirmation code
        this.setState({
          showConfirmationCodeQuery: true,
        });
      } else {
        this.setState({
          error: false,
          msg: 'Tiedot tallennettu onnistuneesti!',
        });
      }
    } catch (err) {
      this.setState({
        error: true,
        msg: translations.fi[err.message] || 'Tapahtui virhe',
      });
    }
  };

  confirmEmail = async (event: any) => {
    event.preventDefault();

    const { confirmationCode } = this.state;

    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode);

      this.setState({
        error: false,
        msg:
          'Sähköpostiosoite vahvistettiin onnistuneesti. Sinut kirjataan automaattisesti ulos palvelusta, jonka jälkeen voi kirjautua takaisin sisään päivitetyllä sähköpostiosoitteella.',
        isWaitingForSignOut: true,
      });

      setTimeout(() => {
        window.location.assign(
          `${config.authConfig.extranetUrl}/kirjaudu-ulos`,
        );
      }, 3000);
    } catch (err) {
      this.setState({
        error: true,
        msg: translations.fi[err.message] || 'Tapahtui virhe',
      });
    }
  };

  render() {
    const {
      email,
      phone,
      givenName,
      familyName,
      type,
      error,
      msg,
      showConfirmationCodeQuery,
      confirmationCode,
      isWaitingForSignOut,
    } = this.state;

    const saveEnabled =
      email.length !== 0 && givenName.length !== 0 && familyName.length !== 0;

    const missingValue = <MissingValue>Puuttuu</MissingValue>;

    return (
      <Page>
        <PageHeader text={texts.headerTitle} />
        {!showConfirmationCodeQuery && (
          <CommonSection>
            <CommonSubSection>
              <CommonSubheader>Muuta käyttäjätietoja</CommonSubheader>
              <CommonDescription>{texts.instructions}</CommonDescription>
              <form onSubmit={this.saveSettings}>
                <CommonFormItemWrapper>
                  <CommonFormItem>
                    <CommonLabelWithState>
                      <CommonLabel>Käyttäjätunnus</CommonLabel>
                      {email.length === 0 && missingValue}
                    </CommonLabelWithState>
                    <CommonInput
                      name="email"
                      id="email"
                      onChange={this.handleInputChange}
                      value={email}
                    />
                  </CommonFormItem>

                  <CommonFormItem>
                    <CommonLabelWithState>
                      <CommonLabel htmlFor="phone">
                        Puhelinnumero (+358)
                      </CommonLabel>
                    </CommonLabelWithState>
                    <CommonInput
                      name="phone"
                      id="phone"
                      placeholder="esim. +3581234567"
                      onChange={this.handleInputChange}
                      value={phone}
                    />
                  </CommonFormItem>

                  <CommonFormItem>
                    <CommonLabelWithState>
                      <CommonLabel htmlFor="givenName">Etunimi *</CommonLabel>
                      {givenName.length === 0 && missingValue}
                    </CommonLabelWithState>
                    <CommonInput
                      name="givenName"
                      id="givenName"
                      onChange={this.handleInputChange}
                      value={givenName}
                    />
                  </CommonFormItem>

                  <CommonFormItem>
                    <CommonLabelWithState>
                      <CommonLabel htmlFor="familyName">Sukunimi *</CommonLabel>
                      {familyName.length === 0 && missingValue}
                    </CommonLabelWithState>
                    <CommonInput
                      name="familyName"
                      id="familyName"
                      onChange={this.handleInputChange}
                      value={familyName}
                    />
                  </CommonFormItem>

                  <CommonFormItem>
                    <CommonLabelWithState>
                      <CommonLabel htmlFor="type">Asiakastyyppi</CommonLabel>
                    </CommonLabelWithState>
                    <span>{type}</span>
                  </CommonFormItem>
                </CommonFormItemWrapper>

                <CommonButtonDiv>
                  <SubmitButton disabled={!saveEnabled}>
                    Päivitä käyttäjätiedot
                  </SubmitButton>

                  <Link pink to={'/'}>
                    Sulje
                  </Link>

                  <CommonMessage error={error}>{msg}</CommonMessage>
                </CommonButtonDiv>
              </form>
            </CommonSubSection>
          </CommonSection>
        )}
        {showConfirmationCodeQuery && (
          <CommonSection>
            <CommonSubSection>
              <CommonSubheader>
                Sähköpostiosoitteen vahvistaminen
              </CommonSubheader>
              <CommonDescription>
                Syöttämäsi tiedot tallennettiin onnistuneesti ja antamaasi
                sähköpostiosoitteeseen, <strong>{this.state.email}</strong>,
                lähetettiin vahvistuskoodi, jonka voit syöttää alla olevaan
                kenttään vahvistaaksesi sähköpostiosoitteen. Huomaathan, että
                vahvistuskoodi voi löytyä myös roskapostikansiosta.
              </CommonDescription>
              <form onSubmit={this.confirmEmail}>
                <CommonFormItemWrapper>
                  <CommonFormItem>
                    <CommonLabelWithState>
                      <CommonLabel>Vahvistuskoodi</CommonLabel>
                      {confirmationCode.length === 0 && missingValue}
                    </CommonLabelWithState>
                    <CommonInput
                      name="confirmationCode"
                      id="confirmationCode"
                      onChange={this.handleInputChange}
                      value={confirmationCode}
                    />
                  </CommonFormItem>
                </CommonFormItemWrapper>
                <CommonButtonDiv>
                  <SubmitButton
                    disabled={!confirmationCode.length || isWaitingForSignOut}
                  >
                    Vahvista sähköpostiosoite
                  </SubmitButton>
                  <CommonMessage error={error}>{msg}</CommonMessage>
                </CommonButtonDiv>
              </form>
            </CommonSubSection>
          </CommonSection>
        )}
      </Page>
    );
  }
}

export default ProfileSettings;
