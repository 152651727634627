import { css } from 'styled-components';
import { config } from './config/config';

const fonts = css`
  @font-face {
    font-family: 'Apex';
    src: url(${require('./fonts/elenia/ApexNewWeb-Book.eot')});
    src: url(${require(`./fonts/elenia/ApexNewWeb-Book.eot`)}?#iefix)
        format('embedded-opentype'),
      url(${require(`./fonts/elenia/ApexNewWeb-Book.woff`)}) format('woff'),
      url(${require(`./fonts/elenia/ApexNewWeb-Book.svg`)}#Apex) format('svg');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Apex-semi-bold';
    src: url(${require('./fonts/elenia/ApexNewWeb-Book-Regular.eot')});
    src: url(${require(`./fonts/elenia/ApexNewWeb-Book-Regular.eot`)}?#iefix)
        format('embedded-opentype'),
      url(${require(`./fonts/elenia/ApexNewWeb-Book-Regular.woff`)})
        format('woff'),
      url(${require(`./fonts/elenia/ApexNewWeb-Book-Regular.svg`)}#Apex)
        format('svg');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Apex';
    src: url(${require('./fonts/elenia/ApexNewWeb-Bold.eot')});
    src: url(${require(`./fonts/elenia/ApexNewWeb-Bold.eot`)}?#iefix)
        format('embedded-opentype'),
      url(${require(`./fonts/elenia/ApexNewWeb-Bold.woff`)}) format('woff'),
      url(${require(`./fonts/elenia/ApexNewWeb-Bold.svg`)}#Apex) format('svg');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${require('./fonts/elenia/Lato-Regular.eot')});
    src: url(${require(`./fonts/elenia/Lato-Regular.eot`)}?#iefix)
        format('embedded-opentype'),
      url(${require(`./fonts/elenia/Lato-Regular.woff`)}) format('woff'),
      url(${require(`./fonts/elenia/Lato-Regular.svg`)}#Apex) format('svg');
    font-style: normal;
    font-weight: normal;
  }

  * {
    color: ${config.themeConfig.colors.grayDark};
    font-family: 'Apex', arial, helvetica, verdana, sans-serif;
    font-size: ${config.themeConfig.fontSizes.small};
    letter-spacing: 0.01em;
    word-spacing: 0.2em;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  header,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    font-family: ${config.themeConfig.fontFamilies.headers};
  }

  h1 {
    font-size: ${config.themeConfig.fontSizes.large};
    text-transform: uppercase;
  }

  h2 {
    text-transform: uppercase;
  }

  h2,
  h3,
  h4 {
    font-size: ${config.themeConfig.fontSizes.medium};
  }
`;

export default fonts;
