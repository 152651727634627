import React from 'react';
import styled, { css } from 'styled-components';
import { Page } from '../components/Page';
import { config } from '../config/config';
import { Button } from '../components/Button';
import { CommonSection, CommonSubSection } from '../components/CommonStyles';
import { CustomershipList } from '../components/CustomershipList';
import { EUserType } from '../types';

const { verifyCustomerships: texts } = config.textData;

type Props = {
  customerShipsFound: boolean;
  enerimData: any;
  linkedCustomerIds: string[];
  newCustomerIds: string[];
  searchedCustomerIds: string[];
  isFetching: boolean;
  resetCustomerships: () => void;
  updateCustomershipActivity: (
    type: 'ignore' | 'reactivate',
    customerId: string,
  ) => void;
  verify: () => void;
};

const ButtonSection = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;

  button {
    height: auto;
    font-size: ${config.themeConfig.fontSizes.medium};
  }
`;

const StyledContentContainer = styled(CommonSection)`
  padding: 1em 1em 0.5em 1em;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  justify-content: space-between;

  a {
    margin-top: 0.5em;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;

    a {
      margin-top: 0;
    }
  }
`;

const SetupHeader = styled.h1`
  text-align: center;
  width: 100%;

  @media screen and (min-width: 600px) {
    margin: 1em;
  }
`;

const TextBlock = styled.p(
  (props: { bold: boolean; center: boolean }) => css`
    ${props.center ? `text-align: center` : `text-align: left`};

    ${props.bold && `font-weight: bold`};

    margin: 0 0 1em 0;
  `,
);

class VerifyCustomerships extends React.Component<Props> {
  generateInstructions = (instructionParts: string[], center: boolean) => {
    return instructionParts.map((part: string, i: number) => {
      return (
        <TextBlock center={center} bold={i === 0} key={part}>
          {part}
        </TextBlock>
      );
    });
  };

  getInstructions = () => {
    const { customerShipsFound } = this.props;

    if (!customerShipsFound) {
      return this.generateInstructions(texts.notFound.instructions, true);
    }

    return this.generateInstructions(texts.found.instructions, false);
  };

  render() {
    const {
      customerShipsFound,
      enerimData,
      linkedCustomerIds,
      isFetching,
      newCustomerIds,
      searchedCustomerIds,
      resetCustomerships,
      updateCustomershipActivity,
      verify,
    } = this.props;

    const header = this.props.customerShipsFound
      ? texts.found.headerTitle
      : texts.notFound.headerTitle;

    const instructions = this.getInstructions();

    return (
      <Page showExtranetButton={false}>
        <StyledContentContainer>
          <CommonSubSection>
            <HeaderDiv>
              <SetupHeader>{header}</SetupHeader>
            </HeaderDiv>

            {instructions}

            {customerShipsFound && (
              <CustomershipList
                enerimData={enerimData}
                linkedCustomerIds={linkedCustomerIds}
                isAdmin={false}
                isFetching={isFetching}
                ignoredCustomerIds={[]}
                newCustomerIds={newCustomerIds}
                searchedCustomerIds={searchedCustomerIds}
                type={EUserType.Person}
                resetCustomerships={resetCustomerships}
                updateCustomershipActivity={updateCustomershipActivity}
                verifyCustomerships={true}
              />
            )}

            {customerShipsFound && (
              <ButtonSection>
                <Button onClick={verify}>{texts.found.confirmButton}</Button>
              </ButtonSection>
            )}
          </CommonSubSection>
        </StyledContentContainer>
      </Page>
    );
  }
}

export default VerifyCustomerships;
