import { ILaunchpadConfig } from './Launchpad';
import jesAuthConfig from '../auth/AuthConfig.jes.qa';
import JesTexts from '../content/TextData.jes';
import energyConsumption from '../../images/jes/energy-consumption.svg';
import solarPanels from '../../images/jes/solar-panels.svg';
import electricCar from '../../images/jes/electric-car.svg';

const launchpadConfig: ILaunchpadConfig = {
  version: 1,
  services: [
    {
      id: 'extranet',
      title: JesTexts.commonTexts.productName,
      url: `${jesAuthConfig.extranetUrl}/kirjaudu`,
      image: energyConsumption,
    },
    {
      id: 'solar',
      title: 'Aurinkosähkö',
      url: 'https://sierra-jse.staging.akamon.cloud/aurinkosahko?redirect=aurinkosahko',
      image: solarPanels,
      magicLinkId: 'aurinkosahko',
    },
    {
      id: 'electric-car',
      title: 'Latauslaskuri',
      url: 'https://sierra-jse.staging.akamon.cloud/lataus?redirect=lataus',
      image: electricCar,
      magicLinkId: 'lataus',
    },
  ],
};

export default launchpadConfig;
