import { ITextData } from './TextData';
import { EEnvironment } from '../config';

const environmentType = process.env.REACT_APP_ENVIRONMENT_TYPE as EEnvironment;

const PRODUCT_NAME =
  !environmentType || environmentType === 'prod'
    ? 'Elenia Aina'
    : `(${environmentType}) Elenia Aina`;

const contact = `asiakaspalvelu@elenia.fi tai puh. 020 690 025 ma-pe 8-18`;

const eleniaTextData: ITextData = {
  commonTexts: {
    productName: `${PRODUCT_NAME}`,
    signIn: `Kirjaudu sisään ${PRODUCT_NAME}an`,
    signUp: `Rekisteröidy ${PRODUCT_NAME}an`,
    extranetButton: 'Elenia Ainaan',
    passwordRequirements: `Salasanan täytyy olla vähintään 8 merkkiä pitkä, ja sen täytyy
    sisältää sekä isoja että pieniä kirjaimia sekä vähintään yksi numero
    ja erikoismerkki.`,
  },
  authStateTitles: {
    signin: `${PRODUCT_NAME} - Kirjautuminen`,
    signup: `${PRODUCT_NAME} - Rekisteröityminen`,
    signout: `${PRODUCT_NAME} - Uloskirjautuminen`,
    loading: `${PRODUCT_NAME} - Ladataan`,
    forgotpassword: `${PRODUCT_NAME} - Palauta salasana`,
    resettingpassword: `${PRODUCT_NAME} - Vaihda salasana`,
    confirmSignUp: `${PRODUCT_NAME} - Vahvista käyttäjätunnus`,
  },
  signUpInfoBox: {
    text: `Uudistimme Elenia Aina -palvelun kirjautumisjärjestelmän entistä
    turvallisemmaksi. Aiempi käyttäjätunnuksesi ja salasanasi eivät ole
    enää voimassa. Kirjautuminen onnistuu suomalaisilla pankkitunnuksilla tai mobiilivarmenteella.`,
  },
  profileSettings: {
    pageTitle: `${PRODUCT_NAME} - Päivitä käyttäjätiedot`,
    headerTitle: 'Käyttäjätietojen muuttaminen',
    instructions: `Käyttäjätietojen muutos vaikuttaa vain palvelun käyttämiseen ja kirjautumiseen.
      Huom! Tietojen päivittyminen ${PRODUCT_NAME}an saattaa vaatia uuden kirjautumisen.`,
  },
  changePassword: {
    pageTitle: `${PRODUCT_NAME} - Vaihda salasana`,
    headerTitle: 'Vaihda salasana',
  },
  signicatUndone: {
    pageTitle: `${PRODUCT_NAME} - Tunnistaudu vahvasti`,
    headerTitle: `${PRODUCT_NAME}n käyttöönotto 1/3 - Tunnistaudu vahvasti`,
    instructions: [
      `Tervetuloa ${PRODUCT_NAME} -palveluun.`,
      `Tunnistaudu palveluun ensimmäisellä kerralla pankkitunnuksilla tai mobiilivarmenteella.`,
      `Jatkossa voit kirjautua sähköpostiosoitteella ja salasanalla.`,
    ],
    beginSignicat: 'Aloita tunnistautuminen',
  },
  deleteUser: {
    pageTitle: `${PRODUCT_NAME} - Poista käyttäjätunnus`,
    headerTitle: 'Poista käyttäjätunnus',
    confirmText: `Ymmärrän, että poistettuani käyttäjätunnukseni, en pääse kirjautumaan enää ${PRODUCT_NAME}an.`,
    instructions: [
      `Käyttäjätunnuksen poistamisen jälkeen et pääse kirjautumaan enää ${PRODUCT_NAME} -palveluun. Voit ottaa ${PRODUCT_NAME} -palvelun käyttöösi rekisteröitymällä palveluun uudelleen.`,
      `Huom. Käyttäjätunnuksen poistaminen ${PRODUCT_NAME} -palvelusta ei katkaise verkkopalvelusopimuksiasi
      tai poista asiakkuuksiasi Elenian asiakastietojärjestelmästä.
      Tehdäksesi muutoksia verkkopalvelusopimukseesi tai asiakastietoihisi Eleniassa,
      ole yhteydessä asiakaspalveluumme: ${contact}`,
    ],
  },
  addOrganization: {
    pageTitle: `${PRODUCT_NAME} - Yrityksen lisääminen`,
    headerTitle: 'Yrityksen lisääminen',
    generalInstructions: `Liittämällä yrityksesi y-tunnuksen käyttäjätiliisi, saat kaikki y-tunnukseesi liitetyt asiakkuudet ja käyttöpaikat kerralla näkyviin. Tämän
    jälkeen myös kaikki mahdolliset uudet asiakkuudet päivittyvät näkyviin automaattisesti eikä sinun tarvitse lisätä niitä erikseen. Mikäli sinulla on useampi y-tunnus,
    voit liittää jokaiseen y-tunnukseesi liittyvät asiakkuudet samalla tavalla. `,
    specificInstructions: [
      ` Y-tunnuksen lisäksi tarvitset yrityksesi verkkopalvelulaskulta
    löytyvän asiakasnumeron sekä sekä käyttöpaikan GSRN-tunnuksen.`,
      ` Mikäli olet uusi yritysasiakkaamme, etkä ole vielä saanut
    ensimmäistä verkkopalvelulaskua, käytä tarkisteena yrityksesi
    y-tunnuksen lisäksi liittymissopimuksen asiakastunnusta ja
    liittymätunnusta.`,
      ` Järjestelmä hakee automaattisesti kaikki kyseiseen y-tunnukseen
    liittyvät asiakkuudet sekä niihin liittyvät käyttöpaikat Elenia
    Aina -palveluun.`,
    ],
    customerNumberFormat:
      'Asiakasnumero on 7-numeroinen luku, joka alkaa joko numerolla 1 tai 7. Löydät asiakasnumerosi verkkopalvelulaskusi oikeasta yläkulmasta, kohdasta ”Asiakasnumero” tai uutena asiakkaana liittymissopimuksesi oikeasta yläkulmasta kohdasta ”Asiakastunnus”.',
    meteringPointOrConnectionNumberFormat: [
      'GSRN-tunnus on 18-numeroinen luku, jonka alkuosa on syötetty valmiiksi.',
      'Jos et vielä ole saanut ensimmäistä verkkopalvelulaskuasi, käytä tarkisteena liittymätunnusta. Liittymätunnus on 7-numeroinen luku, joka alkaa joko numerolla 4 tai 5. Liittymätunnuksen löydät liittymissopimuksesi oikeasta yläkulmasta kohdasta ”Liittymätunnus”.',
    ],
    checkField1Name: 'Asiakasnumero tai asiakastunnus',
    checkField2Name: 'Liittymätunnus tai käyttöpaikan GSRN-tunnus',
  },
  homePage: {
    pageTitle: `${PRODUCT_NAME} - Käyttäjätilin asetukset`,
    headerTitle: 'Käyttäjätilin asetukset',
    instructions: {
      personWithCustomerships: [
        `Käyttäjätiliisi on liitetty seuraavat asiakkuudet`,
        `Voit halutessasi muokata ${PRODUCT_NAME} -palvelussa näytettäviä tietoja piilottamalla asiakkuuden ”Piilota asiakkuus” -painikkeella.
        Piilotetut asiakkuudet voi liittää takaisin milloin tahansa.
        Huomaa, että piilottamisella ei ole vaikutusta verkkopalvelusopimuksesi voimassaoloon tai muihin sopimustietoihisi,
        vaan sen avulla voit ainoastaan muokata ${PRODUCT_NAME} -palvelussa näytettäviä tietoja.`,
        `Jos kaikkia sinulle kuuluvia asiakkuuksiasi ei näy,
        pyydämme ystävällisesti olemaan yhteydessä asiakaspalveluumme: ${contact}.`,
      ],
      personWithoutCustomerships: [
        `Käyttäjätiliisi ei ole tällä hetkellä liitetty yhtäkään asiakkuutta.`,
        `Jos tarvitset apua,
        pyydämme ystävällisesti olemaan yhteydessä asiakaspalveluumme: ${contact}.`,
      ],
      organizationWithCustomerships: [
        `Käyttäjätiliisi on liitetty seuraavat asiakkuudet.`,
        `Voit halutessasi muokata Elenia Aina-palvelussa näytettäviä tietoja piilottamalla asiakkuuden ”Piilota asiakkuus” -painikkeella.
        Piilotetut asiakkuudet voi liittää takaisin milloin tahansa.
        Huomaa, että piilottamisella ei ole vaikutusta verkkopalvelusopimuksesi voimassaoloon tai muihin sopimustietoihisi,
        vaan sen avulla voit ainoastaan muokata Aina-palvelussa näytettäviä tietoja.`,
        `Jos kaikkia y-tunnukseenne liittyviä asiakkuuksia ei löytynyt,
        pyydämme ystävällisesti ottamaan yhteyttä asiakaspalveluumme: ${contact}.`,
      ],
      organizationWithoutCustomerships: [
        `Käyttäjätiliisi ei ole liitetty yhtäkään asiakkuutta.`,
        `Voit käydä liittämässä käyttäjätiliisi yritysasiakkuuden syöttämällä tarvittavat tiedot 'Lisää yritys'-sivulla.`,
      ],
    },
  },
  verifyCustomerships: {
    found: {
      pageTitle: `${PRODUCT_NAME} - Löydetyt asiakkuudet`,
      headerTitle: `${PRODUCT_NAME}n käyttöönotto 2/3 - Löydetyt asiakkuudet`,
      confirmButton: `Vahvista ja siirry ${PRODUCT_NAME}an`,
      instructions: [
        `Tässä ovat asiakkuudet, jotka vahva tunnistautuminen yhdisti sinuun. Jos huomaat tiedoissa virheitä, pyydämme keskeyttämään käyttöönoton ja ottamaan yhteyttä asiakaspalveluun.`,
        `Jos asiakkuuksiasi ei löytynyt tai tiedot ovat puutteellisia, pyydämme ystävällisesti ottamaan yhteyttä asiakaspalveluumme: ${contact}.`,
        `Jos haluat tarkastella ${PRODUCT_NAME} -palvelussa jonkun toisen henkilön tietoja,
        pyydämme toimittamaan häneltä vapaamuotoisen valtuutuksen osoitteeseen asiakaspalvelu@elenia.fi`,
      ],
    },
    notFound: {
      pageTitle: `${PRODUCT_NAME} - Ei asiakkuuksia`,
      headerTitle: `${PRODUCT_NAME}n  käyttöönotto - Asiakkuuksia ei löytynyt`,
      confirmButton: '',
      instructions: [
        `Vahva tunnistautuminen ei yhdistänyt asiakkuuksia sinuun.`,
        `Tämä voi liittyä siihen, että asiakastietosi Elenian asiakastietojärjestelmässä ovat puutteelliset.`,
        `Pyydämme ystävällisesti ottamaan yhteyttä asiakaspalveluumme asian selvittämiseksi: ${contact}.`,
        `Jos haluat tarkastella Elenia Aina -palvelussa jonkun toisen henkilön tietoja,
        pyydämme toimittamaan häneltä vapaamuotoisen valtuutuksen osoitteeseen asiakaspalvelu@elenia.fi`,
      ],
    },
  },
  manageCustomerAccounts: {
    pageTitle: `${PRODUCT_NAME} - Asiakkaan käyttäjätilin hallinta`,
    headerTitle: 'Asiakkaan käyttäjätilin hallinta',
  },
  launchpad: {
    pageTitle: `${PRODUCT_NAME} - Launchpad`,
    headerTitle: 'Launchpad',
  },
};

export default eleniaTextData;
