import { EUserType } from '../../types';
import { Auth, Hub } from 'aws-amplify';
import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  TOAST_AUTH_ERROR_EVENT,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components';
import { translations } from '../../config/translations';

export type TFields = {
  email: string;
  password: string;
  givenName: string;
  familyName: string;
  type: EUserType;
  initialPassword?: string | undefined;
  passwordRepeated?: string | undefined;
};

export default async function signUp(fields: TFields) {
  if (
    !fields.email ||
    !fields.password ||
    !fields.givenName ||
    !fields.familyName ||
    !fields.givenName.trim().length ||
    !fields.familyName.trim().length
  ) {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: TOAST_AUTH_ERROR_EVENT,
      message: 'Tietoja puuttui. Täytäthän kaikki kentät.',
    });
  } else {
    const lowerCaseEmail = fields.email.toLowerCase();

    await Auth.signUp({
      username: lowerCaseEmail,
      password: fields.password,
      attributes: {
        email: lowerCaseEmail,
        given_name: fields.givenName,
        family_name: fields.familyName,
        phone_number: '',
        'custom:user_type': fields.type, // Defaults to 'person'
      },
    })
      .then(() => {
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: AUTH_STATE_CHANGE_EVENT,
          message: AuthState.ConfirmSignUp,
          data: { username: lowerCaseEmail },
        });
      })
      .catch(error => {
        const translatedErrorMessage = translations.fi[error.message];

        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: TOAST_AUTH_ERROR_EVENT,
          message:
            translatedErrorMessage ||
            'Tapahtui virhe. Tarkistathan syöttämäsi tiedot.',
        });
      });
  }
}
