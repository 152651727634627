import * as React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const CloseIcon = styled.i`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  color: #000;
  font-weight: 600;
  font-size: 1.25em;
`;

type Props = {
  cookieDeclarationOpen: boolean;
  setCookieDeclaration: React.Dispatch<React.SetStateAction<boolean>>;
};

Modal.setAppElement('#root');

const CookieDeclarationModal = (props: Props) => {
  const { cookieDeclarationOpen, setCookieDeclaration } = props;

  function onOpened() {
    const script = document.createElement('script');
    script.src =
      'https://consent.cookiebot.com/e9cd5493-8e06-4bd4-836a-23e9f4787227/cd.js';
    script.async = true;
    script.id = 'CookieDeclaration';
    script.type = 'text/javascript';
    const declarationElement = document.getElementById(
      'CookieDeclarationUserStatusPanel',
    );

    if (declarationElement) {
      declarationElement.appendChild(script);
    }
  }

  function closeModal() {
    setCookieDeclaration(false);
  }

  return (
    <Modal
      isOpen={cookieDeclarationOpen}
      onRequestClose={closeModal}
      onAfterOpen={onOpened}
      contentLabel="Cookie declaration"
      style={{ overlay: { zIndex: 3 } }}
    >
      <CloseIcon className="modal-close fa fa-times" onClick={closeModal}>
        <span>{'\u2715'}</span>
      </CloseIcon>
      <div id="CookieDeclarationUserStatusPanel" />
    </Modal>
  );
};

export default CookieDeclarationModal;
