import { IAnalyticsConfig } from './AnalyticsConfig';

const niceProdAnalyticsConfig: IAnalyticsConfig = {
  // Amazon Pinpoint App Client ID
  appId: '974c10570d72461b91a5e3ee5693b5a4',
  // Amazon service region
  region: 'eu-west-1',
  mandatorySignIn: false,
};

export default niceProdAnalyticsConfig;
