import { IAuthConfig } from './AuthConfig';

/**
 * Nice dev authentication config
 */


const niceDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_Bb5uxIU7J',
  userPoolWebClientId: '2dop5afq1tk2cphkc4efs1rnju',
  identityPoolId: 'eu-west-1:b3873f1a-c071-4be0-9beb-bf7d5bb6e697',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.nice-extranet.com',
  SignicatApiUrl: 'https://ese.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-fierce-flag-669',
  extranetUrl: 'http://localhost:8082',
  adminServiceFrontendUrl: `http://localhost:3002`,
  maintenanceNoticeServiceUrl:`http://localhost:9300/admin/maintenancenotice`,
};

export default niceDevAuthConfig;
