import React from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '../Spinner';
import { Auth } from 'aws-amplify';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { config } from '../../config/config';
import { LaunchpadItem } from '../../types';
import ServiceItem from './ServiceItem';
import { request } from '../../helpers';
import widgets from './widgets/widgets';
import CookieDeclarationModal from '../CookieDeclarationModal';

// Style configuration for React Grid Layout
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const { launchpad: texts } = config.textData;

const AMOUNT_OF_COLUMNS = config.launchpadConfig.amountOfColumns || 4;

// Responsive grid component
const ResponsiveGridLayout = WidthProvider(Responsive);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  margin: auto;
  font-family: Apex;

  @media screen and (max-width: 500px) {
    width: 100vw;
  }
  @media screen and (min-width: 501px) {
    max-width: 750px;
    width: 75vw;
  }

  border-radius: 3px;
  min-height: 50vh;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
`;

const commonButtonProps = css`
  flex: 1;
  min-width: 100px;
  height: 50px;

  overflow: hidden;
  text-overflow: ellipsis;

  border: 0;
  border-radius: 5px;

  padding: 0.5em 1em;
  display: inline-block;

  text-decoration: none;

  font: inherit;
  font-weight: 600;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }

  @media screen and (min-width: 501px) and (max-width: 700px) {
    font-size: 13px;
  }

  @media screen and (min-width: 700px) and (max-width: 1000px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1001px) {
    font-size: 16px;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:focus,
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`;

const PrimaryButton = styled.button`
  ${commonButtonProps}

  filter: ${config.themeConfig.launchpad2?.primaryButtonFilter};
  background: ${config.themeConfig.launchpad2?.primaryButtonBackground};
  color: ${config.themeConfig.launchpad2?.primaryButtonColor};
`;

const SecondaryButton = styled.button`
  ${commonButtonProps}

  filter: ${config.themeConfig.launchpad2?.secondaryButtonFilter};
  background: ${config.themeConfig.launchpad2?.secondaryButtonBackground};
  color: ${config.themeConfig.launchpad2?.secondaryButtonTextColor};
`;

const ServiceItemGridButton = styled.button`
  background: transparent;
  flex: 0 0 auto;
  border: 0;
  height: 100%;
  width: 100%;
`;

const Buttons = styled.div`
  margin: 20px 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  filter: ${config.themeConfig.launchpad2?.titleContainerFilter};
  background: ${config.themeConfig.launchpad2?.titleContainerBackground};
  margin-bottom: 10px;
`;

const HiddenServicesContainer = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  text-align: center;
  text-transform: none;
  color: ${config.themeConfig.launchpad2?.titleColor};
  font-size: 40px;
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const HiddenServicesGrid = styled.div`
  padding: 10px;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(${AMOUNT_OF_COLUMNS}, 1fr);
  width: 100%;
`;

const ROW_HEIGHT = 160;
const COLUMNS_CONFIGURATION = { lg: AMOUNT_OF_COLUMNS };
const BREAKPOINTS_CONFIGURATION = { lg: 800 };

type ItemDefaultPosition = {
  id: string;
  width: number; // Width of 1 or 2 columns
  x: number;
  y: number;
};

// Calculate default positions for items on the grid
const calculateDefaultPositions = (
  services: LaunchpadItem[],
): ItemDefaultPosition[] => {
  // Build a list of items with their widths
  const items: ItemDefaultPosition[] = services.map((c, i) => {
    const widget = widgets.find(item => item.id === c.id);

    return {
      id: c.id,
      width: widget?.minWidth || 1,
      x: 0,
      y: 0,
    };
  });

  const packedItems: ItemDefaultPosition[] = [];
  let spaceInCurrentRow = AMOUNT_OF_COLUMNS; // Space left in the current row
  let currentRowNumber = 0; // Current row number i.e. Y position
  let currentX = 0; // X position within the current row

  items.forEach(item => {
    if (item.width <= spaceInCurrentRow) {
      // If the item fits in the current row, assign position and add it
      item.x = currentX;
      item.y = currentRowNumber;
      packedItems.push(item);

      // Update the space and X position for the next item
      spaceInCurrentRow -= item.width;
      currentX += item.width;
    } else {
      // If the item does not fit, move to a new row
      currentRowNumber++;
      spaceInCurrentRow = 4 - item.width; // Reset space for the new row
      currentX = 0; // Reset X position for the new row

      // Assign position for the new row and add the item
      item.x = currentX;
      item.y = currentRowNumber;
      packedItems.push(item);

      // Update the X position for the next item
      currentX += item.width;
    }

    // If there's no space left in the row or the item exactly fits, prepare for a new row
    if (spaceInCurrentRow === 0) {
      currentRowNumber++;
      spaceInCurrentRow = 4; // Reset space for the new row
      currentX = 0; // Reset X position for the new row
    }
  });

  return packedItems;
};

type Props = {
  services: LaunchpadItem[];
  onSelectApplication: Function;
  fetchingMagicLink: boolean;
};

const Launchpad = (props: Props) => {
  const [isEditMode, setEditMode] = React.useState(false);
  const [layouts, setLayouts] = React.useState({});
  const [hiddenServices, setHiddenServices] = React.useState([] as string[]);
  const [draggingItem, setDraggingItem] = React.useState(null as string | null);
  const [isLoadingConfiguration, setLoadingConfiguration] = React.useState(
    true,
  );
  const [cookieDeclarationOpen, setCookieDeclaration] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    document.title = texts.pageTitle;
  }, []);

  // Hook for fetching user data
  React.useEffect(() => {
    if (isLoadingConfiguration) {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();

        (async () => {
          const userMetadata = await request({
            url: `${config.authConfig.lambdaApiUrl}/idm/customerMetadata`,
            params: {
              sub: user.attributes.sub,
            },
          });

          // Validate launchpad configuration
          const isValidLaunchpadConfig =
            userMetadata.data.launchpad_config &&
            typeof userMetadata.data.launchpad_config === 'object' &&
            userMetadata.data.launchpad_config.layouts &&
            userMetadata.data.launchpad_config.hiddenServices;

          if (isValidLaunchpadConfig) {
            setLoadingConfiguration(false);
            setLayouts(userMetadata.data.launchpad_config.layouts);
            setHiddenServices(
              userMetadata.data.launchpad_config.hiddenServices,
            );
          } else {
            setLoadingConfiguration(false);
          }
        })();
      })();
    }
  }, [isLoadingConfiguration]);

  const removeApp = (id: string) => {
    setHiddenServices([...hiddenServices, id]);
  };

  // Save launchpad configuration into DynamoDB
  const saveLaunchpadConfiguration = async () => {
    const user: any = await Auth.currentAuthenticatedUser();

    await request({
      url: `${config.authConfig.lambdaApiUrl}/idm/customerLaunchpadConfig`,
      method: 'POST',
      params: {
        sub: user.attributes.sub,
      },
      data: {
        config: {
          layouts,
          hiddenServices,
        },
      },
    }).catch(error => console.error(error));

    setIsSaving(false);
  };

  if (isLoadingConfiguration || props.fetchingMagicLink) {
    return (
      <Container>
        <ContentContainer>
          <TitleContainer>
            <Title>
              <Spinner />
            </Title>
          </TitleContainer>
        </ContentContainer>
      </Container>
    );
  }

  function onDrop(layout: any, layoutItem: any, _event: any) {
    const layoutItems = layout.map((item: any) => {
      if (item.i === '__dropping-elem__') {
        const widget = widgets.find(item => item.id === draggingItem);

        const updatedItem = !!widget
          ? // Apply widget configuration to the item
            {
              ...item,
              w: widget.minWidth,
              h: widget.minHeight,
              minW: widget.minWidth,
              minH: widget.minHeight,
              i: draggingItem,
            }
          : {
              ...item,
              i: draggingItem,
            };

        // Eliminate isDraggable from item configuration because it
        // overrides the global grid configuration and we don't want that.
        delete updatedItem.isDraggable;

        return updatedItem;
      }

      return item;
    });

    setLayouts({
      lg: layoutItems,
    });
    setHiddenServices(hiddenServices.filter(name => name !== draggingItem));
    setDraggingItem(null);
    setIsDirty(true);
  }

  const editButtonText = isEditMode ? 'Lopeta muokkaus' : 'Muokkaa näkymää';

  const calculatedDefaultPositions = calculateDefaultPositions(props.services);

  return (
    <Container>
      <ContentContainer>
        {!isEditMode && (
          <TitleContainer>
            <Title>Siirry haluamaasi palveluun</Title>
          </TitleContainer>
        )}
        {isEditMode && (
          <TitleContainer>
            <Title>
              <div
                style={{
                  color: config.themeConfig.launchpad2?.editTitleColor,
                  fontSize: 25,
                }}
              >
                Valitsemasi palvelut:
              </div>
              <div
                style={{
                  color: config.themeConfig.launchpad2?.editSubtitleColor,
                  fontSize: 18,
                }}
              >
                Voit muokata kuvakkeiden kokoa ja paikkaa. Halutessasi voit myös
                piilottaa itsellesi tarpeettomia palveluita.
              </div>
            </Title>
          </TitleContainer>
        )}
        <ResponsiveGridLayout
          className="layout"
          isDraggable={isEditMode}
          isResizable={isEditMode}
          isDroppable={isEditMode}
          onDrop={onDrop}
          isBounded={true}
          layouts={layouts}
          margin={[10, 10]}
          breakpoints={BREAKPOINTS_CONFIGURATION}
          preventCollision={false}
          rowHeight={ROW_HEIGHT}
          cols={COLUMNS_CONFIGURATION}
          resizeHandles={['se']}
          onLayoutChange={(_layout: any, layouts: any) => {
            if (!draggingItem && isEditMode) {
              setLayouts(layouts);
              setIsDirty(true);
            }
          }}
        >
          {props.services
            .filter(c => !hiddenServices.includes(c.id))
            .map((c, i) => {
              const pos = calculatedDefaultPositions.find(
                item => item.id === c.id,
              );
              if (!pos) {
                return null;
              }

              if (c.type === 'link') {
                // Default position and size of an item is supplied
                // as data attribute.
                const gridProperties = { x: pos.x, y: pos.y, w: 1, h: 1 };

                return (
                  <ServiceItemGridButton
                    key={c.id}
                    data-grid={gridProperties}
                    onClick={() =>
                      !isEditMode && props.onSelectApplication(c.id)
                    }
                    aria-label={c.title}
                  >
                    <ServiceItem
                      onRemove={isEditMode ? removeApp : undefined}
                      id={c.id}
                      title={c.title}
                      image={c.image}
                    />
                  </ServiceItemGridButton>
                );
              } else if (c.type === 'widget') {
                // Default position and size of an item is supplied
                // as data attribute.
                const widget = widgets.find(item => item.id === c.id);

                const gridProperties = {
                  x: pos.x,
                  y: pos.y,
                  w: widget?.minWidth || 1,
                  h: widget?.minHeight || 1,
                  minW: widget?.minWidth || 1,
                  minH: widget?.minHeight || 1,
                };

                if (!widget) {
                  return null;
                }

                const Comp = widget.component;

                return (
                  <ServiceItemGridButton key={c.id} data-grid={gridProperties}>
                    <Comp
                      id={c.id}
                      onRemove={isEditMode ? removeApp : undefined}
                    />
                  </ServiceItemGridButton>
                );
              } else {
                return null;
              }
            })}
        </ResponsiveGridLayout>

        {isEditMode && hiddenServices.length > 0 && (
          <HiddenServicesContainer>
            <TitleContainer>
              <Title>
                <div
                  style={{
                    color: config.themeConfig.launchpad2?.editTitleColor,
                    fontSize: 25,
                  }}
                >
                  Piilottamasi palvelut:
                </div>
                <div
                  style={{
                    color: config.themeConfig.launchpad2?.editSubtitleColor,
                    fontSize: 18,
                  }}
                >
                  Voit siirtää kuvakkeen takaisin palvelunäkymään painamalla
                  plus-painiketta tai raahamalla kuvakkeen haluamallesi
                  paikalle.{' '}
                </div>
              </Title>
            </TitleContainer>
            <HiddenServicesGrid>
              {hiddenServices.map((serviceName, i) => {
                const c = props.services.find(
                  service => service.id === serviceName,
                );

                if (!c) {
                  return null;
                }

                if (c.type === 'link') {
                  return (
                    <div
                      key={c.id}
                      className="droppable-element"
                      draggable={true}
                      unselectable="on"
                      style={{ height: ROW_HEIGHT, width: '100%', minWidth: 0 }}
                      onDragStart={(e: any) => {
                        e.dataTransfer.setData('text/plain', '');
                        setDraggingItem(c.id);
                      }}
                    >
                      <ServiceItemGridButton
                        key={c.id}
                        tabIndex={0}
                        aria-label={c.title}
                      >
                        <ServiceItem
                          id={c.id}
                          title={c.title}
                          image={c.image}
                          onAdd={(id: string) => {
                            setHiddenServices(
                              hiddenServices.filter(name => name !== id),
                            );
                          }}
                        />
                      </ServiceItemGridButton>
                    </div>
                  );
                } else if (c.type === 'widget') {
                  const widget = widgets.find(item => item.id === c.id);

                  if (!widget) {
                    // This should never ever happen
                    throw new Error('Invalid widget configuration');
                  }

                  const Comp = widget.component;

                  return (
                    <div
                      key={c.id}
                      className="droppable-element"
                      draggable={true}
                      unselectable="on"
                      style={{ height: ROW_HEIGHT, minWidth: 0 }}
                      onDragStart={(e: any) => {
                        e.dataTransfer.setData('text/plain', '');
                        setDraggingItem(c.id);
                      }}
                    >
                      <ServiceItemGridButton key={c.id}>
                        <Comp
                          id={c.id}
                          onAdd={(id: string) => {
                            setHiddenServices(
                              hiddenServices.filter(name => name !== id),
                            );
                          }}
                          hidden
                        />
                      </ServiceItemGridButton>
                    </div>
                  );
                }

                return null;
              })}
            </HiddenServicesGrid>
          </HiddenServicesContainer>
        )}
        <Buttons>
          <PrimaryButton
            aria-label={isEditMode ? 'Lopeta muokkaus' : 'Muokkaa näkymää'}
            disabled={isSaving}
            onClick={() => {
              if (isEditMode) {
                if (isDirty) {
                  setIsSaving(true);
                  saveLaunchpadConfiguration();
                  setIsDirty(false);
                }

                setEditMode(false);
              } else {
                setEditMode(true);
              }
            }}
          >
            {isSaving ? <Spinner dark /> : editButtonText}
          </PrimaryButton>
          <PrimaryButton
            aria-label="Evästeseloste"
            onClick={() => setCookieDeclaration(true)}
          >
            Evästeseloste
          </PrimaryButton>
          {isEditMode && (
            <SecondaryButton
              aria-label="Palauta alkuperäiset asetukset"
              onClick={() => {
                setLayouts({});
                setHiddenServices([]);
              }}
            >
              Nollaa asetukset
            </SecondaryButton>
          )}
          <SecondaryButton
            aria-label="Kirjaudu ulos"
            onClick={() =>
              window.location.assign(
                `${config.authConfig.extranetUrl}/kirjaudu-ulos`,
              )
            }
          >
            Kirjaudu ulos
          </SecondaryButton>
        </Buttons>
      </ContentContainer>
      <CookieDeclarationModal
        cookieDeclarationOpen={cookieDeclarationOpen}
        setCookieDeclaration={() => setCookieDeclaration(false)}
      />
    </Container>
  );
};

export default Launchpad;
