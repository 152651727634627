import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Description = styled.span`
  color: var(--amplify-grey);
  font-size: var(--amplify-text-s);
  margin-bottom: 1.5rem;
  width: 100%;

  b {
    font-weight: normal;
  }
`;

const PasswordRules = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding-top: 6px;
`;

const PasswordRule = styled.div`
  display: flex;
`;

const ValidationIconSuccess = styled.div`
  &:after {
    content: '✔';
    color: green;
  }
`;

const ValidationIconFail = styled.div`
  &:after {
    content: '✘';
    color: var(--amplify-red);
  }
`;

const Item = styled.div`
  text-align: left;
  width: 100%;
  padding: 0em 0 0.5em 0;
  display: flex;
  flex-direction: column;

  ${(props: { half?: boolean }) =>
    props.half &&
    css`
      @media screen and (min-width: 500px) {
        width 48%;
      }
    `}
`;
const Label = styled.label`
  margin-bottom: 0.75em;
  font-size: var(--amplify-text-sm);
  color: #152939;
`;
const Input = styled.input`
  display: block;
  width: 100%;
  padding: var(--amplify-text-md);
  font-size: var(--amplify-text-sm);
  color: #152939;
  background-color: var(--amplify-white);
  background-image: none;
  border: 1px solid var(--amplify-light-grey);
  border-radius: 3px;
  box-sizing: border-box;
  margin: 0 0 0.425rem 0;
  height: 3.125rem;
  line-height: 1.1;
`;

const PasswordMatchValidation = styled.div`
  padding: 0px 10px 10px 0px;
  display: flex;
`;

const ShowHidePassword = styled.div`
  text-align: right;
  cursor: pointer;
`;

// Special characters listed at
// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
const passwordSpecialCharacters = [
  '^',
  '$',
  '*',
  '.',
  '[ ',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  '>',
  '<',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
];

const checkValidations = (
  passwordValidationsToCheck: TPasswordValidations,
): boolean => {
  // Password is ok if all the password checks are ok, i.e. true
  const passwordOk = Object.values(passwordValidationsToCheck).every(
    value => value === true,
  );
  
  return passwordOk;
};

type TPasswordValidations = {
  lowerCaseLetterOk: boolean;
  upperCaseLetterOk: boolean;
  numberOk: boolean;
  specialCharacterOk: boolean;
  minimumLengthOk: boolean;
  repeatedPasswordMatches: boolean;
};

type Props = {
  onValidPassword: Function;
};

export function PasswordInput(props: Props) {
  const { onValidPassword } = props;

  const [passWordValidations, setPasswordValidations] = useState<
    TPasswordValidations
  >({
    lowerCaseLetterOk: false,
    upperCaseLetterOk: false,
    numberOk: false,
    specialCharacterOk: false,
    minimumLengthOk: false,
    repeatedPasswordMatches: false,
  });

  const [fields, setValues] = useState({
    password: '',
    passwordRepeated: '',
  });

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdRepeat, setIsRevealPwdRepeat] = useState(false);

  const { current: id } = useRef(uuidv4());

  const setValueToState = (key: string, value: string) => {
    setValues(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });

    if (key === 'password') {
      const validations = {
        lowerCaseLetterOk: /[a-zåäö]+/.test(value),
        upperCaseLetterOk: /[A-ZÅÄÖ]+/.test(value),
        numberOk: /\d+/.test(value),
        specialCharacterOk: passwordSpecialCharacters.some(char =>
          value.includes(char),
        ),
        minimumLengthOk: value.length >= 8,
        repeatedPasswordMatches: value === fields.passwordRepeated,
      };

      setPasswordValidations(prevState => ({
        ...prevState,
        ...validations,
      }));

      if (checkValidations(validations)) {
        onValidPassword(value);
      }
    }

    if (key === 'passwordRepeated') {
      setPasswordValidations(prevState => ({
        ...prevState,
        repeatedPasswordMatches: value === fields.password,
      }));

      const validations = {
        lowerCaseLetterOk: /[a-zåäö]+/.test(fields.password),
        upperCaseLetterOk: /[A-ZÅÄÖ]+/.test(fields.password),
        numberOk: /\d+/.test(fields.password),
        specialCharacterOk: passwordSpecialCharacters.some(char =>
          fields.password.includes(char),
        ),
        minimumLengthOk: fields.password.length >= 8,
        repeatedPasswordMatches: value === fields.password,
      };

      if (checkValidations(validations)) {
        onValidPassword(fields.password);
      }
    }
  };

  const renderPasswordRules = () => (
    <Description>
      Salasanan täytyy sisältää vähintään:
      <PasswordRules>
        <PasswordRule>
          {passWordValidations.lowerCaseLetterOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}{' '}
          1 pieni kirjain
        </PasswordRule>
        <PasswordRule>
          {passWordValidations.upperCaseLetterOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}
          1 iso kirjain
        </PasswordRule>
        <PasswordRule>
          {passWordValidations.numberOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}
          1 numero
        </PasswordRule>
        <PasswordRule>
          {passWordValidations.specialCharacterOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}{' '}
          1 erikoismerkki
        </PasswordRule>
        <PasswordRule>
          {passWordValidations.minimumLengthOk ? (
            <ValidationIconSuccess />
          ) : (
            <ValidationIconFail />
          )}{' '}
          8 merkkiä
        </PasswordRule>
      </PasswordRules>
    </Description>
  );

  return (
    <React.Fragment>
      <Item>
        <Label htmlFor={`${id}-password`}>Salasana *</Label>
        <ShowHidePassword
          onClick={() => setIsRevealPwd(prevState => !prevState)}
        >
          {isRevealPwd ? 'Piilota salasana ' : 'Näytä salasana'}
        </ShowHidePassword>
        <Input
          name={`${id}-password`}
          id={`${id}-password`}
          type={isRevealPwd ? 'text' : 'password'}
          placeholder="Syötä salasana"
          onChange={event => setValueToState('password', event.target.value)}
          value={fields.password}
        />
      </Item>

      {renderPasswordRules()}

      <Item>
        <Label htmlFor={`${id}-password-repeated`}>Salasana uudelleen *</Label>
        <ShowHidePassword
          onClick={() => setIsRevealPwdRepeat(prevState => !prevState)}
        >
          {isRevealPwdRepeat ? 'Piilota salasana ' : 'Näytä salasana'}
        </ShowHidePassword>
        <Input
          name={`${id}-password-repeated`}
          id={`${id}-password-repeated`}
          type={isRevealPwdRepeat ? 'text' : 'password'}
          placeholder="Syötä salasana uudelleen"
          onChange={event =>
            setValueToState('passwordRepeated', event.target.value)
          }
          value={fields.passwordRepeated}
        />
      </Item>

      <PasswordMatchValidation>
        {passWordValidations.repeatedPasswordMatches ? (
          <React.Fragment>
            <ValidationIconSuccess /> Salasanat täsmäävät
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ValidationIconFail /> Salasanat eivät täsmää
          </React.Fragment>
        )}
      </PasswordMatchValidation>
    </React.Fragment>
  );
}
