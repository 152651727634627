import { IThemeConfig } from './ThemeConfig';
import logoUrl from '../../images/elenia/logo.svg';
import logoLightUrl from '../../images/elenia/logo_light.svg';
import trademarkLogo from '../../images/elenia/elenia_trademark.svg';
import smallLogo from '../../images/elenia/elenia_logo_small.svg';
import hidePasswordIcon from '../../images/elenia/hide_password_icon.svg';
import showPasswordIcon from '../../images/elenia/show_password_icon.svg';
import closeButtonIcon from '../../images/elenia/close-button.svg';

const eleniaThemeConfig: IThemeConfig = {
  colors: {
    mainThemeColor1: 'rgb(0, 48, 87)', // dark-blue
    mainThemeColor2: 'rgb(0, 169, 206)', // turqoise
    secondaryColor1: 'rgb(227, 28, 121)', // pink
    secondaryColor2: 'rgb(206, 220, 0)', // green
    secondaryColor3: 'rgb(27, 170, 205)', // blue
    backgroundColor1: 'rgba(0, 48, 87, 0.7)',
    backgroundColor2: 'rgba(0, 42, 76, 1)',
    formBackground:
      'linear-gradient(0deg, rgba(0, 48, 87, 0.8), rgba(0, 48, 87, 0.8)),linear-gradient(0deg, rgba(146, 161, 180, 0.1), rgba(146, 161, 180, 0.1))',
    backgroundGradient:
      'linear-gradient(114.54deg,rgba(0, 63, 115, 0.8) -0.16%,rgba(0, 48, 87, 0.388) 49.92%,rgba(3, 41, 73, 0.8) 100%),linear-gradient(114.71deg,rgba(0, 169, 206, 0.08) 0%,rgba(0, 169, 206, 0) 100%)',

    mainThemeColor2WithOpacity: 'rgba(0, 169, 206, 0.75)', // turqoise

    // Gray colors
    grayDark: 'rgb(53, 53, 53)',
    grayMedium: 'rgb(200, 200, 200)',
    grayLight: 'rgb(238, 238, 238)',
  },
  logo: {
    width: 200,
    height: 50,
    margin: 10,
    url: logoUrl,
  },
  logoLight: {
    width: 200,
    height: 50,
    margin: 10,
    url: logoLightUrl,
  },
  trademarkLogo: {
    width: 89,
    height: 18,
    margin: 40,
    url: trademarkLogo,
  },
  smallLogo: {
    width: 100,
    height: 26,
    margin: 10,
    url: smallLogo,
  },
  fontSizes: {
    small: '14px',
    medium: '18px',
    large: '20px',
    extraLarge: '32px',
  },
  showPasswordLogo: { url: showPasswordIcon },
  hidePasswordLogo: { url: hidePasswordIcon },
  closeButtonIcon: { url: closeButtonIcon },
  fontFamilies: {
    headers: 'Apex, arial, helvetica, verdana, sans-serif',
    regular: 'Apex, arial, helvetica, verdana, sans-serif',
    buttonFont: 'Lato, arial, helvetica, verdana, sans-serif',
  },
  launchpad: {
    signOutButtonBackground: '#95c23d',
    cancelButtonBackground: 'rgb(227, 28, 121)',
    signOutButtonText: '#000',
    noticesBackgroundColor: 'rgb(5, 40, 79)',
    noticesTextColor: '#fff',
  },
  launchpad2: {
    primaryButtonBackground: '#95c23d',
    primaryButtonFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    primaryButtonColor: '#000',
    secondaryButtonBackground: '#00a9ce',
    secondaryButtonFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    secondaryButtonTextColor: '#fff',
    noticesBackgroundColor: '#95c23d',
    noticesTextColor: '#000',
    titleContainerBackground:
      'linear-gradient(-90deg, rgba(0, 48, 87, 0) 0%, #003057 50%, rgba(0, 48, 87, 0) 100%)',
    titleContainerFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    titleColor: '#fff',
    editTitleColor: '#00A9CE',
    editSubtitleColor: '#fff',
    appLinkBackground:
      'linear-gradient(180deg, rgba(149, 194, 61, 0.75) 0%, #95c23d 100%)',
    appLinkFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))',
    appLinkTitleColor: '#003057',
    removeButtonBackground: '#e31c79',
    removeButtonColor: '#fff',
    widgetBackground:
      'linear-gradient(180deg, rgba(0, 169, 206, 0.75) 0%, #003057 100%)',
    widgetFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    widgetTitleColor: '#fff',
    widgetTextColor: '#fff',
    plusSignBackground:
      'linear-gradient(180deg, rgba(0, 169, 206, 0.75) 0%, rgb(27, 170, 205) 100%)',
    plusSignColor: '#fff',
  },
  launchpad3: {
    primaryButtonBackground: 'rgba(0, 48, 87, 0.7)',
    primaryButtonFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    primaryButtonColor: '#000',
    secondaryButtonBackground: '#00a9ce',
    secondaryButtonFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    secondaryButtonTextColor: '#fff',
    noticesBackgroundColor: 'rgb(5, 40, 79)',
    noticesTextColor: '#fff',
    titleContainerBackground:
      'linear-gradient(-90deg, rgba(0, 48, 87, 0) 0%, #003057 50%, rgba(0, 48, 87, 0) 100%)',
    titleContainerFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    titleColor: '#fff',
    editTitleColor: '#fff',
    editSubtitleColor: '#fff',
    appLinkBackground:
      'linear-gradient(0deg, rgba(0, 48, 87, 0.8), rgba(0, 48, 87, 0.8)),linear-gradient(0deg, rgba(146, 161, 180, 0.1), rgba(146, 161, 180, 0.1))',
    appLinkFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5))',
    appLinkTitleColor: '#003057',
    removeButtonBackground: '#e31c79',
    removeButtonColor: '#fff',
    widgetBackground:
      'linear-gradient(0deg,rgba(0, 169, 206, 0.2),rgba(0, 169, 206, 0.2)),linear-gradient(0deg, rgba(146, 161, 180, 0.1), rgba(146, 161, 180, 0.1))',
    widgetFilter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16))',
    widgetTitleColor: '#fff',
    widgetTextColor: '#fff',
    plusSignBackground:
      'linear-gradient(180deg, rgba(0, 169, 206, 0.75) 0%, rgb(27, 170, 205) 100%)',
    plusSignColor: '#fff',
    currentHourSpotPriceColor: 'rgb(128, 212, 231)',
    topAppBarBackgroundColor:
      'linear-gradient(180deg,rgba(0, 63, 115, 1) 0%,rgba(0, 48, 87, 0.9) 34.5%,rgba(3, 41, 73, 1) 100%)',
    mobileDrawerBackgroundColor: 'rgba(0, 169, 206, 1)',
  },
  mobileBreakPoint: 900,
};

export default eleniaThemeConfig;
