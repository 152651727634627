import { IAuthConfig } from './AuthConfig';

/**
 * Jes qa authentication config
 */

// no protocol, the base URL
const extranetDomain = 'asiakasqa.sssoy.fi';

const jesQaAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_nopB2XjlA',
  userPoolWebClientId: '5afpffevsr8vk5pn33ge43bocs',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakasqa.jes-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://sssoy.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-skinny-pig-773',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasqa.jes-extranet.com/admin/maintenancenotice`,
  magicLinkAuthUrl:
    'https://wmypnopbrj.execute-api.eu-west-1.amazonaws.com/staging/jseoy/magic-link',
};

export default jesQaAuthConfig;
