import { IAuthConfig } from './AuthConfig';

/**
 * Nice prod authentication config
 */

// no protocol, the base domain
const extranetDomain = 'asiakas.ese.fi';

const niceProdAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_hIncvAUba',
  userPoolWebClientId: '3njm0opmm2eegnbbie8d0pjiar',
  identityPoolId: 'eu-west-1:f3b5ae16-a663-4d1f-8998-b2572ff8c3fb',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakas.nice-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://ese.app.signicat.com/auth/open/connect',
  SignicatApiClientId: 'prod-bulky-map-909',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakas.nice-extranet.com/admin/maintenancenotice`,
};

export default niceProdAuthConfig;
