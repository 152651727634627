import { IAnalyticsConfig } from './AnalyticsConfig';

const niceDevAnalyticsConfig: IAnalyticsConfig = {
  // Amazon Pinpoint App Client ID
  appId: '0a9ef0fd55d6476388a387828500d5f4',
  // Amazon service region
  region: 'eu-west-1',
  mandatorySignIn: false,
};

export default niceDevAnalyticsConfig;
