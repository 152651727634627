import styled, { css } from 'styled-components';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import colorByProduct from '../customThemes';
import { config } from '../config/config';

type CommonProps = {
  children: any;
  pink?: boolean;
  light?: boolean;
  disabled?: boolean;
};

type ButtonProps = CommonProps & {
  onClick?: (event: any) => void;
};

type SubmitButtonProps = CommonProps & {
  value?: string;
};

type LinkProps = CommonProps & {
  to?: any;
  className?: string;
};

type ExternalLinkProps = CommonProps & {
  href?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
};

/* To make the Links and Buttons look the same */
const commonStyles = css`
  background-color: ${colorByProduct('buttonBackground')};
  font-family: arial;
  margin: 0;
  padding: 0.5em 1em;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  border: 0;
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled,
  &[disabled] {
    color: white;
    background-color: ${config.themeConfig.colors.grayMedium};
    cursor: not-allowed;
    border: 0;

    &:hover,
    &:focus {
      background-color: ${config.themeConfig.colors.grayMedium};
    }
  }

  ${(props: { pink?: boolean; light?: boolean }) => {
    if (props.pink && !props.light) {
      return css`
        background-color: ${colorByProduct('deleteButtonBackground')};
      `;
    }

    if (props.pink && props.light) {
      return css`
        background-color: white;
        color: ${colorByProduct('deleteButtonBackground')};
        border: 2px solid ${colorByProduct('deleteButtonBackground')};

        &:hover,
        &:focus {
          background-color: lightgray;
        }
      `;
    }

    if (props.light)
      return css`
        background-color: white;
        color: ${colorByProduct('buttonBackground')};
        border: 2px solid ${colorByProduct('buttonBackground')};

        &:hover,
        &:focus {
          background-color: ${config.themeConfig.colors.grayLight};
        }
      `;
  }}
`;

const ButtonContainer = styled.button`
  ${commonStyles}
`;

const SubmitButtonContainer = styled.button`
  ${commonStyles}
`;

const LinkComponent = ({ children, to, className, ...rest }: LinkProps) => {
  return (
    <RouterLink to={to} className={className}>
      {children}
    </RouterLink>
  );
};

const LinkContainer = styled(LinkComponent)`
  ${commonStyles}
`;

const ExternalLinkContainer = styled.a`
  ${commonStyles}
`;

export const Button = (props: ButtonProps) => (
  <ButtonContainer
    disabled={props.disabled}
    onClick={props.onClick}
    pink={props.pink}
    light={props.light}
  >
    {props.children}
  </ButtonContainer>
);

export const SubmitButton = (props: SubmitButtonProps) => (
  <SubmitButtonContainer
    disabled={props.disabled}
    pink={props.pink}
    light={props.light}
    type="submit"
  >
    {props.children}
  </SubmitButtonContainer>
);

export const Link = (props: LinkProps) => (
  <LinkContainer
    className={props.className}
    to={props.to}
    pink={props.pink}
    light={props.light}
  >
    {props.children}
  </LinkContainer>
);

export const ExternalLink = (props: ExternalLinkProps) => (
  <ExternalLinkContainer
    className={props.className}
    href={props.href}
    pink={props.pink}
    light={props.light}
    onClick={props.onClick}
  >
    {props.children}
  </ExternalLinkContainer>
);
