import React from 'react';
import styled from 'styled-components';
import { config } from '../config/config';
import { redirectWithToken } from '../utils/navigation';

const { commonTexts } = config.textData;

const Text = styled.span`
  text-align: center;
  display: block;
  padding: 1em;
  background-color: white;
`;

const LinkButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
`;

class ToExtranet extends React.Component<{}, unknown> {
  async componentDidMount() {
    this.navigateToExtranet();
  }

  navigateToExtranet() {
    redirectWithToken(`${config.authConfig.extranetUrl}/kirjaudu`);
  }

  render() {
    return (
      <Text>
        Sinut uudelleenohjataan automaattisesti {commonTexts.extranetButton}.
        <br />
        Jos uudelleenohjaus ei jostain syystä toimi muutaman sekunnin kuluessa,{' '}
        <LinkButton onClick={this.navigateToExtranet}>
          voit siirtyä {commonTexts.extranetButton} manuaalisesti painamalla
          tästä
        </LinkButton>
        .
      </Text>
    );
  }
}
export default ToExtranet;
