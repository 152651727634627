import React from 'react';
import { instanceOf } from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';
import {
  Cookies,
  CookiesProvider,
  ReactCookieProps,
  withCookies,
} from 'react-cookie';
import { CommonSection, CommonSubSection } from './CommonStyles';
import { Page } from './Page';
import { config } from '../config/config';
import { Button } from './Button';
import colorByProduct from '../customThemes';

const { signicatUndone: texts } = config.textData;

const StyledCommonSubSection = styled(CommonSubSection)`
  max-width: 1000px;
  height: auto;
  margin: 1em 2em;
  padding: 1em;
  align-self: center;

  @media screen and (min-width: 700px) {
    margin: 1em 0;
    padding: 2em;
  }
`;

const TextContainer = styled.div`
  padding: 1em 1em 3em 1em;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin: 1em;
  }

  a {
    margin: 2em 0;
  }

  @media screen and (min-width: 700px) {
    padding: 1em;
  }
`;

const Header = styled.h1`
  font-size: ${config.themeConfig.fontSizes.medium};
  color: ${colorByProduct('headerText')};
  margin: 1em;

  @media screen and (min-width: 500px) {
    font-size: ${config.themeConfig.fontSizes.large};
  }
`;

const TextBlock = styled.p`
  margin: 0 0 1em 0;

  @media screen and (min-width: 500px) {
    margin: 0 4em 1em 4em;
  }
`;

class NeedsStrongAuth extends React.Component<ReactCookieProps> {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  componentDidMount = () => {
    document.title = texts.pageTitle;
  };

  redirectToSignicat = () => {
    // The state value is used to check that the same client that initiated the flow returns to the redirect page.
    // Signicat returns the value in the state parameter to the redirect page, and this can be compared to the value stored in the cookie.
    // This is necessary to prevent CSRF attacks.
    const signicatStateValue =
      window.crypto?.getRandomValues(new Uint32Array(1))[0].toString() ||
      Math.floor(Math.random() * 1e10).toString();

    const { cookies } = this.props;
    cookies!.set('signicatState', signicatStateValue, { path: '/' });

    const queryParams = {
      client_id: config.authConfig.SignicatApiClientId,
      redirect_uri: `${window.location.origin}/redirect`,
      state: signicatStateValue,
      response_type: 'code',
      scope: 'openid+nin+profile',
    };

    const params = {
      url: `${config.authConfig.SignicatApiUrl}/authorize`,
      query: queryParams,
    };

    window.location.href = queryString.stringifyUrl(params, {
      encode: false,
    });
  };

  render() {
    const instructions = texts.instructions.map((part: string) => {
      return <TextBlock key={part}>{part}</TextBlock>;
    });

    return (
      <CookiesProvider>
        <Page showExtranetButton={false}>
          <CommonSection>
            <StyledCommonSubSection>
              <TextContainer>
                <Header>{texts.headerTitle}</Header>

                {instructions}

                <Button onClick={this.redirectToSignicat}>
                  {texts.beginSignicat}
                </Button>
              </TextContainer>
            </StyledCommonSubSection>
          </CommonSection>
        </Page>
      </CookiesProvider>
    );
  }
}
export default withCookies(NeedsStrongAuth);
