import React from 'react';
import { EEnvironment } from '../config/config';
import { ExtranetButton } from './ExtranetButton';
import styled, { css } from 'styled-components';
import { config } from '../config/config';
import { Link } from './Button';
import LaunchpadButton from './LaunchpadButton';

interface ITopBarProps {
  showExtranetButton: boolean;
  signedIn: boolean;
}

const { logo } = config.themeConfig;

const TopBarContainer = styled.header`
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;

  ${(props: { signedIn: boolean }) =>
    props.signedIn &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 130px;
    `}

  @media screen and (min-width: 700px) {
    flex-direction: row;
    height: 80px;
    align-items: unset;
  }
`;

const CustomAmplifySignout = styled(Link)`
  font-size: ${config.themeConfig.fontSizes.small};

  @media screen and (min-width: 700px) {
    margin: auto 1.5em;
  }
`;

const Logo = styled.a`
  padding: 16px 0px 18px 0px;
  background-repeat: no-repeat;
  background-image: url(${logo.url});
  margin: ${logo.margin}px;
  width: ${logo.width}px;
  height: ${logo.height}px;
  background-size: ${logo.width}px ${logo.height}px;

  @media screen and (min-width: 700px) {
    margin: ${logo.margin}px ${logo.margin}px ${logo.margin}px 20px;
  }
`;

const LaunchpadButtonContainer = styled.div`
  @media screen and (max-width: 699px) {
    margin-right: 1em;
  }

  @media screen and (min-width: 700px) {
    margin-right: 1.5em;
    margin-left: 1.5em;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;

  @media screen and (min-width: 700px) {
    margin: unset;
    margin-left: auto;
  }
`;

const StyledEnvDisclaimer = styled.div`
  margin: auto 0;
  background: #e32424;
  width: 100px;
  position: absolute;
  top: 0;
  text-align: center;
  text-transform: uppercase;

  span {
    color: white;
    font-size: ${config.themeConfig.fontSizes.small};
    font-weight: bold;
  }

  @media screen and (min-width: 700px) {
    width: auto;
    position: relative;
    padding: 0 2em;
    margin-left: 40px;
  }
`;

export const EnvDisclaimer = () =>
  config.environmentType !== EEnvironment.PROD ? (
    <StyledEnvDisclaimer>
      <span>
        {config.product} {config.environmentType}
      </span>
    </StyledEnvDisclaimer>
  ) : null;

export const TopBar = ({
  signedIn,
  showExtranetButton,
}: ITopBarProps) => {
  return (
    <TopBarContainer signedIn={signedIn}>
      <Logo href={'/'} />
      <EnvDisclaimer />

      {signedIn && (
        <Buttons>
          {config.launchpadConfig.services && (
            <LaunchpadButtonContainer>
              <LaunchpadButton />
            </LaunchpadButtonContainer>
          )}
          {showExtranetButton && <ExtranetButton />}
          <CustomAmplifySignout to={'/kirjaudu-ulos'}>
            Kirjaudu ulos
          </CustomAmplifySignout>
        </Buttons>
      )}
    </TopBarContainer>
  );
};
