import SpotPriceWidget from './SpotPriceWidget';

export type WidgetItem = {
  id: string;
  component: any;
  minWidth: number,
  minHeight: number,
};

// An array of all supported widgets
const widgets: WidgetItem[] = [
  {
    id: 'spot-price',
    component: SpotPriceWidget,
    minWidth: 2,
    minHeight: 1,
  },
];

export default widgets;
