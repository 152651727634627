import React, { ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';
import styled, { css } from 'styled-components';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { SubmitButton, Link } from '../components/Button';
import { Spinner } from '../components/Spinner';
import { EProduct, config } from '../config/config';
import colorByProduct from '../customThemes';
import { customerNumberToCustomerId, request } from '../helpers';
import {
  CommonButtonDiv,
  CommonSubheader,
  CommonSubSection,
  CommonDescription,
  CommonFormItem,
  CommonLabel,
  CommonInput,
  CommonSection,
  CommonMessage,
} from '../components/CommonStyles';
import { EUserType } from '../types';

const { addOrganization: texts } = config.textData;

const StyledSubSection = styled(CommonSubSection)`
  text-align: left;
`;

const StyledDescription = styled(CommonDescription)`
  ${(props: { error?: boolean }) =>
    !!props.error &&
    css`
      color: ${colorByProduct('inputError')};
    `}
`;

const SubHeader = styled.p`
  margin: 0 0 0.5em 0;
  font-size: 18px;
  font-weight: bold;
`;

const StyledFormItem = styled(CommonFormItem)`
  padding: 1em 0 1em 0;

  @media screen and (min-width: 600px) {
    padding: 1em 1em 1em 0;
    width: 50%;
    min-width: 290px;
  }
`;

/* TODO: Maybe move this logic to the CommonInput component, so that the error highlighting could be used elsewhere as well */
const StyledInput = styled(CommonInput)`
  margin-bottom: 0.5em;

  ${(props: { error?: boolean }) =>
    !!props.error &&
    css`
      color: ${colorByProduct('inputError')};
      border: 2px solid ${colorByProduct('inputError')};
    `}
`;

const CheckBox = styled.input`
  margin-right: 0.5em;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  margin-right: 1em;
  align-items: baseline;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;

type State = {
  sub: string;
  type: EUserType;
  businessId: string;
  customerNumber: string;
  gsrnOrConnectionNumber: string;
  customerIds: string[];
  showResults: boolean;
  error: boolean;
  loading: boolean;
  msg: string;
  invalidBusinessId: boolean;
  invalidCustomerNumber: boolean;
  invalidGsrnOrConnectionNumber: boolean;
  isGsrnNumber: boolean;
  connectionNumberType: string;
};

const StyledHeader = styled(CommonSubheader)`
  padding: 0 0 0.5em 0;
`;

const StyledButtons = styled(CommonButtonDiv)`
  align-items: center;
`;

class AddCustomershipToOrganization extends React.Component<{}, State> {
  state: State = {
    sub: '',
    type: EUserType.Person,
    customerNumber: '',
    businessId: '',
    gsrnOrConnectionNumber: '',
    customerIds: [],
    showResults: false,
    error: false,
    loading: false,
    msg: '',
    invalidBusinessId: false,
    invalidCustomerNumber: false, // always false for JES/Nice
    invalidGsrnOrConnectionNumber: false, // always false for JES/Nice
    isGsrnNumber: false,
    connectionNumberType: 'connection',
  };

  componentDidMount = async () => {
    document.title = texts.pageTitle;
    await this.fetchData();
  };

  async fetchData() {
    try {
      /* Fetch the customer IDs associated with the current user */
      const user: any = await Auth.currentAuthenticatedUser();

      this.setState({
        sub: user.attributes.sub,
        type: user.attributes['custom:user_type'],
      });

      /* userMetadata is used to
        - know if the customerNumber already exists in the dynamoDB.
      */
      const userMetadata = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/customerMetadata`,
        params: {
          sub: user.attributes.sub,
        },
      });

      if (
        userMetadata.data.customer_ids &&
        userMetadata.data.customer_ids.length > 0
      ) {
        this.setState({
          customerIds: userMetadata.data.customer_ids,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { name, value } = event.target;

    const trimmedValue = value.trim();

    if (name === 'businessId') {
      /**
       * Business ID is 7 digits, '-', and one digit.
       * Also we want to specifically deny 0000000-0 as it's a "catch-all" business ID
       * that is being used when the actual business ID is not known => adding that could
       * provide access to invalid customerships.
       */
      const businessIdRegex = /^[0-9]{7}-[0-9]$/;
      const validBusinessId =
        businessIdRegex.test(trimmedValue) && trimmedValue !== '0000000-0';

      this.setState({
        invalidBusinessId: trimmedValue.length === 0 ? false : !validBusinessId,
      });
    }

    /**
     * Customer number, metering point number and connection number validation is done only for Elenia,
     * since the rules are way too complex for Nice and JES that there would be any point in making any regex for those
     * */
    if (config.product === 'elenia') {
      if (name === 'customerNumber') {
        /* Customer number is valid if it's 7 digits long and it begins with 1 or 7 */
        const customerNumberRegex = /^[17][0-9]{6}$/;
        const validCustomerNumber = customerNumberRegex.test(trimmedValue);

        this.setState({
          invalidCustomerNumber:
            trimmedValue.length === 0 ? false : !validCustomerNumber,
        });
      }

      if (name === 'gsrnOrConnectionNumber') {
        /**
         * Metering point number is valid if it's 6-7 digits long and it begins with 2 or 3.
         * So "2 or 3, followed by 5 or 6 digits between 0-9", in total 6-7 digits.
         */
        // TODO: Implement some kind of regex
        // const meteringPointRegex = /^[23][0-9]{5,6}$/;
        /**
         * Connection number is valid if it's 7 digits long and it begins with 4 or 5
         * So "4 or 5, followed by 6 digits between 0-9", in total 7 digits.
         * */
        const connectionNumberRegex = /^[45][0-9]{6}$/;
        const gsrnNumberRegex = /^[0-9]{9}$/;
        const meteringPointRegex = /^[23][0-9]{5,6}$/;

        const validGsrnOrConnectionNumber =
          (gsrnNumberRegex.test(trimmedValue) &&
          this.state.isGsrnNumber) ||
          (connectionNumberRegex.test(trimmedValue) &&
          this.state.connectionNumberType === 'connection') ||
          (meteringPointRegex.test(trimmedValue) &&
          this.state.connectionNumberType === 'meteringPoint');

        this.setState({
          invalidGsrnOrConnectionNumber:
            trimmedValue.length === 0
              ? false
              : !validGsrnOrConnectionNumber,
        });
      }
    }

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      error: value.length === 0,
      msg: '',
    }));
  };

  getGsrnBeginning = () => {
    switch (config.product) {
      case EProduct.ELENIA: {
        return "643006966";
      }
      case EProduct.JES: {
        return "643007609";
      }
      case EProduct.NICE: {
        return "643007127";
      }
    }
  }

  handleCheckboxChange = async (event: any) => {
    this.setState({
      isGsrnNumber: event.target.value === 'gsrn',
      connectionNumberType: event.target.value,
    });
  }

  addCustomershipToOrganizationAccount = async (event: any) => {
    event.preventDefault();

    this.setState({
      loading: true,
    });

    const trimmedCustomerNumber = this.state.customerNumber.trim();
    const trimmedBusinessId = this.state.businessId.trim();
    let trimmedGsrnOrConnectionNumber = this.state.gsrnOrConnectionNumber.trim();

    if (this.state.isGsrnNumber) {
      trimmedGsrnOrConnectionNumber = this.getGsrnBeginning() + trimmedGsrnOrConnectionNumber;
    }

    try {
      if (
        trimmedCustomerNumber.length === 0 ||
        trimmedBusinessId.length === 0 ||
        trimmedGsrnOrConnectionNumber.length === 0
      ) {
        throw new Error('Täytithän kaikki kentät.');
      }

      const customerId = customerNumberToCustomerId(trimmedCustomerNumber);
      if (this.state.customerIds.includes(customerId)) {
        this.setState({
          error: true,
          loading: false,
          msg: 'Tämä asiakasnumero on jo liitettynä tähän käyttäjätiliin.',
        });

        return;
      }

await request({
  url: `${config.authConfig.lambdaApiUrl}/idm/customer/businessWithMetadata`,
  params: {
    sub: this.state.sub,
    // TODO: This could be changed to customerID here and in the lambda
    customerNumberToCheck: trimmedCustomerNumber,
    businessIdToCheck: trimmedBusinessId,
    gsrnOrConnectionNumberToCheck: trimmedGsrnOrConnectionNumber,
  },
});

      this.setState({
        error: false,
        loading: false,
        msg: 'Liittäminen onnistui!',
      });

      /* Forward to home page after successful adding */
      window.location.assign('/');
    } catch (err) {
      console.error(err);
      this.setState({
        error: true,
        loading: false,
        msg: 'Liittäminen epäonnistui. Tarkista kentät ja yritä uudestaan.',
      });
    }
  };

  getInputLabel = () => {
    switch (this.state.connectionNumberType) {
      case ("gsrn"):
        return "GSRN-tunnuksen loppuosa";
      case ("meteringPoint"):
        return "kaukolämmön käyttöpaikkanumero";
      case ("connection"):
        return (config.product === EProduct.LOIMUA || config.product === EProduct.AURIS)
          ? "käyttöpaikkatunnus" : "liittymätunnus";
    }
  }

  render() {
    const {
      customerNumber,
      businessId,
      gsrnOrConnectionNumber,
      loading,
      error,
      msg,
      invalidBusinessId,
      invalidCustomerNumber,
      invalidGsrnOrConnectionNumber,
      isGsrnNumber
    } = this.state;

    /* Disable saving if any of the fields has not been filled and/or any of the fields are in invalid state */
    const disableSaveButton =
      businessId.trim().length === 0 ||
      customerNumber.trim().length === 0 ||
      gsrnOrConnectionNumber.trim().length === 0 ||
      invalidGsrnOrConnectionNumber ||
      invalidCustomerNumber ||
      invalidBusinessId;

    return (
      <Page>
        <PageHeader text={texts.headerTitle} />
        <CommonSection>
          <StyledSubSection>
            <StyledHeader>Liitä yritysasiakkuus</StyledHeader>

            <form onSubmit={this.addCustomershipToOrganizationAccount}>
              <CommonDescription>{texts.generalInstructions}</CommonDescription>

              <StyledFormItem>
                <CommonLabel htmlFor="businessId">
                  Liitettävä y-tunnus
                </CommonLabel>
                <StyledInput
                  error={invalidBusinessId}
                  type="text"
                  name="businessId"
                  id="businessId"
                  placeholder="Syötä y-tunnus jonka haluat liittää tiliisi"
                  onChange={this.handleInputChange}
                  value={businessId}
                />
                <StyledDescription error={invalidBusinessId}>
                  Y-tunnus on muotoa 1234567-8
                </StyledDescription>
              </StyledFormItem>

              <SubHeader>Lisää nämä tarkisteet</SubHeader>

              {texts.specificInstructions &&
                texts.specificInstructions.map((instruction: string) => (
                  <CommonDescription>{instruction}</CommonDescription>
                ))}
              <StyledFormItem>
                <CommonLabel htmlFor="customerNumber">
                  Tarkiste 1: {texts.checkField1Name}
                </CommonLabel>
                <StyledInput
                  error={invalidCustomerNumber}
                  type="text"
                  name="customerNumber"
                  id="customerNumber"
                  placeholder={`Syötä ${texts.checkField1Name.toLowerCase()}`}
                  onChange={this.handleInputChange}
                  value={customerNumber}
                />
                {texts.customerNumberFormat && (
                  <StyledDescription error={invalidCustomerNumber}>
                    {texts.customerNumberFormat}
                  </StyledDescription>
                )}
              </StyledFormItem>

              <StyledFormItem>
                <CommonLabel htmlFor="gsrnOrConnectionNumber">
                  Tarkiste 2: {texts.checkField2Name}
                </CommonLabel>

                {(config.product !== EProduct.LOIMUA && config.product !== EProduct.AURIS) &&
                  <CheckBoxContainer onChange={this.handleCheckboxChange}>
                      <CheckBoxLabel>
                      <CheckBox type="radio" id="connection" value="connection" name="field2" defaultChecked />
                      Liittymätunnus
                      </CheckBoxLabel>
                      <CheckBoxLabel>
                      <CheckBox type="radio" id="gsrn" value="gsrn" name="field2" />
                      GSRN-tunnus
                      </CheckBoxLabel>
                      {(config.product === EProduct.JES || config.product === EProduct.NICE) &&
                        <CheckBoxLabel>
                        <CheckBox type="radio" id="meteringPoint" value="meteringPoint" name="field2" />
                        Kaukolämmön käyttöpaikka
                        </CheckBoxLabel>}
                  </CheckBoxContainer>}
                {isGsrnNumber && this.getGsrnBeginning()}<StyledInput
                  error={invalidGsrnOrConnectionNumber}
                  type="text"
                  name="gsrnOrConnectionNumber"
                  id="gsrnOrConnectionNumber"
                  placeholder={`Syötä ${this.getInputLabel()}`}
                  onChange={this.handleInputChange}
                  value={gsrnOrConnectionNumber}
                />
                {(texts.meteringPointOrConnectionNumberFormat || []).map(
                  (textRow: string) => (
                    <StyledDescription
                      error={invalidGsrnOrConnectionNumber}
                    >
                      {textRow}
                    </StyledDescription>
                  ),
                )}
              </StyledFormItem>

              <StyledButtons>
                <SubmitButton disabled={disableSaveButton}>
                  {loading ? <Spinner /> : 'Tarkista ja liitä y-tunnus'}
                </SubmitButton>

                <Link pink to={'/'}>
                  Sulje
                </Link>

                <CommonMessage error={error}>{msg}</CommonMessage>
              </StyledButtons>
            </form>
          </StyledSubSection>
        </CommonSection>
      </Page>
    );
  }
}

export default AddCustomershipToOrganization;
