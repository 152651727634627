import React, { useEffect } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js/auto';
import { isCurrentTimeInRange } from './utils';
import { SpotPrice } from './types';
import { config } from '../../../../config/config';

const CanvasContainer = styled.div`
  flex: 1 1 50px;
  display: flex;
  justifycontent: center;
  position: relative;
  overflow: hidden;
`;

type Props = {
  dataset: SpotPrice[];
};

const getDisplayTime = (start: Date): string =>
  `${start.getHours().toString().padStart(2, '0')}-${(start.getHours() + 1)
    .toString()
    .padStart(2, '0')}`;

const SpotPriceChart = (props: Props) => {
  useEffect(() => {
    const container = document.getElementById('canvascontainer') as HTMLElement;

    if (!container) {
      // Container does not exist yet, do nothing
      return;
    }

    // Function to execute when the canvas container is resized
    const resizeObserver = new (window as any).ResizeObserver(
      (entries: any) => {
        if (entries.length) {
          const canvas = document.getElementById(
            'priceGraph',
          ) as HTMLCanvasElement;

          if (canvas) {
            canvas.style.width = `${container.offsetWidth}px`;
            canvas.style.height = `${container.offsetHeight}px`;
          }
        }
      },
    );

    const observer = resizeObserver.observe(container);

    let canvas = document.getElementById('priceGraph') as HTMLCanvasElement;

    if (!canvas) {
      // Canvas does not exist yet, create it
      canvas = document.createElement('canvas');
      canvas.id = 'priceGraph';
      canvas.style.width = `${container.offsetWidth}px`;
      canvas.style.height = `${container.offsetHeight}px`;
      container.appendChild(canvas);
    }

    const series = props.dataset.map((item: any, index: number) => {
      const startTime = new Date(item.startTime);
      const isCurrentHour = isCurrentTimeInRange(item.startTime, item.endTime);

      const hour = getDisplayTime(startTime);

      return {
        hour,
        // Convert from €/MWh to snt/kWh and add VAT if price is positive
        price: item.value < 0 ? item.value / 10 : (item.value / 10) * 1.24,
        // Display current hour as white bar
        color: isCurrentHour
          ? config.themeConfig.launchpad3?.currentHourSpotPriceColor
          : config.themeConfig.colors.mainThemeColor2,
      };
    });

    const chart = new Chart(canvas, {
      type: 'bar',
      options: {
        responsive: true,
        // Allow chart to scale properly to container size
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            // Show tooltip even when it does not touch the bar
            intersect: false,
          },
        },
        scales: {
          x: {
            title: {
              text: 'Tunti',
              display: false,
              color: '#fff',
            },
            ticks: {
              color: '#fff',
              font: {
                size: 8,
              },
            },
          },
          y: {
            title: {
              text: 'snt/kWh',
              display: false,
              color: '#fff',
            },
            ticks: {
              color: '#fff',
              font: {
                size: 8,
              },
              stepSize: 0.5,
            },
          },
        },
      },
      data: {
        labels: series.map(row => row.hour),
        datasets: [
          {
            label: 'Hinta snt/kWh',
            data: series.map(row => row.price),
            backgroundColor: series.map(row => row.color),
            borderRadius: {
              topLeft: 2,
              topRight: 2,
              bottomLeft: 0,
              bottomRight: 0,
            },
          },
        ],
      },
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }

      if (chart) {
        chart.destroy();
      }
    };
  }, [props.dataset]);

  return <CanvasContainer id="canvascontainer"></CanvasContainer>;
};

export default SpotPriceChart;
