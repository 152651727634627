import React from 'react';
import { Circle } from 'styled-spinkit';
import styled from 'styled-components';
import { Page } from '../components/Page';
import { config } from '../config/config';

const ContentWrapper = styled.div`
  height: 60vh;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const LoadingSpinnerWrapper = styled.div`
  text-transform: uppercase;

  p {
    font-size: ${config.themeConfig.fontSizes.large};
    position: relative;
  }

  div {
    margin: 1em auto 3em auto;
  }
`;

class LoadingPage extends React.Component<{}> {
  render() {
    return (
      <Page showExtranetButton={false}>
        <ContentWrapper>
          <LoadingSpinnerWrapper>
            <Circle
              color={config.themeConfig.colors.mainThemeColor2}
              size={80}
            />
            <p>Haetaan käyttäjätietoja...</p>
          </LoadingSpinnerWrapper>
        </ContentWrapper>
      </Page>
    );
  }
}

export default LoadingPage;
