import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { Auth } from 'aws-amplify';
import {
  CommonSubSection,
  CommonSubheader,
  CommonLabel,
  CommonInput,
  CommonColumns,
  CommonMessage,
} from '../../components/CommonStyles';
import {
  SearchResultContainer,
  StyledButtons,
  StyledForm,
  StyledFormItem,
} from './styles';
import { Button, SubmitButton } from '../../components/Button';
import { Spinner } from '../../components/Spinner';
import { config } from '../../config/config';
import { Contract, Customer, CustomerType } from '../../types';
import {
  customerNumberToCustomerId,
  request,
  getStreetAddress,
} from '../../helpers';

const CustomerData = styled.div`
  margin-bottom: 2em;
`;

const TextWithWarning = styled.span`
  ${(props: { warn: boolean }) =>
    props.warn &&
    css`
      color: red;
    `}
`;

const MeteringPointTable = styled.table`
  display: flex;
  flex-direction: column;
`;

const TableHeadRow = styled.tr`
  display: flex;
`;

const TableRow = styled.tr`
  display: flex;

  &:nth-child(odd) {
    background: ${config.themeConfig.colors.grayLight};
  }
`;

const TableCell = styled.td`
  flex: 1;
  max-width: 500px;
  padding: 0.5em 0;
  font-size: ${config.themeConfig.fontSizes.small};
`;

const HeaderCell = styled.td`
  flex: 1;
  max-width: 500px;
  padding: 1em 0 0 0;
  font-weight: bold;
`;

type Props = {
  hideSection: () => void;
  toggleReload: (value: boolean) => void;
  modifiedUserSub: string;
  modifiedUserType: CustomerType | null;
};

type State = {
  customerIdToSearch: string;
  searching: boolean;
  searchingError: boolean;
  searchingMsg: string;
  adding: boolean;
  addingError: boolean;
  addingMsg: string;
  customershipResult: Customer | null;
};

export class AdminAddToAccount extends React.Component<Props, State> {
  state: State = {
    customerIdToSearch: '',
    searching: false,
    searchingError: false,
    searchingMsg: '',
    adding: false,
    addingError: false,
    addingMsg: '',
    customershipResult: null,
  };

  handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      searchingError: false,
      searchingMsg: '',
      addingError: false,
      addingMsg: '',
      customershipResult: null,
    }));
  };

  fetchCustomership = async (event: any) => {
    event.preventDefault();
    this.setState({
      searching: true,
    });

    try {
      if (this.state.customerIdToSearch.length === 0) {
        throw new Error('Asiakasnumeroa ei syötetty.');
      }

      const adminUser: any = await Auth.currentAuthenticatedUser();
      const { sub: adminSub } = adminUser.attributes;

      const customerId = customerNumberToCustomerId(
        this.state.customerIdToSearch,
      );

      const result = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/customer`,
        params: {
          sub: adminSub,
          customerIdToFetch: customerId,
          adminRequired: true,
        },
      });

      this.setState({
        searching: false,
        searchingError: false,
        customershipResult: result.data.enerim_data,
      });
    } catch (err) {
      let message = 'Haussa tapahtui virhe';

      if (err.statusCode === 404) {
        message = 'Asiakkuutta ei löytynyt.';
      }

      this.setState({
        searching: false,
        searchingError: true,
        searchingMsg: message,
      });
    }
  };

  addCustomerIdToAccount = async (event: any) => {
    event.preventDefault();

    this.setState({
      adding: true,
    });

    try {
      if (
        !this.state.customershipResult ||
        this.state.customershipResult.customerId.length === 0
      ) {
        throw new Error('Asiakasnumeroa ei löydetty.');
      }

      const customerId = this.state.customershipResult.id;

      await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/addToAccount`,
        method: 'POST',
        params: {
          modifiedUserSub: this.props.modifiedUserSub,
          customerIdToAdd: customerId,
          adminRequired: true,
        },
      });

      this.setState({
        adding: false,
        addingError: false,
        addingMsg: 'Lisäys onnistui.',
      });

      this.props.toggleReload(true);
    } catch (err) {
      let message = 'Lisäyksessä tapahtui virhe.';

      if (err.statusCode === 403) {
        message = 'Asiakasnumero on jo liitetty käyttäjätiliin.';
      }

      this.setState({
        adding: false,
        addingError: true,
        addingMsg: message,
      });
    }
  };

  close = (event: any) => {
    event.preventDefault();

    this.setState(prevState => ({
      customerIdToSearch: '',
      searchingError: false,
      addingError: false,
      searchingMsg: '',
      addingMsg: '',
      customershipResult: null,
    }));

    this.props.hideSection();
  };

  getMeteringPoints = (contracts: Contract[]) => {
    if (!contracts || contracts.length === 0) {
      return null;
    }

    const meteringPointRows = contracts.map((contract: Contract, i: number) => {
      if (!contract || !contract.meteringPoint) {
        return null;
      }

      const address: string = getStreetAddress(
        contract.meteringPoint.currentAddress,
      );

      return (
        <TableRow key={i}>
          <TableCell>{contract.meteringPoint.meteringPointNumber}</TableCell>
          <TableCell>{address}</TableCell>
        </TableRow>
      );
    });

    return meteringPointRows;
  };

  renderSearchResult() {
    const { customershipResult } = this.state;

    if (!customershipResult) {
      return null;
    }

    const name =
      customershipResult.customerType === CustomerType.Organisation
        ? customershipResult.companyName
        : `${customershipResult.firstName} ${customershipResult.lastName}`;

    const meteringPointRows = this.getMeteringPoints(
      customershipResult.contracts,
    );

    const customerType =
      customershipResult.customerType === CustomerType.Organisation
        ? 'Yritys'
        : 'Yksityinen';

    return (
      <CustomerData>
        <p>
          <b>Asiakasnumero: </b> {customershipResult.customerId}
        </p>
        <p>
          <b>Nimi: </b> {name}
        </p>
        <p>
          <b>Asiakastyyppi: </b>{' '}
          <TextWithWarning
            warn={
              customershipResult.customerType !== this.props.modifiedUserType
            }
          >
            {customerType}
          </TextWithWarning>
        </p>
        <p>
          <b>Sopimuksia: </b> {customershipResult.contracts.length} kpl
        </p>

        {customershipResult.contracts.length > 0 && (
          <MeteringPointTable>
            <thead>
              <TableHeadRow>
                <HeaderCell>Käyttöpaikkanumero</HeaderCell>
                <HeaderCell>Osoite</HeaderCell>
              </TableHeadRow>
            </thead>
            <tbody>{meteringPointRows}</tbody>
          </MeteringPointTable>
        )}
      </CustomerData>
    );
  }
  render() {
    const {
      adding,
      addingError,
      addingMsg,
      customerIdToSearch,
      customershipResult,
      searching,
      searchingError,
      searchingMsg,
    } = this.state;

    const searchResultDiv = this.renderSearchResult();

    return (
      <CommonSubSection>
        <CommonSubheader>
          Asiakkuuden liittäminen käyttäjätiliin
        </CommonSubheader>

        <CommonColumns>
          <StyledForm onSubmit={this.fetchCustomership}>
            <StyledFormItem>
              <CommonLabel htmlFor="customerIdToSearch">
                Asiakasnumero
              </CommonLabel>
              <CommonInput
                type="text"
                name="customerIdToSearch"
                id="customerIdToSearch"
                onChange={this.handleInputChange}
                value={customerIdToSearch}
              />
            </StyledFormItem>

            <StyledButtons>
              <SubmitButton disabled={customerIdToSearch.length === 0}>
                {searching ? <Spinner /> : 'Hae'}
              </SubmitButton>

              <Button light onClick={this.close}>
                Sulje
              </Button>
            </StyledButtons>

            <CommonMessage error={searchingError}>{searchingMsg}</CommonMessage>
          </StyledForm>
          {customershipResult && (
            <SearchResultContainer>
              <h3>Löydetty asiakkuus</h3>

              {searchResultDiv}

              <StyledButtons>
                <CommonMessage error={addingError}>{addingMsg}</CommonMessage>
                <Button
                  disabled={
                    customershipResult.customerType !==
                    this.props.modifiedUserType
                  }
                  onClick={this.addCustomerIdToAccount}
                >
                  {adding ? <Spinner /> : 'Liitä asiakasnumero käyttäjätiliin'}
                </Button>
              </StyledButtons>
            </SearchResultContainer>
          )}
        </CommonColumns>
      </CommonSubSection>
    );
  }
}
