import { IThemeConfig } from './ThemeConfig';
import logoUrl from '../../images/loimua/logo.svg';

const loimuaThemeConfig: IThemeConfig = {
  colors: {
    mainThemeColor1: 'rgb(0, 48, 87)', // dark-blue
    mainThemeColor2: 'rgb(0, 169, 206)', // turqoise
    secondaryColor1: 'rgb(227, 28, 121)', // pink
    secondaryColor2: 'rgb(206, 220, 0)', // green
    secondaryColor3: 'rgb(27, 170, 205)', // blue
    noticesTextColor: 'rgb(255, 255, 255)',
    noticesBackgroundColor: 'rgb(0, 169, 206)',

    // Gray colors
    grayDark: 'rgb(53, 53, 53)',
    grayMedium: 'rgb(200, 200, 200)',
    grayLight: 'rgb(238, 238, 238)',
  },
  logo: {
    width: 200,
    height: 60,
    margin: 10,
    url: logoUrl,
  },
  fontSizes: {
    small: '14px',
    medium: '18px',
    large: '20px',
    extraLarge: '32px',
  },
  fontFamilies: {
    headers: 'Apex, arial, helvetica, verdana, sans-serif',
    regular: 'Apex, arial, helvetica, verdana, sans-serif',
    buttonFont: 'Lato, arial, helvetica, verdana, sans-serif',
  },
  mobileBreakPoint: 900,
};

export default loimuaThemeConfig;
