import { IAnalyticsConfig } from './AnalyticsConfig';

const eleniaProdAnalyticsConfig: IAnalyticsConfig = {
  // Amazon Pinpoint App Client ID
  appId: 'bf0699ed086345f49bfdbd15a7cd6b6d',
  // Amazon service region
  region: 'eu-west-1',
  mandatorySignIn: false,
};

export default eleniaProdAnalyticsConfig;
