import React from 'react';
import styled from 'styled-components';
import { config } from '../config/config';
import Divider from './Divider';

const TrademarkLogo = styled.div`
  background-repeat: no-repeat;
  background-image: url(${config.themeConfig.trademarkLogo?.url});

  width: ${config.themeConfig.trademarkLogo?.width}px;
  height: ${config.themeConfig.trademarkLogo?.height}px;
  background-size: ${config.themeConfig.trademarkLogo?.width}px
    ${config.themeConfig.trademarkLogo?.height}px;
`;

const SmallLogo = styled.div`
  background-repeat: no-repeat;
  background-image: url(${config.themeConfig.smallLogo?.url});

  width: ${config.themeConfig.smallLogo?.width}px;
  height: ${config.themeConfig.smallLogo?.height}px;
  background-size: ${config.themeConfig.smallLogo?.width}px
    ${config.themeConfig.smallLogo?.height}px;

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  align-self: flex-end;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    width: 100%;
  }

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    margin-right: 20px;
  }
`;

const LogoDivider = styled(Divider)`
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export default function LogoFooter(props: { className?: string }) {
  return (
    <LogoContainer className={props.className}>
      <LogoDivider className="LogoDivider" />
      <SmallLogo />
      <TrademarkLogo />
    </LogoContainer>
  );
}
