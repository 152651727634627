/**
 * This file exports the correct them object dynamically for each product version,
 * leaving the unused configs out of the build.
 */
import { EProduct } from '../config';

export interface IThemeConfig {
  // RGB codes
  colors: {
    mainThemeColor1: string;
    mainThemeColor2: string;
    secondaryColor1: string;
    secondaryColor2: string;
    secondaryColor3: string;
    noticesTextColor?: string;
    noticesBackgroundColor?: string;
    backgroundColor1?: string;
    backgroundColor2?: string;
    formBackground?: string;
    backgroundGradient?: string;
    mainThemeColor2WithOpacity?: string;

    // Gray colors
    grayDark: string;
    grayMedium: string;
    grayLight: string;
  };
  logo: {
    height: number;
    margin: number;
    url: string;
    width: number;
  };
  logoLight?: {
    height: number;
    margin: number;
    url: string;
    width: number;
  };
  trademarkLogo?: {
    height: number;
    margin: number;
    url: string;
    width: number;
  };
  smallLogo?: {
    height: number;
    margin: number;
    url: string;
    width: number;
  };
  showPasswordLogo?: { url: string };
  hidePasswordLogo?: { url: string };
  closeButtonIcon?: { url: string };
  fontSizes: {
    small: string;
    medium: string;
    large: string;
    extraLarge: string;
  };
  fontFamilies: {
    headers: string;
    regular: string;
    buttonFont: string;
  };
  launchpad?: {
    signOutButtonBackground: string;
    signOutButtonText: string;
    cancelButtonBackground: string;
    noticesBackgroundColor: string;
    noticesTextColor: string;
  };
  launchpad2?: {
    primaryButtonBackground: string;
    primaryButtonFilter: string;
    primaryButtonColor: string;
    secondaryButtonBackground: string;
    secondaryButtonFilter: string;
    secondaryButtonTextColor: string;
    noticesBackgroundColor: string;
    noticesTextColor: string;
    titleContainerBackground: string;
    titleContainerFilter: string;
    titleColor: string;
    editTitleColor: string;
    editSubtitleColor: string;
    appLinkBackground: string;
    appLinkFilter: string;
    appLinkTitleColor: string;
    removeButtonBackground: string;
    removeButtonColor: string;
    plusSignBackground: string;
    plusSignColor: string;
    widgetBackground: string;
    widgetFilter: string;
    widgetTitleColor: string;
    widgetTextColor: string;
  };
  launchpad3?: {
    primaryButtonBackground: string;
    primaryButtonFilter: string;
    primaryButtonColor: string;
    secondaryButtonBackground: string;
    secondaryButtonFilter: string;
    secondaryButtonTextColor: string;
    noticesBackgroundColor: string;
    noticesTextColor: string;
    titleContainerBackground: string;
    titleContainerFilter: string;
    titleColor: string;
    editTitleColor: string;
    editSubtitleColor: string;
    appLinkBackground: string;
    appLinkFilter: string;
    appLinkTitleColor: string;
    removeButtonBackground: string;
    removeButtonColor: string;
    plusSignBackground: string;
    plusSignColor: string;
    widgetBackground: string;
    widgetFilter: string;
    widgetTitleColor: string;
    widgetTextColor: string;
    currentHourSpotPriceColor?: string;
    topAppBarBackgroundColor?: string;
    mobileDrawerBackgroundColor?: string;
  };
  mobileBreakPoint: number;
  /* TODO: Fonts? Font sizes? Default margins and whatnots */
}

export const ThemeConfig = (product: EProduct): IThemeConfig => {
  try {
    import(`./${product}.css`);

    return require(`./ThemeConfig.${product}`).default;
  } catch (err) {
    throw Error(`Invalid PRODUCT (${product}) specified`);
  }
};
