import { Auth } from 'aws-amplify';
import React from 'react';
import styled from 'styled-components';
import { AdminAddCustomerShipForm } from '../components/AdminAddCustomerShipForm';
import { Link } from '../components/Button';
import {
  CommonButtonDiv,
  CommonLabel,
  CommonSection,
  CommonSubheader,
  CommonSubSection,
  CommonColumns,
} from '../components/CommonStyles';
import { CustomershipList } from '../components/CustomershipList';
import { Page } from '../components/Page';
import { Dashboard } from '../components/Dashboard';
import { PageHeader } from '../components/PageHeader';
import { Spinner } from '../components/Spinner';
import { config } from '../config/config';
import { isAdmin, isAllowedToSeeStatistics, request } from '../helpers';
import { Customer, EUserType } from '../types';
import VerifyCustomerships from './VerifyCustomerships';
import LoadingPage from './LoadingPage';
import { AdminServiceLink } from '../components/AdminServiceLink';

const { homePage, verifyCustomerships } = config.textData;

type State = {
  email: string;
  enerimData: Customer[];
  familyName: string;
  searchedCounter: number;
  givenName: string;
  ignoredCustomerIds: string[];
  isAdmin: boolean;
  linkedBusinessIds: string[];
  linkedCustomerIds: string[];
  initialLoading: boolean;
  msg: string;
  newCustomerIds: string[];
  phoneNumber: string;
  searchedCustomerIds: string[];
  showStatistics: boolean;
  sub: string;
  type: EUserType;
  verifyCustomerships: boolean;
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  justify-content: space-between;

  a {
    margin-top: 0.5em;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;

    a {
      margin-top: 0;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  max-width: 800px;
  flex-wrap: wrap;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const InfoSlot = styled.div`
  width: 50%;
  text-align: left;
  padding: 0 1em 1em 0;
`;

const Input = styled.span``;

const SearchResultDiv = styled.div`
  position: fixed;
  bottom: 1em;
  left: 1em;
  right: 1em;
  padding: 1em;
  flex-direction: column;
  margin: 0.5em 0;
  border-radius: 3px;
  background-color: ${config.themeConfig.colors.mainThemeColor1};
  display: flex;

  span {
    color: white;
    font-size: ${config.themeConfig.fontSizes.small};
  }

  @media screen and (min-width: 350px) {
    right: unset;
    flex-direction: row;
    span {
      font-size: ${config.themeConfig.fontSizes.medium};
    }
  }

  @media screen and (min-width: 600px) {
    span {
      font-size: ${config.themeConfig.fontSizes.large};
    }
  }
`;

const StyledContentContainer = styled(CommonSection)`
  padding: 1em 1em 0.5em 1em;
`;

const StyledHeader = styled(CommonSubheader)`
  padding: 0.2em 2em 0.2em 0;
  margin-right: 1em;
`;

const TextBlocks = styled.div`
  max-width: 850px;
`;

const TextBlock = styled.p`
  text-align: left;
  margin: 0 0 1em 0;
`;

type Props = {
  verifyCustomerships: boolean;
};

class ProfileOverview extends React.Component<Props, State> {
  state: State = {
    email: '',
    enerimData: [],
    familyName: '',
    givenName: '',
    ignoredCustomerIds: [],
    isAdmin: false,
    linkedBusinessIds: [],
    linkedCustomerIds: [],
    initialLoading: true,
    msg: '',
    newCustomerIds: [],
    phoneNumber: '',
    searchedCounter: 0,
    searchedCustomerIds: [],
    showStatistics: false,
    sub: '',
    type: EUserType.Person,
    verifyCustomerships: this.props.verifyCustomerships,
  };

  componentDidMount = async () => {
    document.title = this.props.verifyCustomerships
      ? verifyCustomerships.found.pageTitle
      : homePage.pageTitle;
    this.fetchData();
  };

  componentDidUpdate = async (prevProps: any, prevState: State) => {
    if (
      this.state.isAdmin &&
      prevState.linkedCustomerIds.length !== this.state.linkedCustomerIds.length
    ) {
      this.fetchAdminData(this.state.sub);
    }
  };

  getTotalAmountOfItemsToFetch = () => {
    const {
      isAdmin,
      type,
      linkedCustomerIds,
      linkedBusinessIds,
      ignoredCustomerIds,
    } = this.state;

    if (isAdmin) {
      return linkedCustomerIds.length + ignoredCustomerIds.length;
    }

    if (type === EUserType.Organization) {
      if (
        linkedBusinessIds.length === 0 &&
        linkedCustomerIds.length + ignoredCustomerIds.length !== 0
      ) {
        return 1;
      }

      return linkedBusinessIds.length;
    }

    return 1; // There's only one SSN per person.
  };

  async fetchData() {
    try {
      const user: any = await Auth.currentAuthenticatedUser();

      const {
        email,
        sub,
        family_name: familyName,
        given_name: givenName,
        phone_number: phoneNumber = 'Ei määritelty',
        'custom:user_type': type,
      } = user.attributes;

      /**
       * Get initial data
       * - Customer numbers and business IDs that are already attached to the user
       * - Number of business IDs attached to the user (used in the spinners)
       */
      const userMetadata = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/customerMetadata`,
        params: {
          sub,
        },
      });

      const adminUser = isAdmin(user);
      const showStatistics = isAllowedToSeeStatistics(user);

      this.setState({
        email,
        familyName,
        givenName,
        phoneNumber,
        sub,
        showStatistics,
        type,
        isAdmin: adminUser,
        ignoredCustomerIds: userMetadata.data.ignored_customer_ids,
        linkedCustomerIds: userMetadata.data.customer_ids,
        linkedBusinessIds: userMetadata.data.business_ids,
        /**
         * If there are no customer_ids listed in the DynamoDB, we don't yet know if there is
         * any data to be displayed or not for the user --> keep initialLoading as true
         *
         * If there ARE customer_ids listed in the DynamoDB, we know that there are customer
         * info to be fetched --> we can set the initialLoading to false, so instead of
         * the whole page loading we can display the info texts and
         * the actual customer data will be rendered as they are fetched.
         */
        initialLoading: userMetadata.data.customer_ids.length === 0,
      });

      if (!adminUser) {
        /* Fetch data from Enerim based of the user type. */
        type === EUserType.Person
          ? await this.fetchPersonData(sub)
          : await this.fetchOrganizationData(sub);
      } else {
        this.setState({
          initialLoading: false,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Fetch customer data for admin user
   */
  async fetchAdminData(sub: string) {
    try {
      this.state.linkedCustomerIds.map(async (customerId: string) => {
        const alreadyFetched = this.state.enerimData.some(
          (datapoint: Customer) => datapoint.id === customerId,
        );

        if (alreadyFetched) {
          /* This data is already displayed to the user, no need to fetch it again */
          return;
        }

        try {
          const data = await request({
            url: `${config.authConfig.lambdaApiUrl}/idm/admin/customer`,
            params: {
              sub,
              customerIdToFetch: customerId,
              adminRequired: true,
            },
          });
          this.setState({
            searchedCustomerIds: this.state.searchedCustomerIds.concat([
              customerId,
            ]),
            searchedCounter: this.state.searchedCounter + 1,
            enerimData: this.state.enerimData.concat(data.data.enerim_data),
          });
        } catch (err) {
          console.log(
            `Unable to find customership for customer ID ${customerId}`,
          );

          /* Increase the counters even after an error so that the loading spinner doesn't get stuck. */
          this.setState({
            searchedCustomerIds: this.state.searchedCustomerIds.concat([
              customerId,
            ]),
            searchedCounter: this.state.searchedCounter + 1,
          });
        }
      });
    } catch (err) {
      console.log('Error fetching data from Enerim for admin user.'); //FIXME:
    }
  }

  async fetchOrganizationData(sub: string) {
    try {
      /**
       * Note!
       * These queries have to be made asynchronously, because the Lambda stores the newly found
       * customer IDs to the DynamoDB (alreadyLinkedIDs + newIDsFoundForBusinessId) and if there are
       * multiple requests happening simultaneously to the same sub, the subsequent queries will overwrite the
       * results from the previous query. This is not _ a huuuuge _ problem because by refreshing the page the
       * same requests are done again and the previously overwritten/unsuccessfully
       * saved customer IDs are now saved just fine.
       *
       * However doing the requests asyncronously like this
       * allows the requests to finish before moving on to the next one,
       * allowing us to display some sort of "progress bar" to the user
       * without having to make them wait long periods of time.
       */

      if (this.state.linkedBusinessIds.length === 0) {
        /* Check if the business user has some manually added customer ships that should be fetched separately */
        if (
          this.state.linkedCustomerIds.length !== 0 ||
          this.state.ignoredCustomerIds.length !== 0
        ) {
          try {
            const data = await request({
              url: `${config.authConfig.lambdaApiUrl}/idm/customer/manuallyAddedBusiness`,
              params: {
                sub,
              },
            });

            this.setState({
              searchedCounter: this.state.searchedCounter + 1,
              enerimData: this.state.enerimData.concat(data.data.enerim_data),
              initialLoading: false,
            });
          } catch (err) {
            console.log(
              `Unable to find manually added customerships for user.`,
            );

            /* Increase the counters even after an error so that the loading spinner doesn't get stuck. */
            this.setState({
              searchedCounter: this.state.searchedCounter + 1,
              initialLoading: false,
            });
          }
        } else {
          this.setState({
            initialLoading: false,
          });
        }
      } else {
        const fetchedCustomerIds = new Set<string>();
        const linkedCustomerIds = this.state.linkedCustomerIds;
        for (let i = 0; i < this.state.linkedBusinessIds.length; i++) {
          const businessIdToFetch = this.state.linkedBusinessIds[i];

          try {
            const data = await request({
              url: `${config.authConfig.lambdaApiUrl}/idm/customer/businessWithBusinessId`,
              params: {
                sub,
                businessIdToFetch,
              },
            });

            // Keep track which customers have been fetched
            const fetchedCustomers :Customer[] = data.data.enerim_data;
            fetchedCustomers.forEach((customer: Customer) => fetchedCustomerIds.add(customer.id));

            this.setState({
              searchedCounter: this.state.searchedCounter + 1,
              enerimData: this.state.enerimData.concat(data.data.enerim_data),
              newCustomerIds: this.state.newCustomerIds.concat(
                data.data.new_customer_ids,
              ),
              linkedCustomerIds: data.data.linked_customer_ids,
              initialLoading: false,
            });
          } catch (err) {
            console.log(
              `Unable to find customership for business ID ${businessIdToFetch}`,
            );

            /* Increase the counters even after an error so that the loading spinner doesn't get stuck. */
            this.setState({
              searchedCounter: this.state.searchedCounter + 1,
              initialLoading: false,
            });
          }
        }
        const customerIdsToFetch = linkedCustomerIds.filter(customerId => !fetchedCustomerIds.has(customerId));
        if (customerIdsToFetch.length > 0) {
          const data = await request({
            url: `${config.authConfig.lambdaApiUrl}/idm/customer/customerDataForSub`,
            params: {
              sub,
              customerIds: customerIdsToFetch.join(),
            },
          });
          this.setState({
            enerimData: this.state.enerimData.concat(data.data.enerim_data),
          });
        }
      }
    } catch (err) {
      console.log('Error fetching data from Enerim for organization user.'); //FIXME:
    }
  }

  /**
   * Fetch customer data for user type = person.
   * 1 person => 1 SSN => Only 1 query to Enerim.
   */
  async fetchPersonData(sub: string) {
    try {
      const data = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/customer/personWithSSN`,
        params: {
          sub,
        },
      });

      this.setState({
        searchedCounter: this.state.searchedCounter + 1,
        newCustomerIds: data.data.new_customer_ids,
        linkedCustomerIds: data.data.linked_customer_ids,
        enerimData: this.state.enerimData.concat(data.data.enerim_data),
        initialLoading: false,
      });
    } catch (err) {
      console.error('Error fetching data from Enerim for person user.'); //FIXME:
      this.setState({
        searchedCounter: this.state.searchedCounter + 1,
        initialLoading: false,
      });
    }
  }

  updateCustomershipActivity = async (
    task: 'ignore' | 'reactivate',
    customerId: string,
  ) => {
    try {
      if (!customerId) {
        throw new Error('Customer ID to update missing.');
      }

      const { isAdmin, sub } = this.state;

      const [key, endpoint] =
        task === 'reactivate'
          ? ['customerIdToReactivate', 'customer/reactivate']
          : isAdmin
          ? ['customerIdToRemove', 'admin/removeCustomer']
          : ['customerId', 'customer/ignore'];

      const baseParams = {
        sub,
        [key]: customerId,
      };

      const params = isAdmin
        ? {
            ...baseParams,
            adminRequired: true,
          }
        : baseParams;

      const updatedData = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/${endpoint}`,
        method: 'POST',
        params,
      });

      const updatedLinkedCustomerIds = updatedData.data.linked_customer_ids;
      const updatedIgnoredCustomerIds =
        updatedData.data.ignored_customer_ids || [];

      this.updateCustomerIds(
        updatedLinkedCustomerIds,
        updatedIgnoredCustomerIds,
      );
    } catch (error) {
      console.error('Erroring', error); //FIXME:
    }
  };

  resetCustomerships = async () => {
    try {
      const resetData = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/resetCustomers`,
        method: 'POST',
        params: {
          sub: this.state.sub,
          adminRequired: true,
        },
      });

      this.updateCustomerIds(resetData.data.linked_customer_ids, []);
    } catch (err) {
      console.error(err);
      throw new Error('Resetting customerships has failed.');
    }
  };

  updateCustomerIds = async (
    updatedLinkedCustomerIds: string[],
    updatedIgnoredCustomerIds: string[],
  ) => {
    this.setState({
      ignoredCustomerIds: updatedIgnoredCustomerIds,
      linkedCustomerIds: updatedLinkedCustomerIds,
    });
  };

  updateCustomershipList = async (customerId: string) => {
    this.setState({
      linkedCustomerIds: [customerId].concat(this.state.linkedCustomerIds),
      newCustomerIds: [customerId],
    });
  };

  verify = async () => {
    try {
      await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/customer/verify-customerships`,
        method: 'POST',
        params: {
          sub: this.state.sub,
        },
      });

      /* Forward the user to the Extranet */
      window.location.href = `${config.authConfig.extranetUrl}/kirjaudu`;
    } catch (err) {
      console.error(err);
      throw new Error('Verifying customerships has failed.');
    }
  };

  getInstructions = () => {
    const { type, linkedCustomerIds, linkedBusinessIds } = this.state;

    if (type === EUserType.Person) {
      // No customerships found for SSN
      if (linkedCustomerIds.length === 0) {
        return this.generateInstructions(
          homePage.instructions.personWithoutCustomerships,
        );
      }

      return this.generateInstructions(
        homePage.instructions.personWithCustomerships,
      );
    } else {
      if (linkedBusinessIds.length === 0) {
        return this.generateInstructions(
          homePage.instructions.organizationWithoutCustomerships,
        );
      }
      return this.generateInstructions(
        homePage.instructions.organizationWithCustomerships,
      );
    }
  };

  generateInstructions = (instructionParts: string[]) => {
    const parts = instructionParts.map((part: string, i: number) => {
      return <TextBlock key={part}>{part}</TextBlock>;
    });

    return <TextBlocks>{parts}</TextBlocks>;
  };

  render() {
    const {
      email,
      enerimData,
      familyName,
      givenName,
      isAdmin,
      linkedCustomerIds,
      ignoredCustomerIds,
      initialLoading,
      newCustomerIds,
      phoneNumber,
      searchedCounter,
      searchedCustomerIds,
      showStatistics,
      sub,
      type,
      verifyCustomerships,
    } = this.state;

    const total = this.getTotalAmountOfItemsToFetch();
    const isFetching = total > searchedCounter;

    if (initialLoading) {
      return <LoadingPage />;
    }

    if (verifyCustomerships) {
      return (
        <VerifyCustomerships
          customerShipsFound={linkedCustomerIds.length > 0}
          enerimData={enerimData}
          linkedCustomerIds={linkedCustomerIds}
          newCustomerIds={newCustomerIds}
          searchedCustomerIds={searchedCustomerIds}
          isFetching={isFetching}
          resetCustomerships={this.resetCustomerships}
          updateCustomershipActivity={this.updateCustomershipActivity}
          verify={this.verify}
        />
      );
    }

    const instructions = this.getInstructions();

    return (
      <Page>
        <PageHeader text={homePage.headerTitle} />

        <StyledContentContainer>
          <CommonSubSection>
            <CommonSubheader>Käyttäjäprofiilin tiedot</CommonSubheader>
            <Content>
              <Info>
                <InfoSlot>
                  <CommonLabel>Sähköposti/Käyttäjätunnus</CommonLabel>
                  <Input>{email || <Spinner dark />}</Input>
                </InfoSlot>
                <InfoSlot>
                  <CommonLabel>Puhelinnumero</CommonLabel>
                  <Input>{phoneNumber || <Spinner dark />}</Input>
                </InfoSlot>
                <InfoSlot>
                  <CommonLabel>Nimi</CommonLabel>
                  <Input>
                    {initialLoading ? (
                      <Spinner dark />
                    ) : (
                      `${givenName} ${familyName}`
                    )}
                  </Input>
                </InfoSlot>
                <InfoSlot>
                  <CommonLabel>Asiakastyyppi</CommonLabel>
                  <Input>{initialLoading ? <Spinner dark /> : type}</Input>
                </InfoSlot>
              </Info>

              <CommonButtonDiv>
                <Link light to={'/asetukset'}>
                  Päivitä käyttäjätiedot
                </Link>{' '}
                <Link light to={'/salasana'}>
                  Vaihda salasana
                </Link>
                <Link light pink to={'/poista'}>
                  Poista käyttäjätunnus{' '}
                </Link>
              </CommonButtonDiv>
            </Content>
          </CommonSubSection>

          {isAdmin && (
            <CommonSubSection>
              <HeaderDiv>
                <StyledHeader>Käyttäjä- ja sisällönhallinta</StyledHeader>
              </HeaderDiv>
              <CommonButtonDiv>
                <Link light to={'/käyttäjätilien-hallinta'}>
                  Asiakkaan käyttäjätilin hallinta
                </Link>
                <AdminServiceLink />
              </CommonButtonDiv>
            </CommonSubSection>
          )}
          {showStatistics && <Dashboard />}
          <CommonSubSection>
            <HeaderDiv>
              <StyledHeader>Käyttäjätilin asiakkuudet</StyledHeader>

              {type === EUserType.Organization && (
                <Link to={'/lisää'}>Lisää yritys</Link>
              )}
            </HeaderDiv>

            {!isAdmin && instructions}

            <CommonColumns>
              {isAdmin && (
                <AdminAddCustomerShipForm
                  linkedCustomerIds={linkedCustomerIds}
                  sub={sub}
                  updateCustomershipList={this.updateCustomershipList}
                />
              )}

              {initialLoading ? (
                <Spinner dark />
              ) : (
                <CustomershipList
                  enerimData={enerimData}
                  ignoredCustomerIds={ignoredCustomerIds}
                  isAdmin={isAdmin}
                  linkedCustomerIds={linkedCustomerIds}
                  newCustomerIds={newCustomerIds}
                  searchedCustomerIds={searchedCustomerIds}
                  type={type}
                  isFetching={isFetching}
                  resetCustomerships={this.resetCustomerships}
                  updateCustomershipActivity={this.updateCustomershipActivity}
                  verifyCustomerships={false}
                />
              )}
            </CommonColumns>
          </CommonSubSection>
        </StyledContentContainer>

        {isFetching && (
          <SearchResultDiv>
            <Spinner />
            <span>Haetaan asiakastietoja: &nbsp;</span>
            <span>
              {searchedCounter} / {total}
            </span>
          </SearchResultDiv>
        )}
      </Page>
    );
  }
}

export default ProfileOverview;
