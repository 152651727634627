import { ILaunchpadConfig } from './Launchpad';
import eleniaAuthConfig from '../auth/AuthConfig.elenia.local';
import EleniaTexts from '../content/TextData.elenia';

// Icons
import electricCar from '../../images/elenia/launchpad2/electric-car.svg';
import energyConsumption from '../../images/elenia/launchpad2/energy-consumption.svg';
import solarPanels from '../../images/elenia/launchpad2/solar-panels.svg';
import energyCommunity from '../../images/elenia/launchpad2/energy-community.svg';
import ainaLab from '../../images/elenia/launchpad2/aina-lab.svg';
import invoicing from '../../images/elenia/launchpad2/invoicing.svg';
import loadControl from '../../images/elenia/launchpad2/load-control.svg';
import contactService from '../../images/elenia/launchpad2/contact-service.svg';
import faultService from '../../images/elenia/launchpad2/fault-service.svg';

// Background image
import launchpadBackgroundImage from '../../images/elenia/launchpad2/launchpad_v2.jpg';

const launchpadConfig: ILaunchpadConfig = {
  version: 3,
  backgroundImage: launchpadBackgroundImage,
  amountOfColumns: 4,
  services: [
    {
      id: 'extranet',
      title: EleniaTexts.commonTexts.productName,
      url: `${eleniaAuthConfig.extranetUrl}/kirjaudu`,
      image: energyConsumption,
      type: 'link',
    },
    {
      id: 'fault-service',
      title: 'Vikapalvelu',
      url: `${eleniaAuthConfig.extranetUrl}?faultService=1`,
      image: faultService,
      type: 'link',
    },
    {
      id: 'contact-service',
      title: 'Ota yhteyttä',
      url: `${eleniaAuthConfig.extranetUrl}?contactService=1`,
      image: contactService,
      type: 'link',
    },
    {
      id: 'invoicing',
      title: 'Laskutus',
      url: 'https://ainalab-dev.aws.elenia.fi/?view=invoices&lv=i',
      image: invoicing,
      type: 'link',
    },
    {
      id: 'electric-car',
      title: 'Latauslaskuri',
      url: 'https://lataus-dev.elenia.fi',
      image: electricCar,
      type: 'link',
    },
    {
      id: 'energy-community',
      title: 'Energiayhteisö',
      url: 'https://energiayhteiso-dev.elenia.fi',
      image: energyCommunity,
      type: 'link',
    },
    {
      id: 'solar',
      title: 'Aurinkosähkö',
      url: 'https://aurinkosahko-dev.elenia.fi',
      image: solarPanels,
      type: 'link',
    },
    {
      id: 'ainalab',
      title: 'AinaLab',
      url: 'https://ainalab-dev.aws.elenia.fi/?lv=m',
      image: ainaLab,
      type: 'link',
    },
    {
      id: 'ainalab_kuormanohjaus',
      title: 'Kuormanohjaus',
      url: 'https://ainalab-dev.aws.elenia.fi/?view=relay_control&lv=m',
      image: loadControl,
      type: 'link',
    },
    // Spot price widget
    {
      id: 'spot-price',
      title: 'spot-price',
      url: '-',
      type: 'widget',
    },
  ],
};

export default launchpadConfig;
