import { Auth } from 'aws-amplify';
import { instanceOf } from 'prop-types';
import styled from 'styled-components';
import { Circle } from 'styled-spinkit';
import queryString from 'query-string';
import React from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { config } from '../config/config';
import { request } from '../helpers';
import { Page } from '../components/Page';

type State = {
  error: boolean;
  ready: boolean;
};

const ContentWrapper = styled.div`
  height: 60vh;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const LoadingSpinnerWrapper = styled.div`
  text-transform: uppercase;

  p {
    font-size: ${config.themeConfig.fontSizes.large};
    position: relative;
  }

  div {
    margin: 1em auto 3em auto;
  }
`;

class SignicatRedirect extends React.Component<any, State> {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  state: State = {
    error: false,
    ready: false,
  };

  urlParams = queryString.parse(this.props.location.search);

  componentDidMount() {
    // Check that the same client (browser) that initiated the strong auth request has returned to this page.
    // This is necessary to prevent CSRF attacks.
    if (
      !(
        this.urlParams.state &&
        this.props.cookies?.get('signicatState') === this.urlParams.state
      )
    ) {
      console.error('Error: Invalid state value in request');
      this.setState({ error: true, ready: true });
      return;
    }

    // Check for an error code in the response before continuing further
    if (this.urlParams.error) {
      console.error('Error:', this.urlParams.error);
      console.error('Error description:', this.urlParams.error_description);
      this.setState({
        error: true,
        ready: true,
      });
      return;
    }

    this.fetchData();
  }

  async fetchData() {
    try {
      const user: any = await Auth.currentAuthenticatedUser();

      await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/signicat/code`,
        method: 'POST',
        data: {
          code: this.urlParams.code,
        },
        params: {
          sub: user.attributes.sub,
        },
      });

      this.setState({
        error: false,
        ready: true,
      });

      // Redirect user back to the front page after a successful authentication
      window.location.assign('/');
    } catch (err) {
      console.error(err);
      this.setState({ error: true, ready: true });
    }
  }

  render() {
    return (
      <Page>
        <ContentWrapper>
          {this.state.ready ? (
            !this.state.error ? (
              <div>
                <p>
                  Vahva tunnistautuminen onnistui! Sinut ohjataan pian etusivulle.
                </p>
                <a href="/">Palaa takaisin</a>
              </div>
            ) : (
                <div>
                  <p>
                    Virhe tunnistautumisessa!
                    Yritä tunnistautumista uudelleen tai ongelman toistuessa ota yhteyttä asiakaspalveluun.
                  </p>
                  <a href="/">Palaa takaisin</a>
                </div>
              )
          ) : (
              <LoadingSpinnerWrapper>
                <Circle
                  color={config.themeConfig.colors.mainThemeColor2}
                  size={80}
                />
                <p>Käsitellään tietoja...</p>
              </LoadingSpinnerWrapper>
            )}
        </ContentWrapper>
      </Page>
    );
  }
}
export default withCookies(SignicatRedirect);
