import { IAuthConfig } from './AuthConfig';

/**
 * Elenia dev authentication config
 */
// no protocol, the base URL
const extranetDomain = 'asiakasdev.elenia.fi';

const eleniaDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_hupO3ThVF',
  userPoolWebClientId: '1cu3ntpqvamhv4favmgke3sc34',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  widgetsApiUrl: 'https://api.asiakasdev.aina-extranet.com',
  lambdaApiUrl: 'https://api.asiakasdev.aina-extranet.com', // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://elenia.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-adventurous-tooth-705',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasdev.aina-extranet.com/admin/maintenancenotice`,
  cookieStorage: {
    domain: '.elenia.fi',
    secure: true,
    path: '/',
    expires: 30,
  },
};

export default eleniaDevAuthConfig;
