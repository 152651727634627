import React from 'react';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { Dashboard } from '../components/Dashboard';
import { CommonSection } from '../components/CommonStyles';

class DashboardPage extends React.Component<{}, {}> {
  render() {
    return (
      <Page>
        <PageHeader text="Dashboard" />
        <CommonSection>
          <Dashboard />
        </CommonSection>
      </Page>
    );
  }
}

export default DashboardPage;
