import { CognitoIdToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

const TOKEN_NAME = 'token';

const getCookieDomain = () => {
  const parts = window.location.hostname.split('.');
  parts.shift();
  return process.env.NODE_ENV === 'development' ? null : parts.join('.');
}

/**
 * Writes a new idToken cookie based on CognitoIdToken object
 * @param idTokenObj
 */
export const writeIdTokenCookie = (idTokenObj: CognitoIdToken): void => {
  const exp = idTokenObj.getExpiration();
  const jwtToken = idTokenObj.getJwtToken();

  const domain = getCookieDomain();

  const expiryDate = new Date();
  expiryDate.setTime(exp * 1000);

  let cookieStr = `${TOKEN_NAME}=${jwtToken};expires=${expiryDate.toUTCString()};path=/`;
  if (domain) cookieStr += `;domain=${domain}`;

  document.cookie = cookieStr;
};

export const getIdTokenCookie = (): string | null => {
  const cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');

    if (TOKEN_NAME === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

/**
 * Refreshes the session and writes a new idTokenCookie
 */
export const refreshSession = async (): Promise<CognitoUserSession | null> => {
  const user = await Auth.currentAuthenticatedUser();
  let session: CognitoUserSession = user.getSignInUserSession();
  const refreshToken = session.getRefreshToken();

  try {
    session = await new Promise((resolve, reject) => {
      user.refreshSession(
        refreshToken,
        (err: any, result: CognitoUserSession) => {
          if (err) {
            reject(new Error(err));
          } else {
            resolve(result);
          }
        },
      );
    });

    writeIdTokenCookie(session.getIdToken());
  } catch (err) {
    console.error('Session refresh failed', err);
    return null;
  }

  return session;
};

/**
 * Removes the ID cookie by setting the expiry time in the past
 */
export const removeIdTokenCookie = () => {
  const domain = getCookieDomain();

  let cookieStr = `${TOKEN_NAME}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;

  if (domain) cookieStr += `;domain=${domain}`;

  document.cookie = cookieStr;
};