import { getIdTokenCookie, refreshSession } from './session';

/**
 * Ensures that idToken cookie is written before redirecting to a given URL
 * @param url
 */
export const redirectWithToken = async (url: string) => {
  // Id token cookie has expired, refreshing the session..
  if (!getIdTokenCookie()) {
    const session = await refreshSession();

    // .. refresh failed
    if (!session) {
      console.error('Session refresh failed, redirect canceled');
      return;
    }
  }

  window.location.assign(url);
};
