import { IAuthConfig } from './AuthConfig';

/**
 * Jes dev authentication config
 */

const jesDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_dA49JCCUt',
  userPoolWebClientId: '4po5shff1vfe2vu22no6kf37hc',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.jes-extranet.com',
  SignicatApiUrl: 'https://sssoy.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-skinny-pig-773',
  extranetUrl: 'http://localhost:8081',
  adminServiceFrontendUrl: `http://localhost:3001`,
  maintenanceNoticeServiceUrl:`http://localhost:9300/admin/maintenancenotice`,
  magicLinkAuthUrl:
    'https://wmypnopbrj.execute-api.eu-west-1.amazonaws.com/staging/jseoy/magic-link',
};

export default jesDevAuthConfig;
