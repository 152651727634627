import { IAuthConfig } from './AuthConfig';

/**
 * Jes prod authentication config
 */

// no protocol, the base URL
const extranetDomain = 'asiakas.sssoy.fi';

const jesProdAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_S9UBEj3XL',
  userPoolWebClientId: 'eem5mn6iqfgf225ebg82v1k8l',
  identityPoolId: 'eu-west-1:596d8aee-2a3d-42ea-a216-9d55b7149bd1',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakas.jes-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://sssoy.app.signicat.com/auth/open/connect',
  SignicatApiClientId: 'prod-amiable-bridge-154',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakas.jes-extranet.com/admin/maintenancenotice`,
  magicLinkAuthUrl:
    'https://h4mkys72hi.execute-api.eu-west-1.amazonaws.com/production/jseoy/magic-link',
};

export default jesProdAuthConfig;
