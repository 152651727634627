import styled from 'styled-components';
import React from 'react';

const Label = styled.label`
  margin-bottom: 0.75em;
  font-size: var(--amplify-text-sm);
  color: var(--amplify-secondary-color);
`;

interface InputLabelProps {
  className: string;
  children: React.ReactNode;
  htmlFor?: string;
}

export default function InputLabel(props: InputLabelProps) {
  return (
    <Label htmlFor={props.htmlFor} className={props.className}>
      {props.children}
    </Label>
  );
}
