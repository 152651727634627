/**
 * This file exports the correct them object dynamically for each product version,
 * leaving the unused configs out of the build.
 */
import { EProduct } from '../config';

type CommonTexts = {
  productName: string;
  signIn: string;
  signUp: string;
  extranetButton: string;
  passwordRequirements: string;
};

type PageTexts = {
  pageTitle: string;
  headerTitle: string;
  [key: string]: any;
};

export interface ITextData {
  commonTexts: CommonTexts;
  authStateTitles: {
    [key: string]: string;
  };
  signUpInfoBox: {
    [key: string]: string;
  };
  profileSettings: PageTexts;
  changePassword: PageTexts;
  signicatUndone: PageTexts;
  deleteUser: PageTexts;
  addOrganization: PageTexts;
  homePage: PageTexts;
  verifyCustomerships: {
    found: PageTexts;
    notFound: PageTexts;
  };
  manageCustomerAccounts: PageTexts;
  launchpad: PageTexts;
}

export const TextData = (product: EProduct): ITextData => {
  try {
    return require(`./TextData.${product}`).default;
  } catch (err) {
    throw Error(`Invalid PRODUCT (${product}) specified`);
  }
};
