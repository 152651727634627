import React from 'react';
import { config } from '../config/config';
import styled from 'styled-components';

const {
  textData: { signUpInfoBox },
  product,
} = config;

type Props = {
  className?: string;
};

const InfoTextContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 2em;
`;

export const InfoBox = (props: Props) =>
  product === 'elenia' || (!signUpInfoBox.text && !signUpInfoBox.link) ? null : (
    <InfoTextContainer className={props.className}>
      <p>
        {signUpInfoBox.text}
        {!!signUpInfoBox.link && (
          <span>
            <br />
            <br />
            Lisätietoa:{' '}
            <a href={signUpInfoBox.link}>{signUpInfoBox.linkDescription}</a>
          </span>
        )}
      </p>
    </InfoTextContainer>
  );
