import { IThemeConfig } from './ThemeConfig';
import logoUrl from '../../images/jes/logo.svg';

const jesThemeConfig: IThemeConfig = {
  colors: {
    mainThemeColor1: 'rgb(70, 132, 246)', // main blue
    mainThemeColor2: 'rgb(82, 84, 86)', // main gray
    secondaryColor1: 'rgb(147, 3, 46)', // main red
    secondaryColor2: 'rgb(3, 111, 15)', // main green
    secondaryColor3: 'rgb(198, 159, 137)', // main beige
    noticesTextColor: 'rgb(255, 255, 255)',
    noticesBackgroundColor: 'rgb(70, 132, 246)',

    // Gray colors
    grayDark: 'rgb(53, 53, 53)',
    grayMedium: 'rgb(200, 200, 200)',
    grayLight: 'rgb(238, 238, 238)',
  },
  logo: {
    width: 200,
    height: 60,
    margin: 10,
    url: logoUrl,
  },
  fontSizes: {
    small: '14px',
    medium: '18px',
    large: '20px',
    extraLarge: '32px',
  },
  fontFamilies: {
    headers: 'Avenir Heavy, arial, helvetica, verdana, sans-serif',
    regular: 'Apex, arial, helvetica, verdana, sans-serif',
    buttonFont: 'Lato, arial, helvetica, verdana, sans-serif',
  },
  mobileBreakPoint: 900,
};

export default jesThemeConfig;
