import {
  AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  UI_AUTH_CHANNEL,
  TOAST_AUTH_ERROR_EVENT,
  AuthState,
  onAuthUIStateChange,
} from '@aws-amplify/ui-components';
import axios from 'axios';
import styled, { createGlobalStyle, css } from 'styled-components';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Auth, Analytics, Hub, I18n } from 'aws-amplify';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import fonts from './fonts';
import App from './App';
import { TopBar } from './components/TopBar';
import { SignUpForm } from './components/SignUpForm';
import { SignInForm } from './components/SignInForm';
import Launchpadv1 from './components/Launchpad_v1';
import Launchpad from './components/Launchpad';
import LaunchpadV3 from './components/Launchpad_v3';
import { NoticesComponent } from './components/Notices/Notices';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { PasswordChangeRequiredForm } from './components/PasswordChangeRequiredForm';
import { config } from './config/config';
import { translations } from './config/translations';
import * as serviceWorker from './serviceWorker';
import { isAdmin, request } from './helpers';
import { EUserType } from './types';
import './index.css';
import { writeIdTokenCookie } from './utils/session';
import { SignInForm as SignInFormV2 } from './components/SignInForm_v2';
import { ForgotPasswordForm as ForgotPasswordFormV2 } from './components/ForgotPasswordForm_v2';
import { SignUpForm as SignUpFormV2 } from './components/SignUpForm_v2';
import { SignInContainer } from './components/SignInContainer';
import LogoFooter from './components/LogoFooter';

const { authStateTitles, commonTexts } = config.textData;

// Auth is always defined for Cognito authentication to work
Auth.configure(config.authConfig);

// Analytics is opt-in, only defined for the product-environment combos it's
// defined for
Analytics.configure({ AWSPinpoint: config.analyticsConfig });

const GlobalStyle = createGlobalStyle`${fonts}`;

const MobileFooter = styled(LogoFooter)`
  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    display: none;
  }
`;

const DesktopFooter = styled(LogoFooter)`
  ${(props: { showSigninForm: boolean | null }) => {
    return config.product !== 'elenia'
      ? css`
          display: none;
        `
      : !props.showSigninForm
      ? css`
          display: none;
        `
      : css``;
  }}
  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  ${(props: { updatedUi: boolean | null }) => {
    return props.updatedUi
      ? css`
          flex-grow: 4;
          justify-content: space-between;
        `
      : css``;
  }}
`;
const WrapperAuthenticator = styled.div`
  ${(props: { showSigninForm: boolean | null }) => {
    return config.product !== 'elenia'
      ? css`
          display: flex;
          flex-direction: row;
        `
      : props.showSigninForm
      ? css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `
      : css`
          justify-content: flex-start;
          display: flex;
          flex-direction: column;
        `;
  }}
`;

const BackgroundGradient = styled.div`
  ${(props: { showEleniaGradient: boolean }) =>
    props.showEleniaGradient
      ? css`
          background: ${config.themeConfig.colors.backgroundGradient};
        `
      : css``}

  display: flex;
  flex-direction: column;
  top: 0;
  bottom: -59px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

const Background = styled.div`
  flex-direction: column;
  display: flex;
  top: 0;
  bottom: -59px;
  width: 100%;
  height: 100%;
  background-color: ${config.themeConfig.colors.mainThemeColor1};
  background-size: cover;

  ${(props: { showLaunchpad: boolean | null }) =>
    props.showLaunchpad
      ? css`
          background-image: url(${config.launchpadConfig.backgroundImage});
        `
      : css`
          background-image: var(--background-photo);
        `}
  min-height: 100vh;
`;

const LoggedOutText = styled.span`
  text-align: center;
  display: block;
  padding: 1em;
  background-color: white;
`;

I18n.putVocabularies(translations);
I18n.setLanguage('fi'); // TODO: maybe enable other locales in the future?

interface MagicLinkResponse {
  magicLink?: string | null;
}

export function Application() {
  const setUpEventListener = () => {
    Hub.listen(AUTH_CHANNEL, authEvent => {
      /**
       * Detect when user signs in so that we can forward them directly to
       * Extranet if they have already finished their account setup.
       * We want to do the forwarding logic only after sign in.
       */
      if (authEvent.payload.event === 'signIn') {
        checkAccountSetupState();
      }

      setMessage('');
    });

    Hub.listen(UI_AUTH_CHANNEL, event => {
      /**
       * Hide the error message when the auth state change, i.e. user goes from sign-in to forgot-password
       */
      if (event.payload.event === AUTH_STATE_CHANGE_EVENT) {
        setMessage('');
      }

      if (event.payload.event === TOAST_AUTH_ERROR_EVENT) {
        /**
         * Ugly ugly ugly hack that edits the toast style properties
         * so that it's positioned on top of the screen.
         * This allows users to see the error messages better */
        const target = document.getElementsByTagName(
          'amplify-authenticator',
        )[0];

        if (target?.shadowRoot) {
          setTimeout(() => {
            // Get the toast element
            const toastElement = target?.shadowRoot?.querySelector(
              'amplify-toast',
            ) as HTMLElement;

            if (toastElement) {
              toastElement.style.position = 'fixed';
              toastElement.style.top = '0';
              toastElement.style.right = '0';
              toastElement.style.left = '0';
              toastElement.style.zIndex = '200';
            }
          }, 300);
        }
      }

      if (event.payload.message === 'confirmSignUp') {
        setMessage(
          `Vahvista sähköposti syöttämällä vahvistuskoodi. Tämän jälkeen voit kirjautua sisään. Vahvistuskoodi lähetettiin sinulle rekisteröitymisen yhteydessä.`,
        );
      }
    });
  };

  const [redirectState, setRedirectState] = useState({
    loading: true,
    shouldForwardToExtranet: false,
  });
  const [
    automaticRedirectToExtranet,
    setAutomaticRedirectToExtranet,
  ] = useState(config.launchpadConfig.services ? false : true);

  const checkAccountSetupState = () => {
    Auth.currentAuthenticatedUser()
      .then(async user => {
        const userMetadata = await request({
          url: `${config.authConfig.lambdaApiUrl}/idm/customerMetadata`,
          params: {
            sub: user.attributes.sub,
          },
        });

        const { 'custom:user_type': userType } = user.attributes;
        const adminUser = isAdmin(user);

        /* If the user is an admin user, skip redirect check */
        if (adminUser) {
          setAutomaticRedirectToExtranet(false);
          return setRedirectState({
            shouldForwardToExtranet: false,
            loading: false,
          });
        }

        /**
         * Forward the user to the IDM UI instead of the Extranet
         * if the user hasn't finished their account setup yet.
         */
        const shouldForwardToExtranet =
          userType === EUserType.Person
            ? !!userMetadata.data.ssn_update_time &&
              userMetadata.data.customerships_verified
            : userMetadata.data.customer_ids.length > 0;

        if (!automaticRedirectToExtranet) {
          return setRedirectState({
            shouldForwardToExtranet: false,
            loading: false,
          });
        }

        if (!shouldForwardToExtranet) {
          // Account setup incomplete. Disable automatic redirect altogether.
          setAutomaticRedirectToExtranet(false);
        }

        setRedirectState({
          shouldForwardToExtranet,
          loading: false,
        });
      })
      .catch(err => {
        console.log('No signed in user', err); //FIXME:
      });
  };

  const [message, setMessage] = useState('');
  const [authState, setAuthState] = useState('');
  const [user, setUser] = useState<any>();
  const [showLaunchpad, setShowLaunchpad] = useState(
    config.launchpadConfig.services && window.location.pathname === '/',
  );
  const [fetchingMagicLink, setFetchingMagicLink] = useState(false);

  setUpEventListener();
  document.title = authStateTitles[authState] || commonTexts.productName;

  const displayMessage = message.length > 0;

  onAuthUIStateChange((nextAuthState, authData: any) => {
    setAuthState(nextAuthState);
    setUser(authData);

    if (!authData) return;

    const {
      signInUserSession: { idToken },
    } = authData;

    // Write/update JWT token cookie on AuthUIStateChange. External apps (frontend/admin) will handle the cookie deletion
    writeIdTokenCookie(idToken);
  });

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const isFromExtranet = !!params.fromExtranet;

  if (isFromExtranet && showLaunchpad) {
    setShowLaunchpad(false);
  }

  if (isFromExtranet && automaticRedirectToExtranet) {
    // User has navigated into IDM from extranet -> disable automatic redirect back to extranet
    setAutomaticRedirectToExtranet(false);
  }

  if (authState === AuthState.SignedIn && automaticRedirectToExtranet) {
    // Update redirect state in order to decide whether we should
    // redirect user automatically to extranet.
    checkAccountSetupState();
  }

  // Forward to extranet if all conditions match and we are not in AuthState.VerifyContact state
  if (
    redirectState.shouldForwardToExtranet &&
    authState !== AuthState.VerifyContact
  ) {
    if (config.launchpadConfig.services) {
      if (!showLaunchpad) {
        setShowLaunchpad(true);
      }
    } else {
      // Redirect to extranet
      window.location.assign(`${config.authConfig.extranetUrl}/kirjaudu`);
    }
  }

  const loggedOut =
    window.location.pathname === '/kirjaudu-ulos' &&
    authState === AuthState.SignIn;
  if (loggedOut) {
    window.location.assign('/');
  }

  const onSelectApplication = async (appId: string) => {
    const token = user?.signInUserSession?.idToken?.jwtToken;

    // Admin users should land into IDM instead of going to extranet
    if (appId === 'extranet' && isAdmin(user)) {
      setShowLaunchpad(false);
      return;
    }

    const appConfig = config?.launchpadConfig?.services?.find(
      config => config.id === appId,
    );

    if (appConfig) {
      const { magicLinkId } = appConfig;
      const { magicLinkAuthUrl } = config.authConfig;

      if (magicLinkId && magicLinkAuthUrl && token) {
        // Magic link has been enabled for selected app. Call Akamon API to obtain link
        // and then redirect.
        setFetchingMagicLink(true);

        await axios
          .get<MagicLinkResponse>(magicLinkAuthUrl, {
            params: {
              redirectPath: magicLinkId,
            },
            headers: {
              Authorization: token, // without bearer
            },
          })
          .then(resp => {
            const { data } = resp;
            const { magicLink } = data || {};

            if (magicLink) {
              // Redirect into magic link address
              window.location.href = magicLink;
              return;
            } else {
              throw new Error('Magic link not found');
            }
          })
          .catch(err => {
            console.error('Error while fetching magic link', err);
            setFetchingMagicLink(false);
          });

        return;
      }

      // Redirect into app link
      window.location.assign(appConfig.url);
    }
  };

  const getInitialAuthState = () => {
    /*
     * The authenticator allows switching between SignUp and SignIn without
     * the need for different routing. However, we can do this: checking the
     * url path name, and displaying the SignUp as default when navigating to
     * '/rekisteroidy', allowing direct linking to registration as asked
     * by the client.
     */
    return window.location.pathname === '/rekisteroidy'
      ? AuthState.SignUp
      : AuthState.SignIn;
  };
  const initialAuthState = getInitialAuthState();

  const LaunchpadComponent =
    config.launchpadConfig.version === 2
      ? Launchpad
      : config.launchpadConfig.version === 3
      ? LaunchpadV3
      : Launchpadv1;

  return (
    <React.StrictMode>
      <GlobalStyle />
      <Background
        showLaunchpad={
          showLaunchpad &&
          config.launchpadConfig.backgroundImage &&
          authState === AuthState.SignedIn
        }
      >
        <BackgroundGradient showEleniaGradient={config.product === 'elenia'}>
          <NoticesComponent
            onLaunchpad={
              (authState === AuthState.SignedIn && user && showLaunchpad) ??
              false
            }
          />
          {(authState !== AuthState.SignedIn || !user) &&
            config.product !== 'elenia' && (
              <TopBar signedIn={false} showExtranetButton={false} />
            )}
          {loggedOut ? (
            <LoggedOutText>
              Sinut uudelleenohjataan automaattisesti takaisin etusivulle.
              <br />
              Jos uudelleenohjaus ei jostain syystä toimi muutaman sekunnin
              kuluessa,{' '}
              <a href={'/'}>
                voit siirtyä takaisin etusivulle manuaalisesti painamalla tästä
              </a>
              .
            </LoggedOutText>
          ) : (
            <WrapperAuthenticator
              showSigninForm={authState !== AuthState.SignedIn || !user}
              className="wrapper__authenticator-and-app"
            >
              {displayMessage && (
                <div className="wrapper__messages">
                  <p>{message}</p>
                </div>
              )}
              {authState === AuthState.SignedIn && user ? (
                <Content
                  updatedUi={
                    config.product === 'elenia' &&
                    config.launchpadConfig.version === 3
                  }
                >
                  {showLaunchpad ? (
                    <>
                      <LaunchpadComponent
                        services={config.launchpadConfig.services || []}
                        onSelectApplication={onSelectApplication}
                        fetchingMagicLink={fetchingMagicLink}
                      />
                      {config.product === 'elenia' &&
                      config.launchpadConfig.version === 3 ? (
                        <LogoFooter />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <App
                      forwardsToExtranet={redirectState.shouldForwardToExtranet}
                    />
                  )}

                  {/* <AmplifySignOut /> */}
                </Content>
              ) : config.product === 'elenia' ? (
                <SignInContainer>
                  <AmplifyAuthenticator
                    className="custom-authenticator"
                    usernameAlias="email"
                    initialAuthState={initialAuthState}
                  >
                    <SignUpFormV2 />
                    <SignInFormV2 />
                    <ForgotPasswordFormV2 />
                    <PasswordChangeRequiredForm user={user} />
                  </AmplifyAuthenticator>
                  {config.product === 'elenia' &&
                  config.launchpadConfig.version === 3 ? (
                    <MobileFooter className="mobile-footer" />
                  ) : (
                    <></>
                  )}
                </SignInContainer>
              ) : (
                <AmplifyAuthenticator
                  className="custom-authenticator"
                  usernameAlias="email"
                  initialAuthState={initialAuthState}
                >
                  <SignUpForm />
                  <SignInForm />
                  <ForgotPasswordForm />
                  <PasswordChangeRequiredForm user={user} />
                </AmplifyAuthenticator>
              )}

              {config.product === 'elenia' &&
              config.launchpadConfig.version === 3 ? (
                <DesktopFooter
                  showSigninForm={authState !== AuthState.SignedIn || !user}
                  className="desktop-footer"
                />
              ) : (
                <></>
              )}
            </WrapperAuthenticator>
          )}
        </BackgroundGradient>
      </Background>
    </React.StrictMode>
  );
}

ReactDOM.render(<Application />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
