import React from 'react';
import styled from 'styled-components';
import { Page } from '../components/Page';

const ContentWrapper = styled.div`
  height: 60vh;
  align-items: center;
  justify-content: center;
  display: flex;
`;

type Props = {
  msg: string;
};

class ErrorPage extends React.Component<Props, {}> {
  render() {
    return (
      <Page showExtranetButton={false}>
        <ContentWrapper>
          <p>{this.props.msg}</p>
        </ContentWrapper>
      </Page>
    );
  }
}

export default ErrorPage;
