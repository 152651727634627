import React from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '../Spinner';
import { config } from '../../config/config';
import { LaunchpadItem } from '../../types';
import CookieDeclarationModal from '../CookieDeclarationModal';

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  margin: auto;
  background: rgba(255, 255, 255, 0.5);
  width: 90vw;
  padding: 20px;
  border-radius: 3px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ServiceItemContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  margin: 20px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  background: white;
`;

const ServiceItemImageContainer = styled.div`
  flex: 0 0 140px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ServiceItemImage = styled.div`
  ${(props: { url: string, height: string, width: string }) => {
    return css`
      height: ${props.height}px;
      width: ${props.width}px;
      background: 100% 100% url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
    `;
  }}
`;

const ServiceItemTitle = styled.div`
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-size: ${config.themeConfig.fontSizes.medium};
  color: black;
`;

const Title = styled.h1`
  text-align: center;
  padding-bottom: 30px;
  font-weight: 600;
`;

const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SignOutButton = styled.button`
  color: ${config.themeConfig.launchpad?.signOutButtonText};
  background: ${config.themeConfig.launchpad?.signOutButtonBackground};
  border: 0;
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: 3px;
  font: inherit;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 15px;

  &:focus,
  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
`;

const CookieDeclarationButton = styled(SignOutButton)`
  margin-left: 1em;
`;

type Props = {
  services: LaunchpadItem[];
  onSelectApplication: Function;
  fetchingMagicLink: boolean;
};

const Launchpad = (props: Props) => {
  const [cookieDeclarationOpen, setCookieDeclaration] = React.useState(false);

  if (props.fetchingMagicLink) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <Overlay>
      <ContentContainer>
        <Title>Voit nyt siirtyä haluamaasi palveluun</Title>

        <ServicesContainer>
          {props.services.map(c => {
            return (
              <ServiceItemContainer
                key={c.id}
                onClick={() => props.onSelectApplication(c.id)}
              >
                <ServiceItemImageContainer>
                  {c.image && (
                    <ServiceItemImage
                      url={c.image}
                      width={c.width ? `${c.width}` : '120'}
                      height={c.height ? `${c.height}` : '100'}
                    />
                  )}
                </ServiceItemImageContainer>
                <ServiceItemTitle>{c.title}</ServiceItemTitle>
              </ServiceItemContainer>
            );
          })}
        </ServicesContainer>
        <Buttons>
          <SignOutButton
            onClick={() =>
              window.location.assign(
                `${config.authConfig.extranetUrl}/kirjaudu-ulos`,
              )
            }
          >
            Kirjaudu ulos
          </SignOutButton>
          <CookieDeclarationButton
            onClick={() => setCookieDeclaration(true) }
          >
            Evästeasetukset
          </CookieDeclarationButton>
        </Buttons>
      </ContentContainer>
      <CookieDeclarationModal
        cookieDeclarationOpen={cookieDeclarationOpen}
        setCookieDeclaration={setCookieDeclaration}
      />
    </Overlay>
  );
}

export default Launchpad;
