import { IAuthConfig } from './AuthConfig';

/**
 * Loimua dev authentication config
 */

const loimuaDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_xMZGdNdRt',
  userPoolWebClientId: '49vhbr17dm7vprmufbks4v00vb',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.loimua-extranet.com',
  SignicatApiUrl: 'https://loimua.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-lazy-screw-415',
  extranetUrl: 'http://localhost:8083',
  adminServiceFrontendUrl: `http://localhost:3003`,
  maintenanceNoticeServiceUrl:`http://localhost:9300/admin/maintenancenotice`,
};

export default loimuaDevAuthConfig;
