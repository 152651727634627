import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import {
  CommonSubSection,
  CommonSubheader,
  CommonLabel,
  CommonInput,
  CommonColumns,
  CommonMessage,
  CommonDescription,
} from '../../components/CommonStyles';
import {
  DeleteButton,
  FoundCustomerIDs,
  ResultRow,
  SearchResultContainer,
  StyledButtons,
  StyledForm,
  StyledFormItem,
  PasswordInputWrapper,
  PasswordForm,
  UserListItem,
  UserListDescription,
} from './styles';
import { Button, Link, SubmitButton } from '../../components/Button';
import { Spinner } from '../../components/Spinner';
import { config } from '../../config/config';
import {
  customerIdToCustomerNumber,
  customerNumberToCustomerId,
  request,
} from '../../helpers';
import { CustomerType } from '../../types';

const SubResultContainer = styled.div`
  width: 100%;
  flex: 1;
  min-width: 250px;
  margin-bottom: 0.5em;
`;

const SmallText = styled.p`
  font-size: 12px;
`;

type Props = {
  displaySection: (
    section: string,
    sub?: string,
    userType?: CustomerType,
  ) => void;
  toggleReload: (value: boolean) => void;
  shouldReload: boolean;
};

type AccountStatus =
  | 'UNCONFIRMED' // User has been created but not confirmed.
  | 'CONFIRMED' // User has been confirmed.
  | 'ARCHIVED' // User is no longer active.
  | 'COMPROMISED' // User is disabled due to a potential security threat.
  | 'UNKNOWN' // User status is not known.
  | 'RESET_REQUIRED' // User is confirmed, but the user must request a code and reset his or her password before he or she can sign in.
  | 'FORCE_CHANGE_PASSWORD'; // The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change his or her password to a new value before doing anything else.

enum accountStatusMessages {
  UNCONFIRMED = 'Ei vahvistettu',
  CONFIRMED = 'Vahvistettu',
  ARCHIVED = 'Poistettu käytöstä',
  COMPROMISED = 'Suljettu',
  UNKNOWN = 'Ei tiedossa',
  RESET_REQUIRED = 'Salasana resetoitava.',
  FORCE_CHANGE_PASSWORD = 'Salasana muutettava kirjautumisen yhteydessä',
}

/**
 * Information about the user account returned by the lambda
 */
type Account = {
  sub: string;
  email: string;
  name: string;
  type: string;
  status: AccountStatus;
  isStronglyAuthenticated: boolean;
  linked_customer_ids: string[];
  linked_business_ids: string[];
  ignored_customer_ids: string[];
};

type WildcardSearchItem = {
  name: string;
  email: string;
};

type State = {
  deleting: boolean;
  deletingId: string;
  emailToSearch: string;
  error: boolean;
  msg: string;
  searchingUserAccount: boolean;
  userAccountResult: Account | null;
  isChangingPassword: boolean;
  password: string;
  wildcardResults: Array<WildcardSearchItem>;
};

export class AdminFindAccount extends React.Component<Props, State> {
  state: State = {
    deleting: false,
    deletingId: '',
    emailToSearch: '',
    error: false,
    msg: '',
    searchingUserAccount: false,
    userAccountResult: null,
    isChangingPassword: false,
    password: '',
    wildcardResults: [],
  };

  componentDidUpdate = () => {
    if (this.props.shouldReload) {
      this.fetchUserAccount(this.state.emailToSearch);
    }
  };

  submitPassword = async (event: any) => {
    event.preventDefault();

    const { userAccountResult, password } = this.state;
    const { email } = userAccountResult || {};

    const result = window.confirm(
      `Oletko varma että haluat nollata käyttäjän ${email} salasanan?`,
    );

    if (result) {
      try {
        await request({
          url: `${config.authConfig.lambdaApiUrl}/idm/admin/resetPassword`,
          method: 'POST',
          params: {
            email,
            password,
            adminRequired: true,
          },
        });

        // Hide any extra sections that might be open right now.
        this.props.displaySection('');

        this.setState({
          error: false,
          msg: `Uusi salasana käyttäjätunnukselle ${email} asetettu.`,
          userAccountResult: null,
          password: '',
          isChangingPassword: false,
        });
      } catch (err) {
        if (err.statusCode === 500) {
          return  this.setState({
            error: true,
            msg: 'Salasana ei ole riittävän vahva.',
          });
        }

        this.setState({
          error: true,
          msg:
            'Salasanan asetus epäonnistui. Yritä myöhemmin uudelleen.',
        });
      }
    }
  }
  submitSearchForm = async (event: any) => {
    event.preventDefault();

    const { emailToSearch } = this.state;

    if (emailToSearch.endsWith('*')) {
      // Wildcard character supplied -> get a list of Cognito users whose
      // email address matches the criteria.
      this.setState({
        searchingUserAccount: true,
        wildcardResults: [],
        userAccountResult: null,
      });

      try {
        const userList = await request({
          url: `${config.authConfig.lambdaApiUrl}/idm/admin/findUsers`,
          method: 'GET',
          params: {
            email: emailToSearch.slice(0, -1),
            adminRequired: true,
          },
        });

        this.setState({
          wildcardResults: userList.data,
          searchingUserAccount: false,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        searchingUserAccount: true,
        error: false,
        msg: '',
        isChangingPassword: false,
      });
  
      this.fetchUserAccount(emailToSearch);
    }
  };

  fetchUserAccount = async (email: string) => {
    this.props.toggleReload(false);

    try {
      if (email.length === 0) {
        throw new Error('Käyttäjätunnusta ei syötetty.');
      }

      const result = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/findAccount`,
        params: {
          email,
          adminRequired: true,
        },
      });

      this.setState({
        error: false,
        searchingUserAccount: false,
        msg: '',
        userAccountResult: result.data,
      });
    } catch (err) {
      if (err.statusCode === 404) {
        this.setState({
          error: true,
          searchingUserAccount: false,
          msg: 'Käyttäjätiliä ei löytynyt.',
        });
        return;
      }

      this.setState({
        error: true,
        searchingUserAccount: false,
        msg: 'Haussa tapahtui virhe.',
      });
    }
  };

  handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    const { name, value } = event.target;

    // Hide any extra sections that might be open right now.
    this.props.displaySection('');

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      error: false,
      msg: '',
      userAccountResult: null,
    }));
  };

  handlePasswordChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();

    const { value } = event.target;

    this.setState({
      password: value,
    });
  };

  openAddCustomerIdForm = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { userAccountResult } = this.state;

    if (!userAccountResult || !userAccountResult.sub) {
      return;
    }

    const userType =
      userAccountResult.type === 'Yrityskäyttäjä'
        ? CustomerType.Organisation
        : CustomerType.Person;

    this.props.displaySection('addToAccount', userAccountResult.sub, userType);
  };

  deleteCustomership = async (customerNumber: string) => {
    const customerId = customerNumberToCustomerId(customerNumber);

    const { userAccountResult, emailToSearch } = this.state;

    if (!userAccountResult || !userAccountResult.sub) {
      return;
    }

    this.setState({
      deleting: true,
      deletingId: customerNumber,
    });

    try {
      await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/admin/removeFromAccount`,
        method: 'POST',
        params: {
          modifiedUserSub: userAccountResult.sub,
          customerIdToRemove: customerId,
          adminRequired: true,
        },
      });

      this.fetchUserAccount(emailToSearch);
      this.setState({
        deleting: false,
        deletingId: '',
      });
    } catch (err) {
      this.setState({
        deleting: false,
        deletingId: '',
      });
    }
  };

  deleteUser = async (email: string) => {
    const result = window.confirm(
      `Oletko varma että haluat poistaa käyttäjätunnuksen? ${email}. Poistamisen jälkeen käyttäjä joutuu rekisteröitymään palveluun uudelleen.`,
    );

    if (result) {
      try {
        await request({
          url: `${config.authConfig.lambdaApiUrl}/idm/admin/deleteCognitoAccount`,
          method: 'POST',
          params: {
            deletedUsername: email,
            adminRequired: true,
          },
        });

        // Hide any extra sections that might be open right now.
        this.props.displaySection('');

        this.setState({
          msg: `Käyttäjätunnus ${email} poistettu.`,
          userAccountResult: null,
        });
      } catch (err) {
        this.setState({
          error: true,
          msg:
            'Käyttäjätunnuksen poistaminen epäonnistui. Yritä myöhemmin uudelleen.',
        });
      }
    }
  };

  renderRows = (customerIds: string[], header: string) => {
    if (customerIds.length === 0) {
      return null;
    }

    const rows = customerIds.map((value: string) => {
      const customerNumber = customerIdToCustomerNumber(value);

      return (
        <ResultRow key={value}>
          <p>{customerNumber}</p>
          <DeleteButton
            onClick={this.deleteCustomership.bind(this, customerNumber)}
          >
            {this.state.deleting && this.state.deletingId === customerNumber ? (
              <Spinner dark />
            ) : (
              'Poista'
            )}
          </DeleteButton>
        </ResultRow>
      );
    });

    return (
      <SubResultContainer>
        <strong>{header}</strong>
        <div>{rows}</div>
      </SubResultContainer>
    );
  };

  renderSearchResults = (userAccountResult: Account) => {
    const {
      email,
      ignored_customer_ids,
      linked_business_ids,
      linked_customer_ids,
      name,
      type,
      status,
      isStronglyAuthenticated,
    } = userAccountResult;

    const linkedCustomerIds = this.renderRows(
      linked_customer_ids,
      'Liitetyt asiakasnumerot',
    );
    const ignoredCustomerIds = this.renderRows(
      ignored_customer_ids,
      'Irrotetut asiakasnumerot',
    );

    const strongAuthMessage = `, vahva tunnistautuminen ${
      isStronglyAuthenticated ? 'tehty' : 'tekemättä'
    }`;

    const notAllowedToModify =
      status === 'UNCONFIRMED' ||
      (type === 'Yksityiskäyttäjä' && !isStronglyAuthenticated);

    return (
      <SearchResultContainer>
        <h3>Haettu käyttäjä</h3>
        <p>
          <strong>Nimi:</strong> {name}
        </p>

        <p>
          <strong>Käyttäjätunnus:</strong> {email} ({type})
        </p>

        <p>
          <strong>Käyttäjätunnuksen tila:</strong>{' '}
          {accountStatusMessages[status]}
          {type === 'Yksityiskäyttäjä' && strongAuthMessage}
        </p>

        {linked_business_ids.length > 0 && (
          <p>
            <strong>Liitetyt y-tunnukset:</strong> {linked_business_ids}
          </p>
        )}

        <FoundCustomerIDs>
          {linkedCustomerIds}

          {ignoredCustomerIds}
          <br />
          {(linkedCustomerIds || ignoredCustomerIds) && (
            <SmallText>
              Jos asiakasnumero on liitetty käyttäjätiliin automaattisesti
              hetun/y-tunnuksen avulla, asiakasnumeron poistaminen täytyy
              suorittaa suoraan asiakastietojärjestelmään.
            </SmallText>
          )}
        </FoundCustomerIDs>

        <StyledButtons>
          <Button light pink onClick={() => this.setState({ isChangingPassword: true })}>
            Vaihda salasana
          </Button>
          <Button light pink onClick={this.deleteUser.bind(this, email)}>
            Poista käyttäjätili
          </Button>
          <Button
            light
            disabled={notAllowedToModify}
            onClick={this.openAddCustomerIdForm}
          >
            Lisää asiakasnumero
          </Button>
        </StyledButtons>

        
      </SearchResultContainer>
    );
  };

  renderPasswordChangeForm() {
    const { userAccountResult, password } = this.state;
    const { email } = userAccountResult || {};

    return (
      <SearchResultContainer>
        <PasswordForm onSubmit={this.submitPassword}>
          <h3>Käyttäjän salasanan vaihtaminen</h3>
          <div>
            Vaihda käyttäjälle <strong>{email}</strong> uusi väliaikainen
            salasana, joka käyttäjän on vaihdettava kirjautuessaan seuraavan
            kerran järjestelmään.
          </div>

          <PasswordInputWrapper>
            <CommonLabel htmlFor="password">Uusi salasana</CommonLabel>
            <CommonInput
              type="text"
              name="password"
              id="password"
              value={password}
              onChange={this.handlePasswordChange}
            />
          </PasswordInputWrapper>

          <StyledButtons>
            <Button
              light
              onClick={() =>
                this.setState({
                  isChangingPassword: false,
                  password: ' ',
                })
              }
            >
              Takaisin
            </Button>
            <SubmitButton disabled={password.length === 0}>
              Aseta uusi salasana
            </SubmitButton>
          </StyledButtons>
        </PasswordForm>
      </SearchResultContainer>
    );
  }

  renderUserSelection() {
    const { wildcardResults } = this.state;

    return (
      <SearchResultContainer>
        <h3>Haun tulokset</h3>
        <UserListDescription>
          Näytetään enintään 30 hakutulosta.
        </UserListDescription>
        <ul>
          {wildcardResults.map(item => {
            return (
              <UserListItem onClick={() => {
                this.setState({
                  searchingUserAccount: true,
                  error: false,
                  msg: '',
                  isChangingPassword: false,
                  wildcardResults: [],
                });
            
                this.fetchUserAccount(item.email);
              }}>{item.name} ({item.email})</UserListItem>
            )
          })}
        </ul>
      </SearchResultContainer>
    );
  }

  isSearchButtonDisabled() {
    const { emailToSearch } = this.state;

    if (emailToSearch === '*') {
      // Only wildcard character supplied
      return true;
    } else {
      return emailToSearch.length === 0;
    }
  }

  render() {
    const {
      emailToSearch,
      error,
      msg,
      searchingUserAccount,
      userAccountResult,
      isChangingPassword,
      wildcardResults,
    } = this.state;

    return (
      <CommonSubSection>
        <CommonSubheader>Hae käyttäjätilin tiedot</CommonSubheader>
        <CommonDescription>
          Voit etsiä käyttäjää myös osittaisella sähköpostiosoitteella lisäämällä hakuehdon loppuun *-merkin.
        </CommonDescription>
        <CommonColumns>
          <StyledForm onSubmit={this.submitSearchForm}>
            <StyledFormItem>
              <CommonLabel htmlFor="emailToSearch">
                Käyttäjätunnus (sähköpostiosoite)
              </CommonLabel>
              <CommonInput
                type="text"
                name="emailToSearch"
                id="emailToSearch"
                onChange={this.handleInputChange}
                value={emailToSearch}
              />
            </StyledFormItem>

            <StyledButtons>
              <SubmitButton disabled={this.isSearchButtonDisabled()}>
                {searchingUserAccount ? <Spinner /> : 'Hae'}
              </SubmitButton>
              <Link light to={'/'}>
                Takaisin
              </Link>
            </StyledButtons>

            <CommonMessage error={error}>{msg}</CommonMessage>
          </StyledForm>
          {userAccountResult &&
            !isChangingPassword &&
            this.renderSearchResults(userAccountResult)}
          {userAccountResult && isChangingPassword && this.renderPasswordChangeForm()}
          {wildcardResults.length > 0 && this.renderUserSelection()}
        </CommonColumns>
      </CommonSubSection>
    );
  }
}
