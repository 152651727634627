import {
    AUTH_STATE_CHANGE_EVENT,
    AuthState,
    TOAST_AUTH_ERROR_EVENT,
    UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components';
import { Auth, Hub } from 'aws-amplify';
interface Fields {
  code: string;
  email: string;
  password: string;
}

export default async function submitPasswordResetRequest(
  isCodeSent: boolean,
  setIsCodeSent: (newValue: boolean) => void,
  fields: Fields,
) {
  if (!isCodeSent) {
    // Request confirmation code
    Auth.forgotPassword(fields.email)
      .then(() => {
        setIsCodeSent(true);
      })
      .catch(() => {
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: TOAST_AUTH_ERROR_EVENT,
          message: 'Vahvistuskoodin lähetys epäonnistui.',
        });
      });
  } else {
    // Submit new password
    Auth.forgotPasswordSubmit(fields.email, fields.code, fields.password)
      .then(() => {
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: AUTH_STATE_CHANGE_EVENT,
          message: AuthState.SignIn,
          data: { username: fields.email.toLowerCase() },
        });
      })
      .catch(() => {
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: TOAST_AUTH_ERROR_EVENT,
          message: 'Salasanan asettaminen epäonnistui.',
        });
      });
  }
}
