import { IAuthConfig } from './AuthConfig';

/**
 * Auris dev authentication config
 */

const aurisDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_6gQ2t3gxs',
  userPoolWebClientId: '2m46qhc3gnbheugs5suf6avq19',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: 'https://api.asiakasdev.auris-extranet.com',
  SignicatApiUrl: 'https://auris.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-spicy-fork-778',
  extranetUrl: 'http://localhost:8084',
  adminServiceFrontendUrl: `http://localhost:3004`,
  maintenanceNoticeServiceUrl:`http://localhost:9300/admin/maintenancenotice`,
};

export default aurisDevAuthConfig;
