/**
 * This file exports the correct launchpad configuration object dynamically for each product version,
 * leaving the unused configs out of the build.
 */
import { EProduct, EEnvironment } from '../config';
import { LaunchpadItem } from '../../types';

export interface ILaunchpadConfig {
  version?: number;
  backgroundImage?: any;
  amountOfColumns?: number;
  services: LaunchpadItem[] | null;
}

export const LaunchpadConfig = (
  product: EProduct,
  environmentType: EEnvironment,
): ILaunchpadConfig => {
  try {
    return require(`./Launchpad.${product}.${environmentType}`).default;
  } catch (err) {
    console.log(err);
    throw Error(
      `Invalid PRODUCT (${product}) and/or ENVIRONMENT_TYPE (${environmentType}) specified`,
    );
  }
};
