import React from 'react';
import { Page } from '../../components/Page';
import { PageHeader } from '../../components/PageHeader';
import { AdminFindAccount } from './AdminFindAccount';
import { AdminAddToAccount } from './AdminAddToAccount';
import { CommonSection } from '../../components/CommonStyles';
import { config } from '../../config/config';
import { CustomerType } from '../../types';
const { manageCustomerAccounts: texts } = config.textData;

type State = {
  showSection: string;
  shouldReload: boolean;
  sub: string;
  userType: CustomerType | null;
};

class AdminManageAccounts extends React.Component<{}, State> {
  state: State = {
    showSection: '',
    shouldReload: false,
    sub: '',
    userType: null,
  };

  componentDidMount = async () => {
    document.title = texts.pageTitle;
  };

  toggleReload = (value: boolean) => {
    this.setState({
      shouldReload: value,
    });
  };

  displaySection = (section: string, sub?: string, userType?: CustomerType) => {
    this.setState(prevState => ({
      ...prevState,
      userType: userType || null,
      sub: sub || '',
      showSection: section,
    }));
  };

  render() {
    return (
      <Page>
        <PageHeader text={texts.headerTitle} />
        <CommonSection>
          <AdminFindAccount
            toggleReload={this.toggleReload}
            displaySection={this.displaySection}
            shouldReload={this.state.shouldReload}
          />

          {this.state.showSection === 'addToAccount' && (
            <AdminAddToAccount
              hideSection={this.displaySection.bind(this, '')}
              modifiedUserSub={this.state.sub}
              modifiedUserType={this.state.userType}
              toggleReload={this.toggleReload}
            />
          )}
        </CommonSection>
      </Page>
    );
  }
}

export default AdminManageAccounts;
