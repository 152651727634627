import { Auth } from 'aws-amplify';
import { instanceOf } from 'prop-types';
import React from 'react';
import {
  Cookies,
  CookiesProvider,
  ReactCookieProps,
  withCookies,
} from 'react-cookie';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SignicatRedirect from './components/Signicat';
import NeedsStrongAuth from './components/NeedsStrongAuth';
import LogOut from './components/LogOut';
import ToExtranet from './components/ToExtranet';
import {
  AddCustomershipToOrganization,
  AdminManageAccounts,
  ChangePassword,
  DashboardPage,
  DeleteUser,
  ErrorPage,
  LoadingPage,
  ProfileOverview,
  ProfileSettings,
} from './containers';
import { config } from './config/config';
import { EUserType } from './types';
import { isAdmin, isAllowedToSeeStatistics, request } from './helpers';

type State = {
  loading: boolean;
  type: EUserType;
  ssn: string;
  isAdmin: boolean;
  showStatistics: boolean;
  ssn_update_time: string;
  isStronglyAuthenticated: boolean;
  shouldCheckStrongAuth: boolean;
  customerIdsVerified: boolean;
  firstUsageDone: boolean;
  error: boolean;
  msg: string;
};

type Props = {
  forwardsToExtranet: boolean;
};

type CombinedProps = ReactCookieProps & Props;

class App extends React.Component<CombinedProps, State> {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  state: State = {
    loading: true,
    type: EUserType.Person,
    ssn: '',
    ssn_update_time: '',
    isAdmin: false,
    showStatistics: false,
    isStronglyAuthenticated: false,
    shouldCheckStrongAuth: false,
    customerIdsVerified: false,
    firstUsageDone: false,
    error: false,
    msg: '',
  };

  componentDidMount = async () => {
    try {
      const user: any = await Auth.currentAuthenticatedUser();
      const { 'custom:user_type': type } = user.attributes;
      const adminUser = isAdmin(user);
      const showStatistics = isAllowedToSeeStatistics(user);

      /* Do this to figure out if the user account still exist 
        and if the user has (yet) strongly authenticated */
      const userMetadata = await request({
        url: `${config.authConfig.lambdaApiUrl}/idm/customerMetadata`,
        params: {
          sub: user.attributes.sub,
        },
      });

      if (type === EUserType.Person) {
        this.setState({
          type,
          shouldCheckStrongAuth: !adminUser,
          isStronglyAuthenticated: !!userMetadata.data.ssn_update_time,
          customerIdsVerified: userMetadata.data.customerships_verified,
          isAdmin: adminUser,
          showStatistics,
          loading: false,
          error: false,
        });
      } else {
        /** Business user, only type is needed at this point */
        this.setState({
          type,
          shouldCheckStrongAuth: false, // setting this implicitly just in case
          loading: false,
          error: false,
        });
      }
    } catch (err) {
      if (err.statusCode === 403) {
        this.setState({
          loading: false,
          error: true,
          msg: 'Käyttäjätunnus poistettu. Ole hyvä ja kirjaudu ulos.',
        });
      } else {
        this.setState({
          loading: false,
          error: true,
          msg: 'Virhe palvelussa. Yritä myöhemmin uudestaan.',
        });
      }
    }
  };

  render() {
    const {
      loading,
      shouldCheckStrongAuth,
      isStronglyAuthenticated,
      isAdmin,
      type,
      msg,
      customerIdsVerified,
      error,
      showStatistics,
    } = this.state;

    const shouldRedirectToSignicat =
      !loading && shouldCheckStrongAuth && !isStronglyAuthenticated;

    const shouldDisplayCustomerShips =
      !loading &&
      type === EUserType.Person &&
      !isAdmin &&
      !shouldRedirectToSignicat &&
      !customerIdsVerified;

    if (loading) {
      return (
        <Router>
          <Switch>
            <Route path="/kirjaudu-ulos">
              <LogOut />
            </Route>
            <Route path="/">
              <LoadingPage />
            </Route>
          </Switch>
        </Router>
      );
    }

    if (!loading && error && !this.props.forwardsToExtranet) {
      return (
        <Router>
          <Switch>
            <Route path="/kirjaudu-ulos">
              <LogOut />
            </Route>
            <Route path="/">
              <ErrorPage msg={msg} />
            </Route>
          </Switch>
        </Router>
      );
    }

    /* Allow the user to only navigate to pages related to strong auth */
    if (shouldRedirectToSignicat) {
      return (
        <CookiesProvider>
          <Router>
            <Switch>
              <Route path="/kirjaudu-ulos">
                <LogOut />
              </Route>
              <Route
                path="/redirect"
                render={props => (
                  <SignicatRedirect {...props} cookies={this.props.cookies} />
                )}
              />
              <Route path="/">
                <NeedsStrongAuth />
              </Route>
            </Switch>
          </Router>
        </CookiesProvider>
      );
    }

    if (shouldDisplayCustomerShips) {
      return (
        <Router>
          <Switch>
            <Route path="/kirjaudu-ulos">
              <LogOut />
            </Route>
            <Route path="/">
              <ProfileOverview verifyCustomerships={true} />
            </Route>
          </Switch>
        </Router>
      );
    }

    return (
      <Router>
        <Switch>
          <Route path="/kirjaudu-ulos">
            <LogOut />
          </Route>

          <Route path="/extranet">
            <ToExtranet />
          </Route>

          <Route path="/asetukset">
            <ProfileSettings />
          </Route>

          <Route path="/salasana">
            <ChangePassword />
          </Route>

          {/* Only organization users should be able to access this page */}
          {type === EUserType.Organization && (
            <Route path="/lisää">
              <AddCustomershipToOrganization />
            </Route>
          )}

          {isAdmin && (
            <Route path="/käyttäjätilien-hallinta">
              <AdminManageAccounts />
            </Route>
          )}
          {showStatistics && (
            <Route path="/dashboard">
              <DashboardPage />
            </Route>
          )}

          <Route path="/poista">
            <DeleteUser />
          </Route>

          <Route path="/">
            <ProfileOverview verifyCustomerships={false} />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withCookies(App);
