import { Auth, Hub } from 'aws-amplify';
import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  TOAST_AUTH_ERROR_EVENT,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components';
import { translations } from '../../config/translations';

type TFields = {
  email: string;
  password: string;
};

export default async function signIn(fields: TFields) {
  try {
    const authResult = await Auth.signIn(fields.email, fields.password);

    /**
     * Admin users are created with temporary passwords, so their accounts are in the state
     * "FORCE_CHANGE_PASSWORD" in Cognito, and authResult has
     *   {
     *     challengeName: "NEW_PASSWORD_REQUIRED"
     *   }
     * In this case we need to manually dispatch the auth state event that changes the state
     * to AuthState.ResetPassword, passing the CognitoUser type result from Auth.signIn() as
     * the data parameter. Then Amplify can render the default element, i.e. the form for
     * giving the new password, and in this case also family_name and given_name.
     */
    if (authResult?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.ResetPassword,
        data: authResult,
      });
    }
  } catch (error) {
    /**
     * With this custom sign in form we must manually handle situations
     * where the user tries to log in but isn't yet confirmed. In this
     * case we want to redirect the user to the view that asks to input
     * the confirmation code.
     */
    if (error.code === 'UserNotConfirmedException') {
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.ConfirmSignUp,
        data: { username: fields.email },
      });
    } else {
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: TOAST_AUTH_ERROR_EVENT,
        message:
          translations.fi[error.message] ||
          'Tarkista käyttäjätunnus ja salasana.',
      });
    }
  }
}
