import styled from 'styled-components';
import React from 'react';
import { config } from '../config/config';
import { TopBar } from './TopBar';

const StyledDiv = styled.div`
  text-align: center;
  position: absolute;
  top: 130px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${config.themeConfig.colors.grayLight};

  @media screen and (min-width: 700px) {
    top: 80px;
  }
`;

type PageProps = {
  children: any;
  showExtranetButton?: boolean;
};

export const Page = ({ children, showExtranetButton = true }: PageProps) => (
  <React.Fragment>
    <TopBar signedIn={true} showExtranetButton={showExtranetButton} />
    <StyledDiv>{children}</StyledDiv>
  </React.Fragment>
);
