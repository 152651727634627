export enum EUserType {
  Organization = 'Yrityskäyttäjä',
  Person = 'Yksityiskäyttäjä',
}

export type FuseType = 'Under63A' | 'Over63A' | 'AreaGrid';

export enum CustomerType {
  Organisation = '15001',
  Person = '15002',
}

// EnerimCIS returns strings
export enum CustomerContactType {
  MobilePhone = '151001',
  LandlinePhone = '151002',
  Email = '151003',
  Fax = '151004',
}

export enum InvoiceDistributionType {
  PaperBill = 69001,
  EllaFinvoice = 69002,
  EInvoice = 69003,
  EPayment = 69004,
  EmailBill = 69005,
  EdiBill = 69006,
  Print = 69007,
}

export enum OutageMessagingServiceType {
  NoService = 137001,
  BasicService = 137002,
  ExtendedService = 137003,
}
export enum OutageMessagingContactType {
  PhoneNumber = 138001,
  Email = 138002,
}
export enum OutageMessagingType {
  Continuous = 139001,
  Daytime = 139002,
}

export enum InvoicingMode {
  EnergyBySupplierNetworkByDSO = 94001,
  DSOInvoicesForBoth = 94002,
  SupplierInvoicesBoth = 94003,
}
export enum BillingType {
  BasedOnAnEstimate = 95001,
  BasedOnAReading = 95002,
}

export type MeasurementMethod = {
  id: string;
  data_owner: number;
  method: MeasurementMethodType;
};

export enum MeasurementMethodType {
  HourlyMetering = 110001,
  SingleRateMetering = 110002,
  DualRateMeteringDayNight = 110003,
  DualRateMeteringWinterSummer = 110004,
}

export type BalanceSettlementMethod = {
  id: string;
  data_owner: number;
  method: BalanceSettlementMethodType;
};

export enum BalanceSettlementMethodType {
  HourlyMetering = 111001,
  Group1 = 111002,
  Group2 = 111003,
  Group3 = 111004,
  // NotInUse1 = 111005,
  // NotInUse2 = 111006,
  SmallProduction = 111007,
  HourlyMeteredSiteWithAMaximumOf3x63A = 111008,
}

export enum ContractType {
  EnergySelling = 33001,
  EnergyDelivering = 33002,
  HeatSelling = 33003,
  WaterSelling = 33004,
  GasSelling = 33005,
  GasDelivering = 33006,
  SteamSelling = 33007,
  EnergyJunction = 33008,
  HeatJunction = 33009,
  WaterJunction = 33010,
  GasJunction = 33011,
  SteamJunction = 33012,
  // LocalHeatSelling = 33013,
  EnergyContractMaintenance = 33014,
  // HeatContract = 33015,
  EnergySellingProduction = 33016,
  EnergyProduction = 33017,
  // GasContract = 33018,
  // SteamContract = 33019,
  // AreaNetworkEnergyDelivering = 33020,
  // OtherContract = 33021,
  CoolingSelling = 33025,
  HeatQuotation = 195002,
}

export enum EnerimContractCategory {
  NewContract = 188001,
  AdditionalConnectionContract = 188002,
  ChangeOfConnector = 188003,
  ConnectionDemolition = 188004,
  ContractDetailChange = 188005,
  TemporarConnectionContract = 188006,
  ElecricityProduction = 188007,
}

export enum InvoicingType {
  InvoicingByUsageFromTimeSeries = 8001, // Käyttölaskutus (tuntimittaus)
  InvoicingByUsageFromReading = 8002, // Käyttölaskutus (lukema)
  InvoicingByEstimateFixed = 8003, // Arviolaskutus (tasaerät)
  InvoicingByEstimateProfile = 8004, // Arviolaskutus (profiili)
  InvoicingByContractTimeBased = 8005, // Aikasidonnainen laskutus
  UnitBasedInvoicingContract = 8006, // Kappalelaskutus
  UnitBasedInvoicingManually = 8007, // Kappalelaskutus (käsin)
  InvoicingByEstimateFixedUsageFromTimeSeries = 8008, // Arviolaskutus (tasaerät, tuntimittaus)
}

export type Customer = {
  id: string;
  system: string;
  customerId: string;
  customerType: CustomerType;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  currentContacts: ContactInfo[];
  currentAddresses: Address[];
  ssnOrBusinessId: string | null;
  // Date of death
  dod: string | null;
  dateOfDeath: string | null;
  isSsnSet: boolean;
  // (kuolinpesä)
  isEstate: boolean;
  isDirectMarketingProhibited: boolean;
  isPilotUser: boolean;
  isResearchProhibited: boolean;
  isConsolidatedInvoiceRequested: boolean;
  contracts: Contract[];
  junctionContracts: JunctionContract[];
};

export type ContactInfo = {
  id: string;
  type: CustomerContactType;
  isPrimary: boolean;
  contact: string | null;
};
export type Address = {
  id: string;
  streetName: string;
  houseNumber: string | null;
  stairCase: string | null;
  apartmentNumber: string | null;
  postalCode: string | null;
  postOffice: string;
  countryCode: string | null;
};

export type InvoicingBase = {
  id: string;
  periodStartDay: number;
  periodStartMonth: number;
  invoicesPerYear: number;
  dueDay: number | null;
  refundAccount: string | null;
  bic: string | null;
  streetAddress: string;
  postalCode: string;
  postOffice: string;
  countryCode: string | null;
  distributionType: InvoiceDistributionType | null;
};

export type InvoicingBaseRequest = {
  id: string;
  refundAccount: string | null;
  bic: string | null;
};

export type FuseSize = string;

export type OutageMessagingContactDetail = {
  id?: string;
  contactDetail: string;
  contactType: OutageMessagingContactType;
  messagingType: OutageMessagingType;
};

// id exists in data coming from Enerim
export type OutageMessagingSetting = {
  id?: string;
  serviceType: OutageMessagingServiceType;
  contactDetails: OutageMessagingContactDetail[];
};

export type MeteringPoint = {
  id: string;
  meteringPointId: string;
  meteringPointNumber: string;
  use: number | null;
  networkId: string;
  currentFuseSize: null | FuseSize;
  currentAddress: null | Address;
  currentOutageMessagingSetting: null | OutageMessagingSetting;
  currentSeller: null | Seller;
  gsrnIdentifier: null | string;
};

export type ContactDetail = {
  id: string;
  contactDetail: string;
  contactType: number;
  messagingType: number;
};

export type Seller = {
  id: string;
  name: string;
  sellerId: string;
  contractId: string;
  invoicingMode: InvoicingMode;
  billingType: null | BillingType;
  measurementMethod: null | MeasurementMethod;
  balanceSettlementMethod: null | BalanceSettlementMethod;
  validFromDate: string;
  validToDate: null | string;
};

export type Contract = {
  id: string;
  contractNumber: string;
  contractType: ContractType;
  productId: string;
  validFromDate: string;
  validToDate: string | null;
  invoicingBase: InvoicingBase | null;
  meteringPoint: MeteringPoint | null;
  invoicingType: InvoicingType;
  // TODO: implement getting this
  // It's a mapping table in extranet backend at least
  fuseType: null | FuseType;
};

export type JunctionContract = {
  connection: Connection | null;
  contractCategory: null | EnerimContractCategory;
  contractNumber: string;
  contractType: ContractType;
  invoicingType: InvoicingType;
  productId: string;
  validFromDate: string;
  validToDate: string | null;
};

export type Connection = {
  attachmentPoint: number | null;
  connectionNumber: string;
  currentAddress: CurrentAddress;
  currentFuseSize: FuseSize;
  id: string;
  meteringPointIds: string[];
  name: string;
};

export type CurrentAddress = {
  houseNumber: string | null;
  postOffice: string | null;
  postalCode: string | null;
  streetName: string | null;
};

export type LaunchpadItem = {
  id: string;
  title: string;
  url: string;
  image?: any;
  alt?: string;
  width?: number;
  height?: number;
  type?: string,
  magicLinkId?: string,
};
