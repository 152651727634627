import styled, { css } from 'styled-components';
import React from 'react';
import { config } from '../../config/config';
import ButtonV2 from '../Button_v2';
import logoutIcon from '../../images/exit-to-app.svg';

const AppBarContainer = styled.div`
  display: flex;
  min-height: 67px;
  justify-content: space-between;

  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    background: ${config.themeConfig.launchpad3?.topAppBarBackgroundColor};
  }
`;

const logo = config.themeConfig.logoLight ?? config.themeConfig.logo;

const Logo = styled.a`
  padding: 16px 0px 18px 0px;
  background-repeat: no-repeat;
  background-image: url(${logo.url});
  background-size: contain;
  width: ${logo.width}px;
  height: ${logo.height}px;

  @media screen and (min-width: ${config.themeConfig.mobileBreakPoint}px) {
    margin: 40px ${logo.margin}px ${logo.margin}px 40px;
  }
  @media screen and (max-width: ${config.themeConfig.mobileBreakPoint}px) {
    height: 32px;
    width: 122px;
    margin: 23px;
  }
  @media screen and (max-width: 360px) {
    margin: 23px auto 23px auto;
  }
`;

const LogoutButton = styled(ButtonV2)`
  background: transparent;
  border: none;
  font-family: Apex-semi-bold;
  font-weight: 600;

  margin: 23px;
  @media screen and (min-width: 900px) {
    display: none;
  }
  @media screen and (max-width: 360px) {
    margin: 23px auto 23px auto;
  }
`;

const ButtonStartIcon = styled.div`
  ${(props: { url: string }) => {
    return css`
      height: 24px;
      width: 24px;
      background: url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      justify-self: flex-start;
    `;
  }}
`;

export default function TopAppBar() {
  return (
    <AppBarContainer>
      <Logo href="/" />
      <LogoutButton
        className="secondary-button"
        aria-label="Kirjaudu ulos"
        onClick={() =>
          window.location.assign(
            `${config.authConfig.extranetUrl}/kirjaudu-ulos`,
          )
        }
      >
        <ButtonStartIcon url={logoutIcon} />
        Kirjaudu ulos
      </LogoutButton>
    </AppBarContainer>
  );
}
