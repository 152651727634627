import styled from 'styled-components';
import React from 'react';
import color from '../customThemes';

const HeaderContainer = styled.header`
  border-top: 20px solid ${color('decorativeTopBorder')};
  background-color: white;
  display: flex;
`;

const HeaderText = styled.h1`
  padding: 0 0 0 20px;
  margin: 1.5em 0;
  text-align: left;
`;

type HeaderProps = {
  text: string;
};

export const PageHeader = (props: HeaderProps) => (
  <HeaderContainer>
    <HeaderText>{props.text}</HeaderText>
  </HeaderContainer>
);
