import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Input from './Input';
import { config } from '../config/config';

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 0 0.425rem 0;
  height: 3.125rem;

  :focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const PasswordInputField = styled(Input)`
  border-bottom: none;
  outline: none;
  &:focus-visible {
    outline: none;
  }
  /* Hacky solution to get autofill to extend over the PasswordContainer */
  /* Autofill styles */
  &:-webkit-autofill {
    -webkit-box-shadow: 37px 0 0 0 rgb(232, 240, 254) !important; /* Adjust the color to match the desired autofill effect */
    -webkit-text-fill-color: #rgba(70, 90, 126, 0.4) !important;
  }
`;

const ShowHidePassword = styled.div`
  text-align: right;
  cursor: pointer;
  align-self: center;
`;

const PasswordInputIcon = styled.div`
  ${(props: { url?: string }) => {
    return css`
      width: 32px;
      height: 32px;
      margin-right: 5px;
      background: url(${props.url});
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      background-size: contain;
    `;
  }}
`;

interface PasswordInputProps {
  value: string;
  fieldKey: string;
  setValue: (key: string, value: string) => void;
}

export default function PasswordInput(props: PasswordInputProps) {
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (
    <PasswordContainer>
      <PasswordInputField
        className="password-input"
        name="password"
        id="password"
        type={isRevealPwd ? 'text' : 'password'}
        placeholder="Salasana"
        onChange={(event: any) => {
          event.preventDefault();
          props.setValue(props.fieldKey, event.target.value);
        }}
        value={props.value}
      />
      <ShowHidePassword
        onClick={event => {
          event.preventDefault();
          setIsRevealPwd(prevState => !prevState);
        }}
      >
        <PasswordInputIcon
          url={
            isRevealPwd
              ? config.themeConfig.hidePasswordLogo?.url
              : config.themeConfig.showPasswordLogo?.url
          }
        />
      </ShowHidePassword>
    </PasswordContainer>
  );
}
