import { IAuthConfig } from './AuthConfig';

/**
 * Nice qa authentication config
 */

// no protocol, the base domain
const extranetDomain = 'asiakasqa.ese.fi';

const niceQaAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_9JEmweEcU',
  userPoolWebClientId: '2i1l7ha3b8hhlf0chtrdfkbc1k',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakasqa.nice-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://ese.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-fierce-flag-669',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakasqa.nice-extranet.com/admin/maintenancenotice`,
};

export default niceQaAuthConfig;
