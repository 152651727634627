import styled, { css } from 'styled-components';
import React from 'react';
import { EUserType, Customer, Contract, CustomerType } from '../types';
import { Button } from './Button';
import plug from '../images/plug.svg';
import { customerIdToCustomerNumber, getStreetAddress } from '../helpers';
import { CommonDescription, CommonSubheader } from './CommonStyles';
import { config } from '../config/config';

type Props = {
  enerimData: Customer[];
  linkedCustomerIds: string[];
  isAdmin: boolean;
  isFetching: boolean;
  ignoredCustomerIds: string[];
  newCustomerIds: string[];
  searchedCustomerIds: string[];
  type: EUserType;
  verifyCustomerships: boolean;
  resetCustomerships: () => void;
  updateCustomershipActivity: (
    type: 'ignore' | 'reactivate',
    customerId: string,
  ) => void;
};

type State = {
  removing: boolean;
  resetError: boolean;
};

const Container = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;

  p {
    text-align: left;
  }
`;

const Message = styled.p`
  margin: 0;
  padding: 1em;
  display: flex;
  align-items: center;

  ${(props: { error?: boolean }) =>
    !!props.error &&
    css`
      color: red;
    `}
`;

const Card = styled.div`
  background-color: white;
  text-align: left;
  padding: 0;
  box-shadow: 1px 1px 4px 0px rgb(132, 132, 132);
  border-radius: 4px;
  margin-bottom: 1em;
  margin-top: 0;

  ${(props: { newCard?: boolean }) =>
    !!props.newCard &&
    css`
      margin-top: 4.5em;

      &::before {
        position: absolute;
        display: block;
        content: 'UUSI ASIAKKUUS';
        margin-top: -45px;
        margin-left: 10px;
        width: 200px;
        height: 34px;
        background-size: 100%;
        background-color: ${config.themeConfig.colors.mainThemeColor1};
        border-radius: 5px 5px 0 0;
        color: white;
        text-align: center;
        padding-top: 0.6em;
        font-weight: bold;
        font-size: ${config.themeConfig.fontSizes.medium};
      }
    `}
`;

const CardHeader = styled.div`
  background-color: white;
  display: flex;
  align-items: left;
  flex-direction: column;
  padding: 1em;
  border-bottom: 8px solid ${config.themeConfig.colors.mainThemeColor1};

  ${(props: { ignored: boolean }) => {
    if (props.ignored) {
      return `
        border-bottom: 8px solid rgba(153, 153, 153, 0.5);
    `;
    }
  }}

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    padding: 1.5em 1em 1.5em 2em;

    button {
      margin-left: auto;
    }
  }
`;

const CardHeaderText = styled.div``;

const CardHeaderTextWithIcon = styled.div`
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  font-size: ${config.themeConfig.fontSizes.small};
  color: ${config.themeConfig.colors.grayDark};
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  ${(props: { ignored: boolean }) => {
    if (props.ignored) {
      return `
        opacity: 0.5;
      `;
    }
  }}

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;

const SubHeaderIgnored = styled(CommonSubheader)`
  margin-top: 1em;
`;

const BoldText = styled.p`
  font-size: ${config.themeConfig.fontSizes.large};
  font-weight: bold;
  margin-right: 0.5em;
  color: ${config.themeConfig.colors.grayDark};
`;

const Text = styled.p`
  font-size: ${config.themeConfig.fontSizes.medium};
  color: ${config.themeConfig.colors.grayDark};
`;

const CardContainer = styled.div`
  height: auto;

  ${(props: { ignored: boolean }) => {
    if (props.ignored) {
      return `
        opacity: 0.5;
      `;
    }
  }}
`;

const MeteringPointTable = styled.table`
  display: flex;
  flex-direction: column;
`;

const TableHead = styled.thead``;

const TableHeadRow = styled.tr`
  display: flex;
`;
const TableRow = styled.tr`
  display: flex;
  &:nth-child(odd) {
    background: ${config.themeConfig.colors.grayLight};
  }
`;

const ToolBar = styled.div`
  padding: 0 0 0.7em 0;
  display: flex;
  align-items: center;

  button {
    width: 100%;
    height: 40px;
    margin-left: auto;
    margin-right: 0;
  }

  @media screen and (min-width: 900px) {
    margin-left: auto;
    button {
      width: auto;
    }
  }
`;

const Icon = styled.div`
  align-self: center;
  display: inline-block;
  width: 34px;
  height: 38px;
  margin-right: 1em;
  vertical-align: middle;
  background: 100% 100% url(${plug});
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 0;

  ${(props: { ignored: boolean }) => {
    if (props.ignored) {
      return `
        opacity: 0.5;
      `;
    }
  }}
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  flex: 1;
  max-width: 500px;
  padding: 1em 1.5em;
  font-size: 12px;

  @media screen and (min-width: 700px) {
    font-size: ${config.themeConfig.fontSizes.small};
  }
`;

const HeaderCell = styled.td`
  flex: 1;
  max-width: 500px;
  width: auto;
  padding: 1em 1.5em;
  font-weight: bold;
  word-wrap: break-word;

  @media screen and (min-width: 700px) {
  }
`;

export class CustomershipList extends React.Component<Props> {
  state: State = {
    removing: false,
    resetError: false,
  };

  removeAll = async (event: any) => {
    event.preventDefault();

    this.setState({
      removing: true,
      resetError: false,
    });

    try {
      await this.props.resetCustomerships();

      this.setState({
        removing: false,
        resetError: false,
      });
    } catch (err) {
      this.setState({
        removing: false,
        resetError: true,
      });
    }
  };

  getName = (customership?: Customer) => {
    if (!customership) {
      return '';
    }

    const name: string =
      customership.customerType === CustomerType.Organisation
        ? `${customership.companyName}`
        : `${customership.firstName} ${customership.lastName}`;

    return name;
  };

  getMeteringPoints = (i: number, customership?: Customer) => {
    if (!customership) {
      return null;
    }

    const seen: string[] = [];

    const meteringPointRows = customership.contracts
      .map((contract: Contract, j: number) => {
        if (!contract || !contract.meteringPoint) {
          return null;
        }

        const address: string = getStreetAddress(
          contract.meteringPoint.currentAddress,
        );

        const gsrnIdentifier = contract.meteringPoint.gsrnIdentifier;
        const meteringPointNumber = contract.meteringPoint.meteringPointNumber;
        const identifierNumber = gsrnIdentifier
          ? gsrnIdentifier
          : meteringPointNumber;

        const identifierString = `${identifierNumber}${address}`;

        if (seen.includes(identifierString)) {
          // Return null for duplicate item
          return null;
        }

        seen.push(identifierString);

        return (
          <TableRow key={`${i}_${j}`}>
            <TableCell>{identifierNumber}</TableCell>
            <TableCell>{address}</TableCell>
          </TableRow>
        );
      });

    return meteringPointRows;
  };

  generateButton = (ignored: boolean, linkedCustomerId: string) => {
    const props: {
      type: 'reactivate' | 'ignore';
      text: string;
      pink: boolean;
    } = ignored
      ? { type: 'reactivate', text: 'Liitä asiakasnumero', pink: false }
      : { type: 'ignore', text: 'Piilota asiakasnumero', pink: true };

    return (
      <Button
        onClick={this.props.updateCustomershipActivity.bind(
          this,
          props.type,
          linkedCustomerId,
        )}
        pink={props.pink}
      >
        {props.text}
      </Button>
    );
  };

  renderCustomershipListing = (customerIds: string[]) => {
    /**
     * Go through each customer ID linked to the user separately
     * so that we can list all the customer ships and their contracts/metering points
     * (note! right now there is always exactly one SSN linked to a user)
     */
    const customershipData = customerIds.map(
      (linkedCustomerId: string, i: number) => {
        /* New customer ships are marked in the UI separately. */
        const newCustomership = this.props.newCustomerIds.includes(
          linkedCustomerId,
        );

        const customerNumber = customerIdToCustomerNumber(linkedCustomerId);

        const ignored = this.props.ignoredCustomerIds
          ? this.props.ignoredCustomerIds.includes(linkedCustomerId)
          : false;

        const alreadySearched = this.props.searchedCustomerIds.includes(
          linkedCustomerId,
        );

        const foundCustomership = this.props.enerimData.find(
          (customership: Customer) => {
            return customership.id === linkedCustomerId;
          },
        );

        const name = this.getName(foundCustomership);

        /* List all related metering points with addresses inside the customer ship card */
        const meteringPointRows = this.getMeteringPoints(i, foundCustomership);

        /* Hide the buttons when setupping the account */
        const customershipActivityButton =
          !this.props.verifyCustomerships &&
          this.generateButton(ignored, linkedCustomerId);

        const customershipCard = (
          <Card
            newCard={!this.props.verifyCustomerships && newCustomership}
            key={i}
          >
            <CardHeader ignored={ignored}>
              <CardHeaderTextWithIcon>
                <Icon ignored={ignored} />
                <CardHeaderText>
                  <Label>Asiakasnumero</Label>
                  <TextDiv ignored={ignored}>
                    <BoldText>{customerNumber}</BoldText>
                    <Text>{name}</Text>
                  </TextDiv>
                </CardHeaderText>
              </CardHeaderTextWithIcon>

              {customershipActivityButton}
            </CardHeader>
            <CardContainer ignored={ignored}>
              {foundCustomership && (
                <MeteringPointTable>
                  <TableHead>
                    <TableHeadRow>
                      <HeaderCell>Käyttöpaikkanumero</HeaderCell>
                      <HeaderCell>Osoite</HeaderCell>
                    </TableHeadRow>
                  </TableHead>
                  <TableBody>{meteringPointRows}</TableBody>
                </MeteringPointTable>
              )}

              {!foundCustomership &&
                (alreadySearched || !this.props.isFetching) && (
                  <Message error>Asiakastietoja ei saatu haettua.</Message>
                )}

              {!foundCustomership &&
                !alreadySearched &&
                this.props.isFetching && <Message>Haetaan...</Message>}
            </CardContainer>
          </Card>
        );

        return customershipCard;
      },
    );

    return customershipData;
  };

  renderIgnoredCustomershipListing = (
    isAdmin: boolean,
    ignoredCustomerIds: string[],
  ) => {
    if (isAdmin) {
      return null;
    }

    if (ignoredCustomerIds.length > 0) {
      return this.renderCustomershipListing(ignoredCustomerIds);
    }

    return <Message>Ei piilotettuja asiakasnumeroita.</Message>;
  };

  render() {
    const {
      isAdmin,
      linkedCustomerIds,
      ignoredCustomerIds,
      verifyCustomerships,
    } = this.props;
    const { resetError, removing } = this.state;

    const linkedCustomerIdContent =
      linkedCustomerIds.length > 0 &&
      this.renderCustomershipListing(linkedCustomerIds);

    const ignoredCustomerIdContent = this.renderIgnoredCustomershipListing(
      isAdmin,
      ignoredCustomerIds,
    );

    return (
      <Container>
        {isAdmin && (
          <ToolBar>
            {resetError && (
              <Message error>Asiakkuuksien irrottaminen epäonnistui</Message>
            )}
            <Button disabled={removing} onClick={this.removeAll}>
              {!removing ? 'Irrota kaikki asiakasnumerot' : 'Poistetaan'}
            </Button>
          </ToolBar>
        )}

        {linkedCustomerIdContent}

        {isAdmin && linkedCustomerIds.length === 0 && (
          <Message>
            Käyttäjätunnukseen ei ole liitetty vielä yhtäkään asiakasnumeroa.
          </Message>
        )}

        {!isAdmin && !verifyCustomerships && (
          <React.Fragment>
            <SubHeaderIgnored>Piilotetut asiakkuudet</SubHeaderIgnored>

            <CommonDescription>
              Voit halutessasi lisätä irrotetut asiakkuudet takaisin näkyviin.
            </CommonDescription>

            {ignoredCustomerIdContent}
          </React.Fragment>
        )}
      </Container>
    );
  }
}
