/**
 * This file exports the correct analytics object dynamically for each product version,
 * and environment, leaving the unused configs out of the build.
 */
import { EProduct, EEnvironment } from '../config';

export interface IAnalyticsConfig {
  // Amazon Pinpoint App Client ID
  appId: string;
  // Amazon service region
  region: string;
  mandatorySignIn: boolean;
}

export const AnalyticsConfig = (
  product: EProduct,
  environmentType: EEnvironment,
): IAnalyticsConfig | null => {
  try {
    return require(`./AnalyticsConfig.${product}.${environmentType}`).default;
  } catch (err) {
    console.warn(
      `Analytics not configured for product ${product} and environmentType ${environmentType}`,
    );
    return null;
  }
};
