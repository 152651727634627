import React from 'react';
import { Button } from './Button';

const LaunchpadButton = () => (
  <Button light onClick={() => window.location.assign('/')}>
    Palvelun valintaan
  </Button>
);

export default LaunchpadButton;
