import React from 'react';
import { config } from '../config/config';
import styled from 'styled-components';
import { Link } from './Button';

const { commonTexts } = config.textData;

const StyledLink = styled(Link)`
  font-size: ${config.themeConfig.fontSizes.small};
  margin-right: 1em;

  @media screen and (min-width: 700px) {
    margin-right: 0;
  }
`;

export const ExtranetButton = () => (
  <StyledLink light to={'/extranet'}>
    {commonTexts.extranetButton}
  </StyledLink>
);
