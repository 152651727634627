import styled from 'styled-components';
import { config } from '../config/config';
import React from 'react';

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${config.themeConfig.colors.backgroundColor2};
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.2);
  margin: 20px 0;
`;

export default function Divider(props: {className: string}) {
  return <StyledDivider className={props.className}></StyledDivider>;
}
