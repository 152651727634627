import { IAuthConfig } from './AuthConfig';

/**
 * Auris prod authentication config
 */

// no protocol, the base URL
const extranetDomain = 'asiakas.aurisenergia.fi';

const aurisProdAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_oiWa55wqQ',
  userPoolWebClientId: '2c21anq5387ki3nhr4eon2g7rh',
  identityPoolId: 'eu-west-1:adc6a1c4-5de9-4ac3-99a7-10bf035439ea',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  lambdaApiUrl: `https://api.asiakas.auris-extranet.com`, // note that API is not at extranetDomain!
  SignicatApiUrl: 'https://auris.app.signicat.com/auth/open/connect',
  SignicatApiClientId: 'prod-selfish-chain-872',
  extranetUrl: `https://${extranetDomain}`,
  adminServiceFrontendUrl: `https://admin.${extranetDomain}`,
  maintenanceNoticeServiceUrl:`https://api.asiakas.auris-extranet.com/admin/maintenancenotice`,
};

export default aurisProdAuthConfig;
