import { IAuthConfig } from './AuthConfig';

/**
 * Elenia dev authentication config
 */

const eleniaDevAuthConfig: IAuthConfig = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_hupO3ThVF',
  userPoolWebClientId: '1cu3ntpqvamhv4favmgke3sc34',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  widgetsApiUrl: 'http://localhost:9300',
  lambdaApiUrl: 'https://api.asiakasdev.aina-extranet.com',
  SignicatApiUrl: 'https://elenia.sandbox.signicat.com/auth/open/connect',
  SignicatApiClientId: 'sandbox-adventurous-tooth-705',
  extranetUrl: 'http://localhost:8080',
  adminServiceFrontendUrl: `http://localhost:3000`,
  maintenanceNoticeServiceUrl: `http://localhost:9300/admin/maintenancenotice`,
  cookieStorage: {
    domain: 'localhost',
    secure: false,
    path: '/',
    expires: 30,
  },
};

export default eleniaDevAuthConfig;
